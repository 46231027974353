import usePage from "../../hooks/usePage";
import useServer from "../../hooks/useServer";
import { useEffect } from "react";

function useCustomers() {
  const initialState = {
    stores: {
      shipstation: [],
      process: [],
      customers: [],
      products: [],
      materials: [],
    },

    // components
    alert: {
      title: "",
      open: false,
      msg: "",
      type: "",
      accept: () => {},
    },
    modal: {
      open: false,
      profileName: "",
      general: {
        lock: false,
        company: "",
        firstName: "",
        lastName: "",
        website: "",
        email: "",
        phone: "",
      },
      shippingAddress: {
        lock: false,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        country: "US",
        postalCode: "",
      },
      billingAddress: {
        lock: false,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        country: "US",
        postalCode: "",
      },
      provider: {
        source: "generic",
        data: {},
      },
    },
    menu: {
      selected: "",
    },
    body: {
      // error: false,
      tab: "address",
      mode: "idle",
      addressBook: [],
      selectedAddress: {
        lock: false,
        customerId: "",
        addressId: "",
        company: "",
        firstName: "",
        lastName: "",
        website: "",
        email: "",
        phone: "",
        shippingAddress: {
          lock: false,
          address1: "",
          address2: "",
          address3: "",
          city: "",
          state: "",
          postalCode: "",
          country: "US",
        },
        billingAddress: {
          lock: false,
          address1: "",
          address2: "",
          address3: "",
          city: "",
          state: "",
          postalCode: "",
          country: "US",
        },
      },
      selectedProcess: {
        name: "",
        desc: "",
        steps: [
          {
            desc: "",
            name: "",
            constant: "",
            cost: "",
            stationName: "",
            stationType: "",
            stationId: "",
            group: "",
            box: {
              x: 0,
              y: 0,
              x2: 0,
              y2: 0,
            },
          },
        ],
      },
      selectedProduct: {
        name: "",
        desc: "",
        delimiter: "",
        schema: "",
        sample: "",
        processId: null,
        processName: "",
        parts: [{ name: "" }],
        components: [
          {
            search: "",
            name: "",
            note: "",
            options: [
              {
                name: "",
                requirements: [
                  {
                    _id: "",
                    name: "",
                    amount: 0,
                    units: "",
                    cost: 0,
                  },
                ],
                edit: true,
                show: true,
                selected: false,
              },
            ],
          },
        ],
      },
      lock: true, // this is for the address book
      products: {},
      orders: {},
      invoices: {},
    },
  };

  const signState = {};
  const { get } = useServer();
  const page = usePage(initialState, signState);
  const { set, init, load, reset } = page;

  //LOAD CUSTOMERS
  useEffect(() => {
    async function effect() {
      load("stores.customers", {
        reload: async () => {
          return get("/customer/list");
        },
        callback: (data) => {
          if (data.length > 0) {
            init("stores.customers", data);
          }
        },
      });
    }
    effect();
  }, [load, get, set, init]);

  //SET DEFAULT SHIPSTATION STORES
  useEffect(() => {
    async function effect() {
      if (
        page.isLoading["stores.shipstation"] === false &&
        page.state.modal.provider.current === "shipstation"
      ) {
        set("provider.data.shipstationStoreIds", [
          {
            storeId: page.state.stores.shipstation.store[0].storeId,
            storeName: page.state.stores.shipstation.store[0].storeName,
          },
        ]);
      }
    }
    effect();
  }, [set, page.state.shipstationStores, page.state.modal.provider.current]);

  //LOAD MATERIALS
  useEffect(() => {
    async function effect() {
      load("stores.materials", {
        reload: async () => {
          return get("/materials");
        },
        callback: (data) => {
          if (data.length > 0) {
            init("stores.materials", data);
          }
        },
      });
    }
    effect();
  }, [load, get, set, init]);

  return page;
}
export default useCustomers;
