import useMetrics from "../features/metrics/useMetrics";
import Menu from "../features/metrics/Menu";
import ProductList from "../features/metrics/ProductList";
import Spinner from "../icons/Spinner";
function Metrics() {
  const page = useMetrics();

  return (
    <div className="page">
      <Menu page={page} />
      {page.isLoading["stores.customers"] ? (
        <Spinner />
      ) : (
        <ProductList page={page} />
      )}
    </div>
  );
}
export default Metrics;
