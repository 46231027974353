import DropDown from "../../components/DropDown";
import NumberInput from "../../components/NumberInput";
import TextInput from "../../components/TextInput";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";

function NewMaterial(props) {
  const { page } = props;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const modal = page.state.modal;

  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (page.validate()) return;

    const payload = {
      ...modal,
      amount: parseFloat(modal.amount),
      cost: parseFloat(modal.cost),
      type: modal.type.selected,
      units: modal.units.selected,
    };

    let data = await post("upsert/material", payload);
    if (!data.pass) {
      console.log(data.msg);

      page.set_by_key("errors", data.path, true);
      page.set_by_key("errorMessages", data.path, data.msg);
      return;
    }
    if (data.pass) {
      setAlert({ type: "success", msg: data.msg });
      page.reset("modal");
      page.reload("stores.materials");
    }
  };

  return (
    <div className="container column soft">
      <form
        className="column soft"
        onSubmit={(e) => {
          handleOnSubmit(e);
        }}
      >
        <div className="grid-bar">
          <label className="span-2 right form-label item">Name</label>
          <TextInput
            css="span-10"
            value={modal.name}
            required={true}
            onChange={(e) => {
              page.set("modal.name", e.target.value);
            }}
            errorFunc={(mount) => {
              if (mount) {
                return page.error("modal.name", (reason) => {
                  return reason.length === 0;
                });
              } else {
                page.error("modal.name");
              }
            }}
            error={page.state.errors["modal.name"]}
          />
        </div>

        <div className="grid-bar">
          <label className="span-2 right form-label item">Nickname</label>
          <TextInput
            css="span-10"
            value={modal.nickName}
            onChange={(e) => {
              page.set("modal.nickName", e.target.value);
            }}
          />
        </div>

        <div className="grid-bar">
          <label className="span-2 right form-label item">Description</label>
          <textarea
            className="span-10 form-control form-textarea list"
            value={page.state.modal.description}
            type="text"
            onChange={(e) => {
              page.set("modal.description", e.target.value);
            }}
          />
        </div>

        <div className="grid-bar">
          <label className="span-2 right form-label item">
            {page.state.modal.mode === "new"
              ? "Initial Inventory"
              : "Inventory"}
          </label>
          <NumberInput
            value={modal.amount}
            onChange={(e) => {
              page.set("modal.amount", e.target.value);
            }}
          />
          <label className="span-2 right form-label item">Unit</label>
          <DropDown
            value={modal.type.selected}
            title={modal.type.selected}
            error={page.state.errors["modal.units"]}
            errorMsg={page.state.errorMessages["modal.units"]}
          >
            {page.state.modal.type.options.map((type) => {
              return (
                <div
                  key={type}
                  className="dropdown-option"
                  onClick={() => {
                    page.set("modal.type.selected", type);
                  }}
                >
                  {type}
                </div>
              );
            })}
          </DropDown>
          <DropDown value={modal.units.selected} title={modal.units.selected}>
            {page.state.modal.units.options.map((unit) => {
              return (
                <div
                  key={unit}
                  className="dropdown-option"
                  onClick={() => {
                    page.set("modal.units.selected", unit);
                  }}
                >
                  {unit}
                </div>
              );
            })}
          </DropDown>
          <label className="span-2 right form-label item">Cost per Unit</label>
          <NumberInput
            value={modal.cost}
            required={true}
            onChange={(e) => {
              page.set("modal.cost", e.target.value);
            }}
            errorFunc={(mount) => {
              if (mount) {
                return page.error("modal.cost", (reason) => {
                  return reason === 0 || reason === "0" || reason === "";
                });
              } else {
                page.error("modal.cost");
              }
            }}
            error={page.state.errors["modal.cost"]}
          />
        </div>
        <div className="grid-bar">
          <label className="span-2 right form-label item">Supplier</label>
          <TextInput
            css="span-4"
            value={modal.supplier}
            required={true}
            onChange={(e) => {
              page.set("modal.supplier", e.target.value);
            }}
            errorFunc={(mount) => {
              if (mount) {
                return page.error("modal.supplier", (reason) => {
                  return reason.length === 0;
                });
              } else {
                page.error("modal.supplier");
              }
            }}
            error={page.state.errors["modal.supplier"]}
          />
          <label className="span-2 right form-label item">Website</label>
          <TextInput
            css="span-4"
            value={modal.website}
            onChange={(e) => {
              page.set("modal.website", e.target.value);
            }}
            error={page.state.errors["modal.website"]}
          />
        </div>
        <button className="btn-primary" type="submit">
          {page.state.modal.mode === "new" ? "Create" : "Save Edit"}
        </button>
      </form>
    </div>
  );
}
export default NewMaterial;
