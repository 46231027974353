import ErrorBox from "../../../../components/ErrorBox";
import TextInput from "../../../../components/TextInput";
import CloseIcon from "../../../../icons/Close";

function ProductParts(props) {
  const { page } = props;

  return (
    <>
      <h3 className="column">Product Parts</h3>
      <ErrorBox
        isError={page.state.errors["body.selectedProduct.parts"]}
        errorMsg="One part required"
        errorFunc={(mount) => {
          if (mount) {
            page.error(
              "body.selectedProduct.parts",
              (reason) => reason.length === 0
            );
          } else {
            page.error("body.selectedProduct.parts");
          }
        }}
      >
        <div className="card shadow soft">
          <button
            className="btn-default item"
            onClick={(e) => {
              e.preventDefault();
              page.add("body.selectedProduct.parts", { name: "" });
            }}
          >
            + Part
          </button>
          <div className="column">
            {page.state.body.selectedProduct.parts.map((part, index) => {
              return (
                <div key={index} className="grid-bar titleline list">
                  <div className="span-3 item">{`Part No. ${index + 1}`}</div>
                  <TextInput
                    placeholder="Part Name..."
                    render="page.state.body.selectedProduct.render"
                    css="span-4"
                    value={part.name}
                    error={
                      page.state.errors[
                        `body.selectedProduct.parts.${index}.name`
                      ]
                    }
                    errorMsg="Part name required"
                    errorFunc={(mount) => {
                      if (mount) {
                        page.error(
                          `body.selectedProduct.parts.${index}.name`,
                          (reason) => reason.length === 0
                        );
                      } else {
                        page.error(`body.selectedProduct.parts.${index}.name`);
                      }
                    }}
                    onChange={(e) => {
                      page.set(
                        `body.selectedProduct.parts.${index}.name`,
                        e.target.value
                      );
                    }}
                  />
                  <button
                    className="btn-icon span-1 right"
                    tabIndex={-1}
                    onClick={(e) => {
                      e.preventDefault();
                      page.remove("body.selectedProduct.parts", index);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>
              );
            })}
          </div>
        </div>
      </ErrorBox>
    </>
  );
}

export default ProductParts;
