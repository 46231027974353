import { useState } from "react";
import format_time from "../../../utils/format_time";

function LaborShop(props) {
  const { page, selected } = props;
  const [isDragging, setIsDragging] = useState(false);

  const handleMouseDown = (index) => {
    setIsDragging(true);
    page.toggle(`body.selected.steps.${index}.highlight`);
  };

  const handleMouseEnter = (index) => {
    if (isDragging) {
      page.toggle(`body.selected.steps.${index}.highlight`);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const choose = (avg, constant) => {
    if (avg === 0) return constant;
    return avg;
  };

  const find_emp_count = (step) => {
    let maxCount = 0;
    step.skus.forEach((sku) => {
      if (sku.employees.length > maxCount) {
        maxCount = sku.employees.length;
      }
    });
    return maxCount;
  };

  return selected.steps.map((step, index) => {
    return (
      <div
        key={step.stepId}
        className="grid-bar click"
        onMouseDown={() => handleMouseDown(index)}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseUp={handleMouseUp}
        style={{
          backgroundColor: step.highlight ? "var(--primary)" : "transparent",
          userSelect: "none",
          color: step.highlight ? "white" : "",
        }}
      >
        <div className="span-4">{`${index + 1}. ${step.stepName}`}</div>
        <div className="span-1">
          {format_time(choose(step.shop_avg, step.constant))}
        </div>
        <div className="span-1">{format_time(step.shop_mode)}</div>
        <div className="span-1">{`${step.total}`}</div>
        <div className="span-1">{`$${
          step?.shop_labor_cost?.toFixed(2) ?? 0
        }`}</div>
        <div className="span-1">{`${find_emp_count(step)}
                `}</div>
      </div>
    );
  });
}
export default LaborShop;
