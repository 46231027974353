import AddressBlock from "../../../components/AddressBlock";
function BillingAddress(props) {
  const { page } = props;

  const address = page.state.modal.billingAddress;
  const hidden = page.state.modal.billingAddress.hidden;
  const render = page.state.modal.render;

  return hidden ? (
    <></>
  ) : (
    <AddressBlock
      title="Billing Information"
      cardCSS="card-two-thirds"
      lock={address.lock}
      onLock={() => {
        page.toggle("modal.billingAddress.lock");
      }}
      address1={{
        value: address.address1,
        render: render,
        required: true,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.billingAddress.address1", e.target.value);
        },
        error: page.state.errors["modal.billingAddress.address1"],
        errorMsg: "Address Line 1 required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.billingAddress.address1", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.billingAddress.address1");
          }
        },
      }}
      address2={{
        value: address.address2,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.billingAddress.address2", e.target.value);
        },
      }}
      address3={{
        value: address.address3,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.billingAddress.address3", e.target.value);
        },
      }}
      city={{
        value: address.city,
        render: render,
        required: true,
        css: "half",
        onChange: (e) => {
          page.set("modal.billingAddress.city", e.target.value);
        },
        error: page.state.errors["modal.billingAddress.city"],
        errorMsg: "City required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.billingAddress.city", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.billingAddress.city");
          }
        },
      }}
      state={{
        value: address.state,
        render: render,
        required: true,
        css: "half",
        onChange: (e) => {
          page.set("modal.billingAddress.state", e.target.value);
        },
        error: page.state.errors["modal.billingAddress.state"],
        errorMsg: "State required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.billingAddress.state", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.billingAddress.state");
          }
        },
      }}
      country={{
        value: address.country,
        required: true,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.billingAddress.country", e.target.value);
        },
      }}
      postalCode={{
        value: address.postalCode,
        render: render,
        required: true,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.billingAddress.postalCode", e.target.value);
        },
        error: page.state.errors["modal.billingAddress.postalCode"],
        errorMsg: "Postal Code required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.billingAddress.postalCode", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.billingAddress.postalCode");
          }
        },
      }}
      copyButton={{
        hidden: false,
        text: "Same as Priamry?",
        onClick: () => {
          page.set("modal.billingAddress", page.state.modal.shippingAddress);
        },
      }}
    />
  );
}
export default BillingAddress;
