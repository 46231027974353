import { useState, useEffect, useRef } from "react";
import React from "react";
import ErrorBox from "./ErrorBox";

function DropDown(props) {
  const {
    children,
    required = false,
    lock = false,
    value,
    css = "",
    title = "",
    onOpen = () => {},
    error = false,
    errorMsg = "Required",
    errorFunc = () => {},
  } = props;
  const self = useRef();
  const selfChildren = useRef();
  const [show, setShow] = useState(false);
  const [choosing, setChoosing] = useState(false);
  const choosingRef = useRef(choosing);
  const [isError, setIsError] = useState(false);

  const requireMark = {
    display: lock ? "none" : "block",
    color: isError === true ? "var(--error)" : "var(--primary)",
  };

  //assign error checker
  useEffect(() => {
    errorFunc(true);
    return () => {
      errorFunc(false);
    };
  }, []);

  //check for errors
  useEffect(() => {
    error === true ? setIsError(true) : setIsError(false);
  }, [error]);

  useEffect(() => {
    choosingRef.current = choosing;
  }, [choosing]);

  useEffect(() => {
    const checkMouseOver = (e) => {
      try {
        let x = e.clientX;
        let y = e.clientY;
        let parent = self.current.getBoundingClientRect();
        let children = selfChildren.current.getBoundingClientRect();
        let bounds = {
          left: parent.left,
          right: children.right,
          top: parent.top,
          bottom: children.bottom,
        };
        if (
          x < bounds.left ||
          x > bounds.right ||
          y < bounds.top ||
          y > bounds.bottom
        ) {
          {
            setChoosing(false);
            setShow(false);
          }
        }
      } catch (error) {
        setChoosing(false);
        setShow(false);
      }
    };

    document.addEventListener("keydown", (e) => {
      if (e.key === "Escape") {
        setShow(false);
      }
    });
    document.addEventListener("mousemove", (e) => {
      if (choosingRef.current === true) {
        checkMouseOver(e);
      }
    });
    //Clean up
    return () => {
      document.removeEventListener("keydown", (e) => {
        if (e.key === "Escape") {
          setShow(false);
        }
      });
      document.removeEventListener("mousemove", (e) => {
        if (choosingRef.current === true) {
          checkMouseOver(e);
        }
      });
    };
  }, []);

  const outline = () => {
    if (isError && choosing) {
      return "2px solid var(--error)";
    }
    if (choosing) {
      return "2px solid black";
    }
  };

  const editStyle = {
    border: "1px solid #ccc",
    outline: outline(),
  };

  const lockStyle = {
    backgroundColor: "var(--background)",
    color: "var(--text)",
    border: "1px solid #fff",
    padding: "0.125rem",
  };

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  const arrowDisplay = () => {
    if (lock === true) {
      return "";
    } else if (lock === false && show === false) {
      return "▼";
    } else if (lock === false && show === true) {
      return "▲";
    }
  };

  //COMPONENT
  return (
    <div className={`input-error-box ${css} ${lockStatus()}`}>
      <p
        className="input-required"
        style={required === true ? requireMark : { display: "none" }}
      >
        *
      </p>
      <ErrorBox isError={isError} errorMsg={errorMsg}>
        <div
          className={`dropdown ${lock === true ? "lock" : ""}`}
          style={lock === true ? lockStyle : editStyle}
          ref={self}
        >
          <div
            className={`dropdown-btn third`}
            onClick={(e) => {
              e.preventDefault();
              show === false ? setShow(true) : setShow(false);
              setChoosing(!choosing);
              onOpen();
            }}
            disabled={choosing}
          >
            {`${title} ${arrowDisplay()}`}
          </div>
          {show ? (
            <div
              ref={selfChildren}
              className="dropdown-options-container two-thirds shadow"
              value={value ?? "No Data"}
              onClick={(e) => {
                setChoosing(false);
                setShow(false);
              }}
            >
              {children}
            </div>
          ) : (
            <></>
          )}
        </div>
      </ErrorBox>
    </div>
  );
}
export default DropDown;
