import { useState } from "react";
import CustomerMenu from "../features/customers/CustomerMenu";
import CustomerBody from "../features/customers/CustomerBody";
import useCustomers from "../features/customers/useCustomers";
import ModalBase from "../components/ModalBase";
import CustomerNew from "../features/customers/CustomerNew";
import Spinner from "../icons/Spinner";
import Alert from "../components/Alert";

function Customers() {
  const [alerts, setAlert] = useState([]);
  const page = useCustomers();

  return (
    <div className="page">
      <Alert
        page={page}
        title={page.state.alert.title}
        close={() => {
          page.set("alert.open", false);
        }}
        open={page.state.alert.open}
        msg={page.state.alert.msg}
        decline={() => {
          page.set("alert.open", false);
        }}
        accept={page.state.alert.accept}
      />
      <ModalBase
        title="New Customer"
        page={page}
        open={page.state.modal.open}
        close={(e) => {
          e.preventDefault();
          page.reset("modal");
          page.reset("modal.general");
          page.reset("modal.shippingAddress");
          page.reset("modal.billingAddress");
        }}
      >
        <CustomerNew page={page} alerts={alerts} setAlert={setAlert} />
      </ModalBase>
      <CustomerMenu page={page} />
      {page.isLoading["stores.customers"] ? (
        <Spinner />
      ) : (
        <CustomerBody page={page} alerts={alerts} setAlert={setAlert} />
      )}
    </div>
  );
}
export default Customers;
