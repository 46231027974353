import AddressBook from "./customerBody/address/AddressBook";
import TabSelect from "./customerBody/TabSelect";
import Process from "./customerBody/process/Process";
import Product from "./customerBody/product/Product";
function CustomerBody(props) {
  const { page } = props;
  return (
    <div
      className="column shadow smooth"
      style={{ minHeight: "calc(100vh - 136px" }}
    >
      <TabSelect {...props} />
      {page.state.body.tab === "address" ? <AddressBook {...props} /> : <></>}
      {page.state.body.tab === "process" ? <Process {...props} /> : <></>}
      {page.state.body.tab === "products" ? <Product {...props} /> : <></>}
    </div>
  );
}
export default CustomerBody;
