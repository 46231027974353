import { useState } from "react";
function useDrag(page) {
  const [clicked, setClicked] = useState(false);
  const [clickY, setClickY] = useState(0);
  const [target, setTarget] = useState(false);
  const [handle, setHandle] = useState(null);
  const [dragGroup, setDragGroup] = useState(null);

  const dragMap = (drag, drop, array) => {
    let arrayCopy = [...array];
    const dragIndex = arrayCopy.findIndex((obj, index) => {
      return index === drag;
    });
    const dropIndex = arrayCopy.findIndex((obj, index) => {
      return index === drop;
    });
    const list = arrayCopy.splice(dragIndex, 1);
    const frontList = arrayCopy.slice(0, dropIndex);
    const rearList = arrayCopy.slice(dropIndex);
    const updatedArray = [...frontList, ...list, ...rearList];
    return updatedArray;
  };

  function dragsetup(page) {
    const traverse = (obj, path) => {
      const route = path.split(".");
      for (let i = 0; i < route.length; i++) {
        obj = obj[route[i]];
      }
      return obj;
    };

    const move = (e) => {
      clicked && updateScrollPos(e);
    };

    const updateScrollPos = (e) => {
      window.screenTop = window.screenTop + (clickY - (e.pageY + 50));
    };

    const start = (e) => {
      setHandle(Array.from(document.querySelectorAll(".drag-icon")));
      if (!handle.includes(target)) {
        e.preventDefault();
      } else {
        e.target.style.backgroundColor = "var(--table-header)";
        const id = e.target.getAttribute("data-id");
        setDragGroup(e.target.getAttribute("data-group"));
        e.dataTransfer.setData("text/plain", id);
      }
    };

    const down = (e) => {
      setHandle(Array.from(document.querySelectorAll(".drag-icon")));
      setTarget(e.target);
      setClicked(true);
      setClickY(e.pageY);
    };

    const up = (e) => {
      setDragGroup(null);
      setHandle(null);
      setClicked(false);
      setTarget(false);
      setClickY(0);
      document.querySelector("html").style.cursor = "auto";
    };

    const drop = (e, path) => {
      let dropTarget = parseInt(e.target.dataset.id);
      let dragTarget = parseInt(e.dataTransfer.getData("text"));
      if (e.target.dataset.group !== dragGroup || dragGroup === null) {
        e.preventDefault();
      } else {
        e.stopPropagation();
        e.preventDefault();
        e.currentTarget.classList.remove("drag-over");
        e.currentTarget.classList.add("drag-leave");
        const children = e.currentTarget.children;
        for (let i = 0; i < children.length; i++) {
          children[i].classList.remove("dragging");
        }
        const target = traverse(page.state, path);
        const updatedPosition = dragMap(dragTarget, dropTarget, target);
        console.log(updatedPosition);
        page.init(path, updatedPosition, { reinitialize: true });
      }
    };

    const end = (e) => {
      e.stopPropagation();
      e.preventDefault();
      e.target.style.backgroundColor = "transparent";
      const children = e.currentTarget.children;
      for (let i = 0; i < children.length; i++) {
        children[i].classList.remove("dragging");
      }
    };

    const enter = (e) => {
      if (e.target.dataset.group !== dragGroup) {
        e.preventDefault();
      } else {
        e.stopPropagation();
        e.preventDefault();
        const children = e.currentTarget.children;
        for (let i = 0; i < children.length; i++) {
          children[i].classList.add("dragging");
        }
      }
    };

    const over = (e) => {
      if (e.target.dataset.group !== dragGroup) {
        e.preventDefault();
      } else {
        e.stopPropagation();
        e.preventDefault();
        e.currentTarget.classList.remove("drag-leave");
        e.currentTarget.classList.add("drag-over");
        return false;
      }
    };

    const leave = (e) => {
      if (e.target.dataset.group !== dragGroup) {
        e.preventDefault();
      } else {
        e.stopPropagation();
        e.preventDefault();
        const children = e.currentTarget.children;
        for (let i = 0; i < children.length; i++) {
          children[i].classList.remove("dragging");
        }
        e.currentTarget.classList.remove("drag-over");
        e.currentTarget.classList.add("drag-leave");
      }
    };

    return { move, start, down, up, drop, end, enter, over, leave };
  }

  return dragsetup(page);
}
export default useDrag;
