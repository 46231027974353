function DragDropIcon(props) {
  return (
    <svg
      id="Layer_1"
      className="drag-icon"
      data-name="Layer 1"
      xmlns="http://www.w3.org/2000/svg"
      width="16.5"
      height="16.5"
      viewBox="0 0 16.5 16.5"
      {...props}
    >
      <defs>
        <style></style>
      </defs>
      <path
        d="M5.95,8.5A1.451,1.451,0,0,0,4.5,9.95v.64h1V9.95a.4481.4481,0,0,1,.45-.45h2.1v-1ZM4.5,12.17v4.24h1V12.17ZM5.95,20.5a.4481.4481,0,0,1-.45-.45V18h-1v2.05A1.451,1.451,0,0,0,5.95,21.5h.69v-1Zm2.28,0v1h4.23v-1Z"
        transform="translate(-4.5 -5)"
      />
      <path
        d="M17.5,19.54v.51a1.451,1.451,0,0,1-1.45,1.45h-2v-1h2a.4481.4481,0,0,0,.45-.45v-.51Z"
        transform="translate(-4.5 -5)"
      />
      <rect
        className="drag-icon"
        style={{ fill: "transparent" }}
        x="4.5"
        width="12"
        height="12"
        rx="0.9495"
      />
      <path
        d="M20.05,5H9.95A.956.956,0,0,0,9,5.95v10.1a.956.956,0,0,0,.95.95H16.5v.93h1V17h2.55a.956.956,0,0,0,.95-.95V5.95A.956.956,0,0,0,20.05,5ZM20,16H10V6H20Z"
        transform="translate(-4.5 -5)"
      />
    </svg>
  );
}

export default DragDropIcon;
