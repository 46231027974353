import psuedo_random_hex from "../../utils/psuedo_random_hex";

const itemId = (each, index) => {
  if (each._id !== "" && each._id !== undefined && each._id !== null) {
    return each._id;
  }
  return psuedo_random_hex(24, index);
};

function order_create_payload(page) {
  const now = new Date().toISOString();
  const general = page.state.modal.general;
  const shipping = page.state.modal.shippingAddress;
  const billing = page.state.modal.billingAddress;
  const provider = page.state.modal.currentCustomer.provider;
  const orderItems = page.state.modal.orderItems;

  return {
    _id: page.state.modal.orderInformation._id,
    dedupe: psuedo_random_hex(24),
    orderNumber: page.state.modal.orderInformation.orderNumber,
    createdAt: now,
    updatedAt: now,
    status: "new",
    redo: 0,
    customerId: page.state.modal.currentCustomer._id,
    customer: {
      company: general.company,
      firstName: general.firstName,
      lastName: general.lastName,
      website: general.website,
      email: general.email,
      phone: general.phone,
      shippingAddress: {
        address1: shipping.address1,
        address2: shipping.address2,
        address3: shipping.address3,
        city: shipping.city,
        state: shipping.state,
        postalCode: shipping.postalCode,
        country: shipping.country,
      },
      billingAddress: {
        address1: billing.address1,
        address2: billing.address2,
        address3: billing.address3,
        city: billing.city,
        state: billing.state,
        postalCode: billing.postalCode,
        country: billing.country,
      },
    },
    provider: {
      source: provider.source,
      originator: "local",
      data: provider?.data,
    },
    orderItems: orderItems.lines.map((each, index) => {
      return {
        _id: itemId(each, index),
        providerItemId: each.providerItemId,
        itemProductId: each.itemProductId,
        skuId: each.skuId,
        sku: each.sku,
        name: each.name,
        product: each.product,
        quantity: parseInt(each.quantity),
        unitPrice: !each.unitPrice ? 0 : parseFloat(each.unitPrice),
      };
    }),
    batches: page.state.modal.orderInformation.batches,
  };
}

export default order_create_payload;
