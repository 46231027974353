exports.add_step = (page) => {
  page.add("body.selectedProcess.steps", {
    desc: "",
    name: "",
    stationName: "",
    stationType: "",
    stationId: "",
    group: "",
    constant: 0,
    box: {
      x: 0,
      y: 0,
      x2: 0,
      y2: 0,
    },
  });
};
