const is_overflown = (element, direction = "bottom", object = window) => {
  if (element !== null) {
    const distance = element.scrollHeight;
    window.requestAnimationFrame(() => {
      object.scrollTo({
        left: 0,
        top: direction === "bottom" ? distance : 0,
        behavior: "smooth",
      });
    });
  }
};

export default is_overflown;
