import { useState, useEffect } from "react";
import ErrorBox from "./ErrorBox";

function NumberInput(props) {
  const {
    value,
    onChange,
    css,
    placeholder,
    required,
    lock,
    maxLength,
    error = false,
    errorMsg = "Required",
    errorFunc = () => {},
  } = props;

  const [isError, setIsError] = useState(false);
  const [hasFocus, setHasFocus] = useState(false);

  //assign error checker
  useEffect(() => {
    errorFunc(true);
    return () => {
      errorFunc(false);
    };
  }, []);

  //check for errors
  useEffect(() => {
    error === true ? setIsError(true) : setIsError(false);
  }, [error]);

  const outline = () => {
    if (isError && hasFocus) {
      return "2px solid var(--error)";
    }
    if (hasFocus) {
      return "2px solid black";
    }
  };

  const editStyle = {
    outline: outline(),
  };
  const lockStyle = {
    backgroundColor: "var(--background)",
    color: "var(--text)",
    padding: "0.125rem",
  };
  const requireMark = {
    display: lock ? "none" : "block",
    color: isError === true ? "var(--error)" : "var(--primary)",
  };

  const lockStatus = () => {
    if (lock === undefined) {
      return "";
    } else if (lock === false) {
      return "input-unlock";
    } else if (lock === true) {
      return "input-lock";
    }
  };

  return (
    <ErrorBox isError={isError} errorMsg={errorMsg}>
      <input
        className="input-borderless"
        type="text"
        value={value}
        disabled={lock}
        onChange={(e) => {
          const regex = /^[0-9.]*$/;
          const isNumeric = regex.test(e.target.value);
          if (isNumeric) {
            onChange(e);
          }
        }}
        onFocus={() => setHasFocus(true)}
        onBlur={() => setHasFocus(false)}
        minLength={required === true ? 1 : 0}
        maxLength={maxLength ? maxLength : "none"}
        placeholder={lock === true ? "" : placeholder}
        style={lock === true ? lockStyle : editStyle}
      />
    </ErrorBox>
  );
}
export default NumberInput;
