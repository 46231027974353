import TextInput from "../../../../components/TextInput";

function ProductSku(props) {
  const { page, lock } = props;
  const product = page.state.body.selectedProduct;

  const renderSku = () => {
    let sku = "";
    product.components?.forEach((component, index) => {
      if (component.name === "") {
        sku += `Component ${index + 1}`;
      } else {
        sku += component.name;
      }
      if (index !== product.components.length - 1) {
        sku += product.delimiter;
      }
    });
    return sku;
  };

  const renderSample = (delimiter) => {
    let sample = "";
    if (product.components.some((component) => component.options.length === 0))
      return sample;
    product.components.forEach((component, index) => {
      const randomIndex = Math.floor(Math.random() * component.options.length);
      sample += component.options[randomIndex].name;
      if (index !== product.components.length - 1) {
        sample += delimiter;
      }
    });
    page.set("body.selectedProduct.sample", sample);
  };

  return (
    <div className="column list">
      <h3>Sku Schema</h3>
      <div className="bar shadow soft smooth">
        <label>Delimiter</label>
        <TextInput
          value={product.delimiter}
          placeholder="N/A"
          maxLength="1"
          lock={lock}
          css="item"
          onChange={(e) => {
            page.set("body.selectedProduct.delimiter", e.target.value);
          }}
        />

        <h4 className="bar">Schema: {renderSku()}</h4>
        <div className="bar right">
          <h4>{product.sample}</h4>
          <button
            className="btn-default list"
            onClick={(e) => {
              e.preventDefault();
              renderSample(product.delimiter);
            }}
          >
            Sample
          </button>
        </div>
      </div>
    </div>
  );
}
export default ProductSku;
