import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import "../../alert.css";

import { useEffect, useState, useRef } from "react";
function AlertArea() {
  const { alert } = useGlobalAlert();
  const [localMsg, setLocalMsg] = useState(""); // this brings the alert message to the local state to prevent flickering
  const [animation, setAnimation] = useState("");
  const alertRef = useRef(null);

  useEffect(() => {
    const node = alertRef.current;

    const startAnimation = () => {
      setLocalMsg(alert.msg);
      setAnimation(`alert-${alert.type}-animation`);
    };

    const switchAnimation = () => {
      setAnimation(`alert-${alert.type}-idle`);
      node.removeEventListener("animationend", switchAnimation);
    };

    if (node) {
      node.addEventListener("animationend", switchAnimation);
      window.requestAnimationFrame(() => {
        window.requestAnimationFrame(startAnimation);
      });
    }

    return () => {
      if (node) {
        node.removeEventListener("animationend", switchAnimation);
      }
    };
  }, [alert]);

  return (
    <div ref={alertRef} className={`global-alert ${animation}`}>
      <h3 className={`alert-msg alert-${alert.type}`}>{localMsg}</h3>
    </div>
  );
}
export default AlertArea;
