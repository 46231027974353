import General from "../../components/General";
import CustomerSelect from "./modal/CustomerSelect";
import ShippingAddress from "./modal/ShippingAddress";
import BillingAddress from "./modal/BillingAddress";
import OrderItems from "./modal/OrderItems";
import TextInput from "../../components/TextInput";
import Ellipse from "../../icons/Ellipse";
import order_create_payload from "./order_create_payload";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import { useState } from "react";

function OrderNew(props) {
  const { page } = props;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const [preventDup, setPreventDup] = useState(false);
  const [deleteOrder, setDeleteOrder] = useState(false);

  const general = page.state.modal.general;
  const render = page.state.modal.render;
  let mode = page.state.mode;

  const buttonName = () => {
    mode = mode === "create" ? "Create" : "Update";
    mode = mode.charAt(0).toUpperCase() + mode.slice(1);
    return mode;
  };

  const on_fulfill = async () => {
    const data = await post(
      "/order/fulfill",
      page.state.modal.orderInformation._id
    );
    if (data) {
      setAlert({ msg: data.msg, type: "success" });
      page.set("mode", "create");
      page.reset("errors");
      page.reset("modal");
      page.reset("stores.products");
      page.reset("mode");
      page.reload("stores.orders");
    }
  };

  const on_submit = async () => {
    if (preventDup) return;
    if (page.validate()) {
      return;
    }
    const payload = order_create_payload(page);
    setPreventDup(true);
    const response = await post("/order", payload);
    if (response) {
      page.set("modal.msg", `"${response.msg}"`);
      page.set("modal.orderInformation._id", response.data._id);
      page.set("modal.orderItems.lines", response.data.orderItems);
      page.set("modal.general.lock", true);
      page.set("modal.shippingAddress.lock", true);
      page.set("modal.billingAddress.lock", true);
      page.set("modal.orderItems.lock", true);
      page.set("modal.orderInformation.lock", true);
      page.set("mode", "edit");
      page.reload("stores.orders");
      setPreventDup(false);
    }
  };

  const on_delete = async () => {
    setPreventDup(true);
    const response = await post(
      "/order/delete",
      page.state.modal.orderInformation._id
    );
    if (response) {
      setPreventDup(false);
      setAlert({ msg: response.msg, type: response.type });
      page.set("mode", "create");
      page.reset("errors");
      page.reset("modal");
      page.reset("stores.products");
      page.reset("mode");
      page.reload("stores.orders");
    }
  };

  return (
    <>
      {page.state.mode === "edit" ? (
        <div className="grid-bar list">
          <button
            className="btn-link span-2"
            onClick={() => {
              setDeleteOrder(!deleteOrder);
            }}
          >
            Toggle Delete
          </button>
          {deleteOrder ? (
            <>
              <p className="span-9" style={{ color: "var(--error)" }}>
                WARNING! This cannot be undone. All items assoicated with this
                order will be permantly deleted. This does not delete the order
                from external originating sources.
              </p>
              <button
                className="btn-error span-1 s-12"
                onClick={on_delete}
                disabled={preventDup}
              >
                Delete Permantly
              </button>
            </>
          ) : null}
        </div>
      ) : null}
      <CustomerSelect page={page} />
      <div className="grid-bar gap list">
        <button
          className="btn-link span-2"
          onClick={(e) => {
            e.preventDefault();
            page.toggle("modal.general.hidden");
            page.toggle("modal.shippingAddress.hidden");
            page.toggle("modal.billingAddress.hidden");
          }}
        >
          {page.state.modal.general.hidden ? "Show Address" : "Hide Address"}
        </button>
        {preventDup ? (
          <div className="grid-bar span-2 s-9">
            <p className="span-1">Submitting</p>
            <Ellipse css="span-1" />
          </div>
        ) : null}
        {page.state.mode === "edit" ? (
          <button
            className="btn-success span-1 s-11"
            onClick={async () => {
              on_fulfill();
            }}
          >
            Fulfill
          </button>
        ) : null}
        <button
          disabled={preventDup}
          className="btn-primary span-1 s-12"
          onClick={async () => {
            on_submit();
          }}
        >
          {buttonName()}
        </button>
      </div>

      <div className="bar ">
        <h3 className="item pB">Order Name</h3>
        <TextInput
          path={"modal.orderInformation.orderNumber"}
          value={page.state.modal.orderInformation.orderNumber}
          lock={page.state.modal.orderInformation.lock}
          render={render}
          error={page.state.errors["modal.orderInformation.orderNumber"]}
          errorMsg="Order Number is required"
          errorFunc={(mount) => {
            if (mount) {
              page.error("modal.orderInformation.orderNumber", (reason) => {
                return reason.length === 0;
              });
            }
            if (!mount) {
              page.error("modal.orderInformation.orderNumber");
            }
          }}
          onChange={(e) => {
            page.set("modal.orderInformation.orderNumber", e.target.value);
          }}
        />
      </div>
      <General
        lock={page.state.modal.general.lock}
        onLock={() => {
          page.toggle("modal.general.lock");
        }}
        title="General"
        company={{
          value: general.company,
          required: false,
          render: render,
          onChange: (e) => {
            page.set("modal.general.company", e.target.value);
          },
          css: "two-thirds",
          placeholder: "Company Name",
          lock: true,
        }}
        firstName={{
          value: general.firstName,
          required: true,
          render: render,
          onChange: (e) => {
            page.set("modal.general.firstName", e.target.value);
          },
          css: "half",
          error: page.state.errors["modal.general.firstName"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.general.firstName", (reason) => {
                return reason.length === 0;
              });
            } else {
              page.error("modal.general.firstName");
            }
          },
        }}
        lastName={{
          value: general.lastName,
          required: true,
          render: render,
          onChange: (e) => {
            page.set("modal.general.lastName", e.target.value);
          },
          css: "half",
          error: page.state.errors["modal.general.lastName"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.general.lastName", (reason) => {
                return reason.length === 0;
              });
            } else {
              page.error("modal.general.lastName");
            }
          },
        }}
        website={{
          value: general.website,
          required: false,
          render: render,
          onChange: (e) => {
            page.set("modal.general.website", e.target.value);
          },
          css: "two-thirds",
        }}
        email={{
          value: general.email,
          required: false,
          render: render,
          onChange: (e) => {
            page.set("modal.general.email", e.target.value);
          },
          css: "two-thirds",
        }}
        phone={{
          value: general.phone,
          required: false,
          render: render,
          onChange: (e) => {
            page.set("modal.general.phone", e.target.value);
          },
          css: "two-thirds",
        }}
      />

      <ShippingAddress page={page} />
      <BillingAddress page={page} />
      <OrderItems page={page} title="Items" />
    </>
  );
}
export default OrderNew;
