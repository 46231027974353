import { Link, useNavigate, Outlet } from "react-router-dom";
import { UserContext } from "../../context/User/UserProvider";
import { useContext } from "react";
import axios from "../../utils/axios";

function HeaderAdmin() {
  const { auth, setAuth } = useContext(UserContext);
  const navigate = useNavigate();

  const logOutUser = async () => {
    try {
      const data = await axios.get("user/logout");
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <header>
        <div className="nav">
          <div className="card">
            <div className="logo">
              <Link to="/">Home</Link>
            </div>
            <ul>
              {auth.accessToken !== "" ? (
                <>
                  <li>
                    <Link to="/orders">Orders</Link>
                  </li>
                  <li>
                    <Link to="/batches">Batches</Link>
                  </li>
                  <li>
                    <Link to="/inventory">Inventory</Link>
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
          {auth.accessToken !== "" ? (
            <>
              {/* <Link to="/profile">{auth.name}</Link> */}
              <button
                onClick={async (e) => {
                  setAuth({ accessToken: "", role: 0 });
                  await logOutUser();
                  navigate("/login");
                }}
              >
                <p className="btn-loginout">Logout</p>
              </button>
            </>
          ) : (
            <button>
              <Link to="/login">Login</Link>
            </button>
          )}
        </div>
      </header>
      <Outlet />
    </>
  );
}

export default HeaderAdmin;
