import Item from "./Item";
import BatchDetailsMenu from "./BatchDetailsMenu";

function BatchDetailedView(props) {
  const { page, alerts, setAlert } = props;

  const handleOnPrint = (orderItem, orderNumber) => {
    let barcodes = [];
    let payload = {};

    orderItem.items.forEach((item, i) => {
      item.parts.forEach((part, index) => {
        let clone = { ...item };
        clone.partName = part.name;
        clone.partNumber = index + 1;
        clone.totalParts = item.parts.length;
        barcodes = [...barcodes, clone];
      });
    });
    payload = {
      ...page.state.modal,
      open: true,
      barcodes: barcodes,
    };

    page.set("modal", payload);
  };

  const isSearch = (result) => {
    if (page.state.batchList.search === "") return {};
    const regext = new RegExp(page.state.batchList.search, "i");
    if (page.state.detailedView.searched === true && result.match(regext)) {
      return { color: "var(--alt-font-color)" };
    } else {
      return {};
    }
  };

  const formatCustomer = (customer) => {
    if (customer.company === "")
      return customer.firstName + " " + customer.lastName;
    return customer.company;
  };

  const renderBorderStyle = (item) => {
    if (item.redo === true) {
      return { borderColor: "var(--warning)" };
    }
    if (item.lock === true) {
      return { borderColor: "var(--error)" };
    }
  };

  //COMPONENT
  return (
    <div className="table-boss shadow soft middled two">
      <BatchDetailsMenu page={page} alerts={alerts} setAlert={setAlert} />

      <div className="table-container">
        {page.state.detailedView.orders?.map((order, index) => {
          return (
            <div key={index} className="soft bordered shadow list">
              <div className="card list bottom">
                <h2 className="item" style={isSearch(order.orderNumber)}>
                  {order.orderNumber}
                </h2>
                <h4 className="item">
                  Customer: {formatCustomer(order.customer)}
                </h4>
                <h4 className="item">Date: {order.createdAt.split("T")[0]}</h4>
              </div>
              <div>
                {order?.orderItems?.map((orderItem, i) => {
                  return (
                    <div
                      key={orderItem._id}
                      className="bordered list-item smooth"
                      style={renderBorderStyle(orderItem)}
                    >
                      <div className="pair list item">
                        <p>Product:</p>
                        <h3
                          className="list item"
                          style={isSearch(orderItem.product)}
                        >
                          {orderItem.product}
                        </h3>
                      </div>
                      <div className="pair list item">
                        <p>Name:</p>
                        <h3
                          className="list item"
                          style={isSearch(orderItem.name)}
                        >
                          {orderItem.name}
                        </h3>
                      </div>
                      <div className="pair list item">
                        <p>Date:</p>
                        <b className="item">{order.updatedAt.split("T")[0]}</b>
                      </div>
                      <div className="pair list item">
                        <p>Item Qty:</p>
                        <b className="item">{orderItem.items?.length}</b>
                      </div>

                      <div className="bar split">
                        <button
                          className="btn-default item"
                          onClick={() => {
                            page.toggle(
                              `detailedView.orders.${index}.orderItems.${i}.show`
                            );
                          }}
                        >
                          {page.state.detailedView.orders[index].orderItems[i]
                            .show
                            ? "Hide Items"
                            : "View Items"}
                        </button>

                        <button
                          className="btn-default list-item"
                          onClick={() => {
                            handleOnPrint(orderItem, order.orderNumber);
                          }}
                        >
                          Print All
                        </button>
                      </div>

                      <div className="column">
                        {orderItem.items?.map((item) => {
                          if (
                            page.state.detailedView.orders[index].orderItems[i]
                              .show === true
                          ) {
                            return (
                              <Item key={item._id} item={item} page={page} />
                            );
                          }
                        })}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
export default BatchDetailedView;
