import AddressBlock from "../../../../components/AddressBlock";
import AddressBookHeader from "./AddressBookHeader";
import AddressTable from "./AddressTable";
import useServer from "../../../../hooks/useServer";
import format_save_address from "../../format_save_address";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
function AddressBook(props) {
  const { page } = props;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const selectedAddress = page.state.body.selectedAddress;
  const shippingAddress = page.state.body.selectedAddress.shippingAddress;
  const billingAddress = page.state.body.selectedAddress.billingAddress;

  const handleOnSave = async (e) => {
    e.preventDefault();
    if (page.validate()) return;
    const customerId = page.state.body.selectedAddress.customerId;
    const addressId = page.state.body.selectedAddress._id;
    console.log(addressId);
    const address = format_save_address(page.state.body.selectedAddress);
    const payload = {
      customerId,
      addressId,
      address,
    };
    const data = await post("/update/address", payload);
    if (data) {
      page.set("body.selectedAddress.lock", true);
      page.set("body.selectedAddress.shippingAddress.lock", true);
      page.set("body.selectedAddress.billingAddress.lock", true);
      page.init("body.addressBook", data.data.addressBook);
      setAlert({ type: "success", msg: data.msg });
    }
  };

  return (
    <>
      {page.state.body.selectedAddress.customerId === "" ? (
        <h4> Load Customer</h4>
      ) : (
        <form className="card-three-quarters" onSubmit={handleOnSave}>
          <AddressBookHeader page={page} />
          <div className="soft">
            <div className="grid-bar">
              <div className="half titleline">
                <AddressBlock
                  title="Shipping Address"
                  lock={shippingAddress.lock}
                  onLock={(e) => {
                    e.preventDefault();
                    page.toggle("body.selectedAddress.shippingAddress.lock");
                  }}
                  copyButton={{
                    text: "",
                    onClick: () => {},
                    hidden: true,
                  }}
                  address1={{
                    value: shippingAddress?.address1 ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.address1",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.shippingAddress.address1"
                      ],
                    errorMsg: "Address Line 1 is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.shippingAddress.address1",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error(
                          "body.selectedAddress.shippingAddress.address1"
                        );
                      }
                    },
                    css: "two-thirds",
                    placeholder: "Address Line 1",
                    required: true,
                    lock: shippingAddress.lock,
                  }}
                  address2={{
                    value: shippingAddress?.address2 ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.address2",
                        e.target.value
                      );
                    },
                    css: "two-thirds",
                    placeholder: "Address Line 2",
                    required: false,
                    error: "Address Line 2 is required",
                    lock: shippingAddress.lock,
                  }}
                  address3={{
                    value: shippingAddress?.address3 ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.address3",
                        e.target.value
                      );
                    },
                    css: "two-thirds",
                    placeholder: "Address Line 3",
                    required: false,

                    lock: shippingAddress.lock,
                  }}
                  city={{
                    value: shippingAddress?.city ?? "",

                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.city",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.shippingAddress.city"
                      ],
                    errorMsg: "City is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.shippingAddress.city",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error("body.selectedAddress.shippingAddress.city");
                      }
                    },

                    css: "half",
                    placeholder: "City",
                    required: true,

                    lock: shippingAddress.lock,
                  }}
                  state={{
                    value: shippingAddress?.state ?? "",

                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.state",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.shippingAddress.state"
                      ],
                    errorMsg: "State is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.shippingAddress.state",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error(
                          "body.selectedAddress.shippingAddress.state"
                        );
                      }
                    },
                    css: "half",
                    placeholder: "State",
                    required: true,
                    lock: shippingAddress.lock,
                  }}
                  country={{
                    value: shippingAddress?.country ?? "",

                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.country",
                        e.target.value
                      );
                    },
                    css: "two-thirds",
                    placeholder: "country",
                    required: true,

                    lock: shippingAddress.lock,
                  }}
                  postalCode={{
                    value: shippingAddress?.postalCode ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.shippingAddress.postalCode",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.shippingAddress.postalCode"
                      ],
                    errorMsg: "Postal Code is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.shippingAddress.postalCode",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error(
                          "body.selectedAddress.shippingAddress.postalCode"
                        );
                      }
                    },
                    css: "two-thirds",
                    placeholder: "Postal Code",
                    required: true,

                    lock: shippingAddress.lock,
                  }}
                />
              </div>
              <div className="half titleline">
                <AddressBlock
                  title="Billing Address"
                  lock={billingAddress.lock}
                  onLock={(e) => {
                    e.preventDefault();
                    page.toggle("body.selectedAddress.billingAddress.lock");
                  }}
                  copyButton={{
                    text: "Same as Primary?",
                    onClick: (e) => {
                      e.preventDefault();
                      page.set(
                        "body.selectedAddress.billingAddress",
                        shippingAddress
                      );
                      page.validate();
                    },
                    hidden: billingAddress.lock,
                  }}
                  address1={{
                    value: billingAddress?.address1 ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.address1",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.billingAddress.address1"
                      ],
                    errorMsg: "Address Line 1 is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.billingAddress.address1",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error(
                          "body.selectedAddress.billingAddress.address1"
                        );
                      }
                    },
                    css: "two-thirds",
                    placeholder: "Address Line 1",
                    required: true,

                    lock: billingAddress.lock,
                  }}
                  address2={{
                    value: billingAddress?.address2 ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.address2",
                        e.target.value
                      );
                    },
                    css: "two-thirds",
                    placeholder: "Address Line 2",
                    required: false,
                    error: "Address Line 2 is required",
                    lock: billingAddress.lock,
                  }}
                  address3={{
                    value: billingAddress?.address3 ?? "",
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.address3",
                        e.target.value
                      );
                    },
                    css: "two-thirds",
                    placeholder: "Address Line 3",
                    required: false,
                    error: "Address Line 3 is required",
                    lock: billingAddress.lock,
                  }}
                  city={{
                    value: billingAddress?.city ?? "",

                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.city",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.billingAddress.city"
                      ],
                    errorMsg: "City is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.billingAddress.city",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error("body.selectedAddress.billingAddress.city");
                      }
                    },
                    css: "half",
                    placeholder: "City",
                    required: true,

                    lock: billingAddress.lock,
                  }}
                  state={{
                    value: billingAddress?.state ?? "",
                    render: selectedAddress.render,
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.state",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.billingAddress.state"
                      ],
                    errorMsg: "State is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.billingAddress.state",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error("body.selectedAddress.billingAddress.state");
                      }
                    },
                    css: "half",
                    placeholder: "State",
                    required: true,

                    lock: billingAddress.lock,
                  }}
                  country={{
                    value: billingAddress?.country ?? "",
                    render: selectedAddress.render,
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.country",
                        e.target.value
                      );
                    },

                    css: "two-thirds",
                    placeholder: "country",
                    required: true,
                    error: "country is required",
                    lock: billingAddress.lock,
                  }}
                  postalCode={{
                    value: billingAddress?.postalCode ?? "",
                    render: selectedAddress.render,
                    onChange: (e) => {
                      page.set(
                        "body.selectedAddress.billingAddress.postalCode",
                        e.target.value
                      );
                    },
                    error:
                      page.state.errors[
                        "body.selectedAddress.billingAddress.postalCode"
                      ],
                    errorMsg: "Postal Code is required",
                    errorFunc: (mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedAddress.billingAddress.postalCode",
                          (reason) => {
                            return reason.length === 0;
                          }
                        );
                      } else {
                        page.error(
                          "body.selectedAddress.billingAddress.postalCode"
                        );
                      }
                    },
                    css: "two-thirds",
                    placeholder: "Postal Code",
                    required: true,

                    lock: billingAddress.lock,
                  }}
                />
              </div>
            </div>
          </div>
          <AddressTable page={page} />
        </form>
      )}
    </>
  );
}
export default AddressBook;
