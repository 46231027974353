import Option from "./Option";
import OptionEdit from "./OptionEdit";
import React, { Fragment } from "react";
function ProductOptions(props) {
  const { page, component, drag, componentIndex, lock } = props;

  return (
    <>
      {component.options.map((option, optionIndex) => {
        const color = optionIndex % 2 ? "note" : "";
        return option.edit === true ? (
          <OptionEdit
            key={`option${optionIndex}${option.id}`}
            componentIndex={componentIndex}
            optionIndex={optionIndex}
            page={page}
            option={option}
            drag={drag}
            color={color}
            lock={lock}
          />
        ) : (
          <Option
            key={`option${optionIndex}${option.id}`}
            componentIndex={componentIndex}
            optionIndex={optionIndex}
            page={page}
            option={option}
            drag={drag}
            lock={lock}
            color={color}
          />
        );
      })}
    </>
  );
}
export default ProductOptions;
