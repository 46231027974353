function Error(props) {
  const fill = props.fill || "#000000";
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="12px"
      height="12px"
      viewBox="0 0 12 12"
      xmlSpace="preserve"
    >
      <circle cx="6" cy="6" r="6" fill={fill} />
      <g>
        <polygon fill="#FFFFFF" points="6.9,2.4 5.1,2.4 5.4,7.1 6.6,7.1 	" />
        <rect x="5.4" y="8.2" fill="#FFFFFF" width="1.2" height="1.2" />
      </g>
    </svg>
  );
}

export default Error;
