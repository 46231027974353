import DropDown from "../../../components/DropDown";
import ShipstationStore from "./ShipstationStore";
function CustomerSelect(props) {
  const { page } = props;
  const currentCustomer = page.state.modal.currentCustomer;

  const on_select = async (customer) => {
    page.set("modal.currentCustomer", customer);
    page.set("modal.currentCustomer.provider.data.selectedStore", "");
    page.reset("modal.orderItems.lines");
    //Products are loaded into store on useOrders useEffect
  };

  const on_fill = (address) => {
    page.set("modal.addressId", address.id);
    page.set("modal.general.firstName", address.firstName);
    page.set("modal.general.lastName", address.lastName);
    page.set("modal.general.company", address.company);
    page.set("modal.general.website", address.website);
    page.set("modal.general.email", address.email);
    page.set("modal.general.phone", address.phone);
    page.set("modal.shippingAddress", address.shippingAddress);
    page.set("modal.billingAddress", address.billingAddress);
  };

  return (
    <div className="grid-bar">
      <h3 className="span-3">
        {currentCustomer?.profileName ?? "Select Customer"}
      </h3>
      <div className="span-3">
        <DropDown
          value={page.state.modal.customers}
          lock={page.state.modal.orderInformation.lock}
          title={currentCustomer?.profileName ?? "Customer"}
        >
          {page.state.stores.customers.map((customer) => {
            return (
              <div
                className="dropdown-option"
                key={customer._id}
                value={customer.id}
                onClick={() => {
                  on_select(customer);
                }}
              >
                {customer.profileName}
              </div>
            );
          })}
        </DropDown>
      </div>
      <DropDown
        title="Quick Fill"
        value={page.state.modal.addressId}
        css="span-3"
      >
        {currentCustomer?.addressBook?.map((address) => {
          return (
            <div
              className="dropdown-option"
              key={address._id}
              value={address.id}
              onClick={() => {
                on_fill(address);
              }}
            >
              <h6>{`${address.firstName} ${address.lastName}`}</h6>
              <p>
                {`${address.shippingAddress.address1}
              ${address.shippingAddress.city}
              ${address.shippingAddress.state}
              ${address.shippingAddress.postalCode}`}
              </p>
            </div>
          );
        })}
      </DropDown>
      <div className="span-3">
        {currentCustomer?.provider.source === "shipstation" ? (
          <ShipstationStore page={page} currentCustomer={currentCustomer} />
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
export default CustomerSelect;
