import DropDown from "../../../components/DropDown";
import NumberInput from "../../../components/NumberInput";
import TextInput from "../../../components/TextInput";
import useServer from "../../../hooks/useServer";
import CloseIcon from "../../../icons/Close";

function LineItem(props) {
  const { page, line, index } = props;

  const { post } = useServer();
  const skus = page.state.modal.orderItems.lines[index].skus;
  const lock = page.state.modal.orderItems.lock;
  const render = page.state.modal.render;

  const setLine = async (product, index) => {
    page.set(`modal.orderItems.lines.${index}.product`, product.name);
    page.set(`modal.orderItems.lines.${index}.itemProductId`, product._id);
    const data = await post("/customer/skus", product._id);

    if (data) {
      page.set(`modal.orderItems.lines.${index}.skus`, data);
      page.set(`modal.orderItems.lines.${index}.sku`, "");
    }
  };

  return (
    <div className="grid-bar titleline list">
      <div className="span-2 bar">
        <div className="input-label">Name:</div>
        <TextInput
          value={line.name}
          lock={lock}
          onChange={(e) => {
            page.set(`modal.orderItems.lines.${index}.name`, e.target.value);
          }}
        />
      </div>
      <div className="span-2 bar">
        <div className="input-label">Product:</div>
        <DropDown
          title={line.product !== "" ? line.product : "Products"}
          css="third"
          lock={lock}
          value={line.itemProductId}
          error={page.state.errors[`modal.orderItems.lines.${index}.product`]}
          errorMsg={"Choose Product"}
          errorFunc={(mount) => {
            if (mount) {
              page.error(
                `modal.orderItems.lines.${index}.product`,
                (reason) => {
                  return reason === "";
                }
              );
            } else {
              page.error(`modal.orderItems.lines.${index}.product`);
            }
          }}
        >
          {page.state.stores?.products?.map((product, i) => {
            return (
              <div
                className="dropdown-option"
                key={`${product._id}-${i}`}
                value={product.id}
                onClick={() => {
                  setLine(product, index);
                }}
              >
                {product.name}
              </div>
            );
          })}
        </DropDown>
      </div>
      <div className="span-2 bar ">
        <div className="input-label">Sku:</div>
        <DropDown
          title={line.sku !== "" ? line.sku : "Choose Sku"}
          css="third"
          lock={lock}
          value={line.sku}
          error={page.state.errors[`modal.orderItems.lines.${index}.sku`]}
          errorMsg={"Choose Sku"}
          errorFunc={(mount) => {
            if (mount) {
              page.error(`modal.orderItems.lines.${index}.sku`, (reason) => {
                return reason === "";
              });
            } else {
              page.error(`modal.orderItems.lines.${index}.sku`);
            }
          }}
        >
          {skus?.map((each) => {
            return (
              <div
                className="dropdown-option"
                key={each._id}
                onClick={() => {
                  //CONSIDER:: check for duplicate sku unless it breaks shipstation
                  page.set(`modal.orderItems.lines.${index}.sku`, each.sku);
                  page.set(`modal.orderItems.lines.${index}.skuId`, each._id);
                }}
              >
                {each.sku}
              </div>
            );
          })}
        </DropDown>
      </div>
      <div className="span-2 bar center">
        <div className="input-label">Qty:</div>
        <NumberInput
          value={line.quantity}
          render={render}
          lock={lock}
          onChange={(e) => {
            page.set(
              `modal.orderItems.lines.${index}.quantity`,
              e.target.value
            );
          }}
          css="span-1"
          error={page.state.errors[`modal.orderItems.lines.${index}.quantity`]}
          errorMsg={"Atleast 1 required"}
          errorFunc={(mount) => {
            if (mount) {
              page.error(
                `modal.orderItems.lines.${index}.quantity`,
                (reason) => {
                  let int = reason;
                  reason === "" ? (int = 0) : (int = parseInt(reason));
                  return int <= 0;
                }
              );
            } else {
              page.error(`modal.orderItems.lines.${index}.quantity`);
            }
          }}
        />
      </div>
      <div
        className="span-2 bar center"
        style={lock ? { display: "flex" } : { display: "none" }}
      >
        <div className="input-label">Allocated:</div>
        <div>{line.allocate} </div>
      </div>
      <div
        className="span-2 bar center"
        style={lock ? { display: "flex" } : { display: "none" }}
      >
        <div className="input-label">Batched:</div>
        <div>{line.batched} </div>
      </div>
      <div className="span-2 bar center">
        <div className="input-label">$/unit:</div>
        <NumberInput
          value={line.unitPrice}
          lock={lock}
          onChange={(e) => {
            page.set(
              `modal.orderItems.lines.${index}.unitPrice`,
              e.target.value
            );
          }}
          css="span-1"
        />
      </div>
      {lock ? (
        <></>
      ) : (
        <button
          className="btn-icon"
          onClick={(e) => {
            e.preventDefault();
            page.remove(`modal.orderItems.lines`, index);
          }}
        >
          <CloseIcon />
        </button>
      )}
    </div>
  );
}
export default LineItem;
