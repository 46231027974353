function OrderTableHeader(props) {
  const { page } = props;

  const columnResize = (e, index, width) => {
    const dragFeild = document.querySelector("#order-header");
    let initial = e.clientX;
    let min = e.target.previousSibling.offsetWidth; // minimum width of the column
    dragFeild.ondragover = (e) => {
      let current = e.clientX;
      let position = current - initial;
      let diff = width + position;

      let updateWidth = diff < min ? min : diff;
      let updated = page.state.tableInfo.columns.map((col) => {
        return {
          ...col,
          width: col.index === index ? updateWidth : col.width,
        };
      });

      localStorage.setItem("orders", JSON.stringify(updated));

      page.set(`tableInfo.columns`, updated);
    };
  };

  return (
    <div id="order-header" className="header">
      {page.state.tableInfo?.columns?.map((cell) => {
        return (
          <div
            key={cell.index}
            className="header-cell"
            style={{ width: cell.width }}
          >
            <p className="item">{cell.name}</p>
            <div
              id={cell.index}
              className="header-slide"
              draggable="true"
              onDragStart={(e) => {
                columnResize(e, cell.index, cell.width);
              }}
            ></div>
          </div>
        );
      })}
    </div>
  );
}
export default OrderTableHeader;
