import { useEffect, useState, useRef } from "react";

function WarningBox(props) {
  const { children, isError, errorMsg } = props;
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isError === true) {
      setError(true);
    } else {
      setError(false);
    }
  }, [isError]);

  const box = {
    border: error === true ? "2px solid var(--warning)" : "none",
    borderRadius: "var(--border-radius)",
  };
  const message = {
    display: error === true ? "block" : "none",
  };

  return (
    <div className="error-box">
      <div style={box}>{children}</div>
      <p className="warning-msg" style={message}>
        {errorMsg}
      </p>
    </div>
  );
}
export default WarningBox;
