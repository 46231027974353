const get_shop_totals = (employee) => {
  const totals = { avg: 0, mode: 0, cost: 0, count: 0 };
  if (employee.length === 0) return totals;
  employee.forEach((emp) => {
    totals.avg += emp.totals.avg * emp.totals.count; // we are correcting to the weighted average
    totals.mode += emp.totals.mode;
    totals.cost += emp.totals.cost * emp.totals.count;
    totals.count += emp.totals.count;
  });
  totals.avg = totals.avg / totals.count;
  totals.mode = totals.mode / employee.length;
  totals.cost = totals.cost / totals.count;
  return totals;
};
export default get_shop_totals;
