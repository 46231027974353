import { useState, useEffect, useCallback } from "react";
import useAuth from "../../context/User/useAuth";
import axios from "../../utils/axios";
import { Outlet, useNavigate } from "react-router-dom";

axios.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response.status === 403) {
      return error.response;
    }
    return Promise.reject(error);
  }
);

const refresh = async () => {
  try {
    const response = await axios.get("/user/refresh", {
      withCredentials: true,
    });
    if (response) {
      return response.data;
    }
  } catch (err) {
    console.log(err);
  }
};

function UserRefresh() {
  const { auth, setAuth } = useAuth();
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const callbackRefresh = useCallback(async () => {
    try {
      return await refresh();
    } catch (err) {
      console.log(err);
    }
  }, []);

  useEffect(() => {
    const verifyRefresh = async () => {
      try {
        const data = await callbackRefresh();
        if (data.err) {
          navigate("/login");
          setIsLoading(false);
        } else if (data.accessToken) {
          setAuth((prev) => {
            return {
              ...prev,
              role: data.role,
              name: data.name,
              accessToken: data.accessToken,
            };
          });
          setIsLoading(false);
        }
      } catch (err) {
        console.log(err);
      }
    };
    verifyRefresh();
  }, [callbackRefresh, setAuth, navigate]);

  useEffect(() => {
    if (!auth?.accessToken) {
      setIsLoading(true);
    } else {
      setIsLoading(false);
    }
  }, [auth.accessToken]);

  return (
    <>
      {isLoading ? <div className="column soft">Loading...</div> : <Outlet />}
    </>
  );
}

export default UserRefresh;
