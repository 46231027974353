import useServer from "../../hooks/useServer";
import DropDown from "../../components/DropDown";
import AddTask from "./AddTask";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
function AddJob(props) {
  const { page } = props;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const modal = page.state.modal2;

  const submit_jobs = async () => {
    if (page.validate()) return;
    const payload = {
      batch: modal.batchName,
      batchId: modal.batchId,
      empId: modal._id,
      customerId: modal.customerId,
      jobs: modal.products,
      date: new Date(),
    };

    const data = await post("/employees/assign/job", payload);
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      page.reset("modal2");
      page.reload("stores.employees");
    }
  };

  const submit_all_jobs = async () => {
    if (modal.items.length === 0) {
      page.set("modal2.alert", { type: "error", msg: "Select batch" });
      return;
    }
    const all = [];
    modal.items.forEach((item) => {
      item.steps.forEach((step) => {
        if (step.constant === 0) {
          all.push({
            product: item.name,
            productId: item.productId,
            sku: item.sku,
            skuId: item.skuId,
            quantity: item.quantity,
            outOf: item.quantity,
            stepId: step._id,
            step: step.name,
            desc: step.desc,
          });
        }
      });
    });

    const payload = {
      batch: modal.batchName,
      batchId: modal.batchId,
      empId: modal._id,
      customerId: modal.customerId,
      jobs: all,
      date: new Date(),
    };
    const data = await post("/employees/assign/job", payload);
    if (data) {
      console.log(data);
      setAlert({ msg: data.msg, type: data.type });
      page.reset("modal2");
      page.reload("stores.employees");
    }
  };

  const group_byItems = (batch) => {
    const items = {};
    batch.map((order) => {
      order.orderItems.forEach((item) => {
        let id = item.skuId;
        if (items[id] === undefined) {
          items[id] = {
            productId: item.itemProductId,
            name: item.name,
            sku: item.sku,
            skuId: item.skuId,
            quantity: item.quantity,
            outOf: item.quantity,
            steps: item.steps,
            step: "",
            desc: "",
          };
        } else {
          items[id].quantity += item.quantity;
        }
      });
    });

    const itemsArray = Object.keys(items).map((key) => ({
      _id: key,
      ...items[key],
    }));

    return itemsArray;
  };

  return (
    <div>
      <h4 className="list titleline">
        Assign Jobs to {`${modal.firstName} ${modal.lastName}`}
      </h4>
      <div className="grid-bar">
        <div className="span-6 bar">
          <div className="pB pR">From:</div>
          <DropDown title={modal.batchName === "" ? "Batch" : modal.batchName}>
            {page.state.stores.batches.map((batch) => {
              return (
                <div
                  className="dropdown-option"
                  key={batch._id}
                  onClick={async () => {
                    page.set("modal2.batchName", batch.batchName);
                    page.set("modal2.batchId", batch._id);
                    const data = await post("/batches/job", {
                      batchId: batch._id,
                    });
                    if (data) {
                      page.set("modal2.batch", data);
                      page.set("modal2.customerId", data[0].customerId);
                      page.set("modal2.items", group_byItems(data));
                    }
                  }}
                >
                  {batch.batchName}
                </div>
              );
            })}
          </DropDown>
        </div>
      </div>
      <div className="grid-bar titleline list">
        <div className="span-2 list">
          <button
            className="btn-default"
            onClick={() => {
              page.add("modal2.products", {
                sku: "",
                quantity: 0,
                outOf: 0,
                steps: [],
                step: "",
                desc: "",
              });
            }}
          >
            Add Job
          </button>
        </div>
        {modal.products.length > 0 ? (
          <div className="span-10 right pair">
            <div className="item">
              {`Assign the below ${modal.products.length} jobs to ${modal.firstName} ${modal.lastName}?`}
            </div>
            <button
              className="btn-primary"
              onClick={() => {
                submit_jobs();
              }}
            >
              Confirm
            </button>
          </div>
        ) : (
          <div className="span-10 right pair">
            <div className="item">
              {`Assign all production from batch - ${modal.batchName} - to ${modal.firstName} ${modal.lastName}?`}
            </div>
            <button
              className="btn-primary"
              onClick={() => {
                submit_all_jobs();
              }}
            >
              Confirm
            </button>
          </div>
        )}
      </div>
      <AddTask page={page} />
    </div>
  );
}
export default AddJob;
