import DropDown from "../../components/DropDown";
function UserPanelDisplay(props) {
  const { page } = props;

  const map_roles = (role) => {
    return role === 1776 ? "Admin" : "User";
  };

  const handleOnClick = (e) => {
    e.preventDefault();
    page.toggle("userPanel.edit");
  };

  return (
    <>
      <div className="bar relative">
        <DropDown
          title={page.state.userPanel.name}
          value={page.state.userPanel.current}
        >
          {page.state.stores.users.map((user) => {
            return (
              <div
                className="dropdown-option"
                key={user._id}
                onClick={() => {
                  page.set("userPanel.current", user._id);
                  page.set("userPanel.edit", false);
                  page.set("userPanel.name", user.name);
                  page.set("userPanel.email", user.email);
                  page.set("userPanel.roles", user.roles);
                }}
              >
                {user.name}
              </div>
            );
          })}
        </DropDown>
        <div
          className="table-cell-link list-item"
          onClick={(e) => {
            handleOnClick(e);
          }}
        >
          Edit
        </div>
      </div>
      <div className="list-item">
        <b>Role:</b> {map_roles(page.state.userPanel.role)}
      </div>
      <div className="list-item">
        <b>Name:</b> {page.state.userPanel.name}
      </div>
      <div className="list-item">
        <b>Email:</b> {page.state.userPanel.email}
      </div>
    </>
  );
}

export default UserPanelDisplay;
