function copy_requirements(page, componentIndex, optionIndex) {
  const copy =
    page.state.body.selectedProduct.components[componentIndex].options[
      optionIndex
    ].requirements;
  const options =
    page.state.body.selectedProduct.components[componentIndex].options;

  const update = options.map((option) => {
    return { ...option, edit: true, requirements: copy };
  });
  page.set(`body.selectedProduct.components.${componentIndex}.options`, update);
}

export default copy_requirements;
