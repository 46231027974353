import React from "react";
import Calendar from "react-calendar";

function Home() {
  return (
    <>
      <div className="page">
        <div className="container column">
          <div className="column thirds shadow list">
            <h3>Calendar</h3>
            <Calendar />
          </div>
        </div>
      </div>
    </>
  );
}
export default Home;
