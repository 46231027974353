import React from "react";
import NumberInput from "../../components/NumberInput";
function CustomerLine(props) {
  const { customer, page, color, index } = props;

  const add_remove_change = (sku, e, type) => {
    let c = page.state.body.changes.indexOf(
      page.state.body.changes.find((change) => change._id === sku._id)
    );
    let clone = {
      ...sku,
      quantity: parseInt(e.target.value),
    };
    page.remove("body.changes", c);
    if (type === "add") {
      page.add("body.changes", clone);
    }
  };

  return (
    <div className={`grid-bar titleline ${color}`}>
      <b className="span-3">{customer.profileName}</b>
      <div className="span-9 card">
        <div className="grid-bar titleline">
          <b className="span-2">Product</b>
          <b className="span-10">Skus</b>
        </div>
        {customer.products?.map((product, ind) => {
          return (
            <React.Fragment key={product._id}>
              <div className="grid-bar span-9">
                <div
                  className="span-2"
                  style={
                    product.archived === true ? { color: "var(--warning)" } : {}
                  }
                >{`${product.name} ${
                  product.archived === true ? "'Archived'" : ""
                }`}</div>
                <div
                  style={
                    product.show === false
                      ? { color: "var(--text)" }
                      : { color: "var(--primary)" }
                  }
                  className="btn-link span-2"
                  onClick={() => {
                    page.toggle(
                      `stores.customers.${index}.products.${ind}.show`
                    );
                  }}
                >
                  {product.show === false ? "▼" : "▲"}
                </div>
              </div>
              <div className="grid-bar titleline">
                <div className="span-2"></div>
                <div className="span-10">
                  {product.skus.map((sku, i) => {
                    {
                      return product.show === true ? (
                        <div className="grid-bar span-7" key={sku._id}>
                          <div className="span-4">{sku.sku}</div>
                          <div
                            style={{ color: sku.color }}
                            className="span-3 click"
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                page.toggle(
                                  `stores.customers.${index}.products.${ind}.skus.${i}.show`
                                );
                              }
                            }}
                            onDoubleClick={() => {
                              page.toggle(
                                `stores.customers.${index}.products.${ind}.skus.${i}.show`
                              );
                            }}
                          >
                            {sku.show === false ? (
                              sku.quantity
                            ) : (
                              <div className="span-1">
                                <NumberInput
                                  value={sku.quantity}
                                  onChange={(e) => {
                                    page.set(
                                      `stores.customers.${index}.products.${ind}.skus.${i}.quantity`,
                                      e.target.value
                                    );
                                    if (parseInt(e.target.value) > sku.amount) {
                                      add_remove_change(sku, e, "add");
                                      page.set(
                                        `stores.customers.${index}.products.${ind}.skus.${i}.color`,
                                        "var(--primary)"
                                      );
                                    } else if (
                                      parseInt(e.target.value) < sku.amount
                                    ) {
                                      add_remove_change(sku, e, "add");
                                      page.set(
                                        `stores.customers.${index}.products.${ind}.skus.${i}.color`,
                                        "var(--warning)"
                                      );
                                    } else {
                                      add_remove_change(sku, e, "remove");
                                      page.set(
                                        `stores.customers.${index}.products.${ind}.skus.${i}.color`,
                                        "var(--text)"
                                      );
                                    }
                                  }}
                                />
                              </div>
                            )}
                          </div>
                        </div>
                      ) : (
                        <React.Fragment key={sku._id}></React.Fragment>
                      );
                    }
                  })}
                </div>
              </div>
            </React.Fragment>
          );
        })}
      </div>
    </div>
  );
}
export default CustomerLine;
