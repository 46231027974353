import { createContext, useState } from "react";

export const UserContext = createContext({ accessToken: "", role: 0 });

export const UserProvider = ({ children }) => {
  const [auth, setAuth] = useState({ accessToken: "", role: 0 });

  return (
    <UserContext.Provider value={{ auth, setAuth }}>
      {children}
    </UserContext.Provider>
  );
};
