import { useEffect } from "react";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
function OrderMenu(props) {
  const { page } = props;
  const { setAlert } = useGlobalAlert();

  useEffect(() => {
    if (page.state.search === "") {
      filter_by_search({ preventDefault: () => {} });
    }
  }, [page.state.search]);

  const orderFilter = (e, type) => {
    let payload = [];
    if (e.target.value === "all") {
      payload = [...page.state.filters].filter(
        (filter) => filter.type !== type
      );
      page.set("filters", payload);
      return;
    }
    if (!page.state.filters.find((filter) => filter.type === type)) {
      payload = [...page.state.filters, { type: type, id: e.target.value }];
    } else {
      payload = [...page.state.filters].map((filter) => {
        if (filter.type === type) {
          return {
            ...filter,
            id: e.target.value,
          };
        }
        return filter;
      });
    }
    page.set("filters", payload);
  };

  const filter_by_search = (e) => {
    e.preventDefault();
    const id = page.state.search;
    let payload = [];
    if (id === "") {
      payload = [...page.state.filters].filter(
        (filter) => filter.type !== "search"
      );
      page.set("filters", payload);
      return;
    }
    if (!page.state.filters.find((filter) => filter.type === "search")) {
      payload = [...page.state.filters, { type: "search", id: id }];
    } else {
      payload = [...page.state.filters].map((filter) => {
        if (filter.type === "search") {
          return {
            ...filter,
            id: id,
          };
        }
        return filter;
      });
    }
    page.set("filters", payload);
  };

  const on_toggle_batch = (e) => {
    e.preventDefault();
    page.reset("errors");
    setAlert({ type: "clear", msg: "" });
    page.set("mode", page.state.mode === "queue" ? "create" : "queue");
    page.toggle("body.cueSidebar.open");
    page.set("body.cueTable.open", false);
  };

  const handleOnNew = (e) => {
    e.preventDefault();
    page.set("modal.open", true);
  };

  //COMPOENT
  return (
    <div className="container">
      <div className="card shadow soft">
        <h1>Orders</h1>
        <button
          className="btn-default item"
          onClick={(e) => {
            on_toggle_batch(e);
          }}
        >
          Toggle Batch
        </button>
        <div className="pair item">
          <label className="form-label item">Filter By Date:</label>
          <select
            className="selector-default"
            onChange={(e) => {
              orderFilter(e, "date");
            }}
          >
            <option value="all">Default</option>
            <option value="newest">Newest</option>
            <option value="oldest">Oldest</option>
          </select>
        </div>
        <div className="pair item">
          <label className="form-label item">Filter By Store:</label>
          <select
            className="selector-default"
            onChange={(e) => {
              orderFilter(e, "store");
            }}
          >
            <option value="all">All</option>
            {page.state.stores.customers.map((customer) => {
              return (
                <option
                  key={customer._id}
                  className="dropdown-item"
                  value={customer._id}
                >
                  {customer.profileName}
                </option>
              );
            })}
          </select>
        </div>
        <div className="pair item">
          <label className="form-label item">Filter By Status:</label>
          <select
            className="selector-default"
            onChange={(e) => {
              orderFilter(e, "status");
            }}
          >
            <option value="all">All</option>
            <option value="new">New</option>
          </select>
        </div>
        <div className="pair item">
          <input
            type="text"
            className="form-input-small"
            value={page.state.search}
            onChange={(e) => {
              page.set("search", e.target.value);
            }}
            onKeyDown={(e) => {
              if (e.key !== "Enter") return;
              filter_by_search(e);
            }}
          />
          <button
            className="btn-default"
            onClick={(e) => {
              filter_by_search(e);
            }}
          >
            Search
          </button>
        </div>
        <div className="pair item">
          <button
            className="btn-primary"
            disabled={page.state.mode === "queue" ? true : false}
            onClick={(e) => {
              handleOnNew(e);
            }}
          >
            New Order
          </button>
        </div>
      </div>
    </div>
  );
}
export default OrderMenu;
