function Item(props) {
  const { item, page } = props;

  //FUNCTIONS
  const formatItemLocation = (item) => {
    if (item.checkpoints.length === 0)
      return { formatted: "Not Scanned", date: "" };
    let location = item.checkpoints?.slice(item.checkpoints.length - 1)[0].name;
    let date = item.checkpoints
      ?.slice(item.checkpoints.length - 1)[0]
      .date.split("T")[0];
    let formatted = location?.slice(0, 1)[0].toUpperCase() + location.slice(1);
    return { formatted, date };
  };

  const on_print = (item) => {
    let bc = [];

    item.parts.forEach((part, index) => {
      let clone = { ...item };
      clone.batchName = page.state.detailedView.batchName;
      clone.partName = part?.name ?? "none";
      clone.partNumber = index + 1;
      clone.totalParts = item?.parts?.length ?? 0;
      bc = [...bc, clone];
    });

    page.set("modal", {
      open: true,
      barcodes: bc,
    });
  };

  //COMPONENT
  return (
    <div className="column list note round soft">
      <div className="grid-bar list-item">
        <div className="span-3 pair">
          <p className="">Item: </p>
          <b className="item">{`${item.name} ${item.sku}`}</b>
        </div>
        <div className="pair item span-6">
          <p>Barcode: </p>
          <b className="item">{item._id}</b>
        </div>
        <div className="span-1 s-12 right">
          <button
            className="btn-default item"
            onClick={() => {
              on_print(item);
            }}
          >
            Print
          </button>
        </div>
      </div>

      <div className="grid-bar list">
        <div className="pair span-12">
          <p>Status: </p>
          <div className="item">
            {formatItemLocation(item).formatted} Date:{" "}
            {formatItemLocation(item).date}
          </div>
        </div>
      </div>
    </div>
  );
}
export default Item;
