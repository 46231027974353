import useBatches from "../features/batches/useBatches";
import { useState } from "react";
import Spinner from "../icons/Spinner";
import BatchList from "../features/batches/BatchList";
import BatchDetailedView from "../features/batches/BatchDetailedView";
import BatchBarcodeModal from "../features/batches/BatchBarcodeModal";

function Batches() {
  const [alerts, setAlert] = useState([]);
  const page = useBatches();

  return (
    <div className="page">
      {page.state.modal?.open === false ? (
        <></>
      ) : (
        <BatchBarcodeModal page={page} />
      )}
      <div className="container">
        {page.isLoading["stores.batches"] ? (
          <div className="column shadow soft">{<Spinner />}</div>
        ) : (
          <>
            <div className="bar">
              <BatchList page={page} />
              <BatchDetailedView
                page={page}
                alerts={alerts}
                setAlert={setAlert}
              />
            </div>
          </>
        )}
      </div>
    </div>
  );
}
export default Batches;
