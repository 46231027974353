import Items from "./Items";
import Materials from "./Materials";
function Body(props) {
  const { page } = props;
  return (
    <div className="soft card shadow">
      <h1>
        {page.state.body.type === "items" ? "Customer Items" : "Materials"}
      </h1>
      {page.state.body.type === "items" ? (
        <Items page={page} />
      ) : (
        <Materials page={page} />
      )}
    </div>
  );
}
export default Body;
