import React from "react";
import useInventory from "../features/inventory/useInventory.js";
import Menu from "../features/inventory/Menu.jsx";
import Body from "../features/inventory/Body.jsx";
import ModalBase from "../components/ModalBase.jsx";
import NewMaterial from "../features/inventory/NewMaterial.jsx";
import Spinner from "../icons/Spinner.jsx";

function Inventory() {
  const page = useInventory();

  return (
    <div className="page">
      <ModalBase
        page={page}
        title={"New Material"}
        close={() => {
          page.set("modal.open", false);
          page.reset("modal");
        }}
        open={page.state.modal.open}
      >
        <NewMaterial page={page} />
      </ModalBase>
      <Menu page={page} />
      {page.isLoading["stores.materials"] ||
      page.isLoading["stores.customers"] ? (
        <Spinner />
      ) : (
        <Body page={page} />
      )}
    </div>
  );
}
export default Inventory;
