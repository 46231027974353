function CloseIcon(props) {
  let fill = props.fill;
  fill = undefined ? "black" : fill;
  return (
    <svg
      id="Layer_2"
      className="icon"
      data-name="Layer 2"
      xmlns="http://www.w3.org/2000/svg"
      width="20.3472"
      height="20.3472"
      viewBox="-1 -1 20.3472 20.3472"
      fill={fill}
    >
      <path
        d="M8.25,16.4238A8.1736,8.1736,0,1,1,16.4238,8.25,8.1829,8.1829,0,0,1,8.25,16.4238Zm0-15.3471A7.1736,7.1736,0,1,0,15.4238,8.25,7.1811,7.1811,0,0,0,8.25,1.0767Z"
        transform="translate(-0.0767 -0.0767)"
      />
      <path
        d="M10.66,9.95a.5134.5134,0,0,1,0,.71.4973.4973,0,0,1-.36.14.4906.4906,0,0,1-.35-.14l-1.7-1.7-1.7,1.7a.4973.4973,0,0,1-.36.14.4906.4906,0,0,1-.35-.14.5134.5134,0,0,1,0-.71l1.7-1.7-1.7-1.7a.502.502,0,0,1,.71-.71l1.7,1.7,1.7-1.7a.502.502,0,0,1,.71.71l-1.7,1.7Z"
        transform="translate(-0.0767 -0.0767)"
      />
    </svg>
  );
}

export default CloseIcon;
