import { Route, Routes } from "react-router-dom";
import Header from "./components/headers/Header";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Inventory from "./pages/Inventory";
import Layout from "./pages/Layout";
import ProfileAdmin from "./pages/ProfileAdmin";
import RoleCheck from "./context/User/RoleCheck";
import Orders from "./pages/Orders";
import Batches from "./pages/Batches";
import Customers from "./pages/Customers";
import Employee from "./pages/Employee";
import Metrics from "./pages/Metrics";
import "./app.css";
import "./ink.css";
import "./drag.css";
import "./ellipse.css";
import "./animations.css";
import UserRefresh from "./context/User/UserRefresh";

function App() {
  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/login" element={<Login />} />
        <Route path="/unauthorized" />
        <Route path="*" element={<h1>404</h1>} />

        {/* Protected Routes */}
        <Route element={<UserRefresh />}>
          <Route element={<Header allowedRoles={[1776, 1999]} />}>
            <Route element={<RoleCheck allowedRoles={[1776, 1917, 1999]} />}>
              <Route path="/" element={<Home />}></Route>
              <Route path="/inventory" element={<Inventory />} />
              <Route path="/orders" element={<Orders />} />
              <Route path="/batches" element={<Batches />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/employee" element={<Employee />} />
              <Route path="/metrics" element={<Metrics />} />
              <Route path="/profileAdmin" element={<ProfileAdmin />} />
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  );
}

export default App;
