import DropDown from "../../../../components/DropDown";
function ProcessHeader(props) {
  const { page } = props;

  return (
    <>
      <div className="grid-bar titleline note heading">
        <h3 className="half ">Process Information</h3>
      </div>
      <div className="grid-bar gap">
        <div className="span-2 right">
          <button
            className="btn-primary"
            onClick={(e) => {
              e.preventDefault();
              page.set("body.mode", "new");
              page.reset(`body.selectedProcess`);
            }}
          >
            New
          </button>
        </div>
        <div className="span-10">
          <DropDown
            title={
              page.state.body.selectedProcess.name === ""
                ? "Load Process"
                : page.state.body.selectedProcess.name
            }
            value={page.state.body.selectedProcess?._id ?? ""}
            css="half"
          >
            {page.state.stores.process.length === 0 ? (
              <></>
            ) : (
              page.state.stores.process.map((store) => {
                return (
                  <div
                    className="dropdown-option"
                    onClick={() => {
                      const select = page.state.stores.process.find(
                        (each) => each._id === store._id
                      );
                      page.set("body.mode", "edit");
                      page.set(`body.selectedProcess`, select);
                    }}
                    key={store._id}
                    value={store._id}
                    data-name={store.name}
                  >
                    {store.name}
                  </div>
                );
              })
            )}
          </DropDown>
        </div>
      </div>
      <div className="grid-bar titleline list"></div>
    </>
  );
}
export default ProcessHeader;
