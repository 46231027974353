import LineItem from "./LineItem";
import Lock from "../../../icons/Lock";
import Unlock from "../../../icons/Unlock";
import ErrorBox from "../../../components/ErrorBox";

import React, { useEffect } from "react";
function OrderItems(props) {
  const { page, title } = props;

  const lock = page.state.modal.orderItems.lock;
  const { error } = page;

  useEffect(() => {
    error("modal.orderItems.lines", (reason) => {
      return reason.length === 0;
    });
    return () => {
      error("modal.orderItems.lines");
    };
  }, [error]);

  const addLine = () => {
    page.add(`modal.orderItems.lines`, {
      providerItemId: "",
      itemProductId: "",
      name: "",
      sku: "",
      quantity: "",
      unitPrice: "",
    });
  };

  return (
    <ErrorBox
      isError={page.state.errors["modal.orderItems.lines"]}
      errorMsg={"At least one Item is required"}
    >
      <div className="grid-bar titleline list">
        <div className="span-6 pair">
          <h3 className="item">{title}</h3>
          <button
            className="btn-icon"
            onClick={() => {
              page.toggle("modal.orderItems.lock");
            }}
          >
            {!lock ? <Unlock /> : <Lock fill="var(--text)" />}
          </button>
        </div>
      </div>
      <div>
        {page.state.modal.orderItems.lines.map((line, index) => {
          return (
            <React.Fragment key={`line${index}`}>
              <LineItem page={page} line={line} index={index} />
            </React.Fragment>
          );
        })}
      </div>
      <div className="grid-bar">
        <div className="span-1">
          {lock ? (
            <></>
          ) : (
            <button
              className="btn-default"
              onClick={() => {
                addLine();
              }}
            >
              Item +
            </button>
          )}
        </div>
      </div>
    </ErrorBox>
  );
}
export default OrderItems;
