function Lock(props) {
  const fill = props.fill;
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="15.525"
      height="15"
      viewBox="0 0 41.2 40"
      xmlSpace="preserve"
      fill={fill}
    >
      <g id="XMLID_509_">
        <path
          id="XMLID_510_"
          d="M1.8,40h24.2c1,0,1.8-0.8,1.8-1.8V17.6c0-1-0.8-1.8-1.8-1.8h-1.8v-5.5C24.2,4.6,19.6,0,13.9,0
       S3.6,4.6,3.6,10.3v5.5H1.8c-1,0-1.8,0.8-1.8,1.8v20.6C0,39.2,0.8,40,1.8,40z M15.8,28.5v2.4c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8
       v-2.4c-0.7-0.6-1.2-1.4-1.2-2.4c0-1.7,1.4-3,3-3s3,1.4,3,3C17,27,16.5,27.9,15.8,28.5z M7.3,10.3c0-3.7,3-6.7,6.7-6.7
       s6.7,3,6.7,6.7v5.5H7.3V10.3z"
        />
      </g>
    </svg>
  );
}
export default Lock;
