import CloseIcon from "../../../icons/Close";
import DragDropIcon from "../../../icons/DragDrop";
import { useEffect } from "react";
function CueSidebarItem(props) {
  const { page, drag, item, index } = props;
  const { error } = page;

  //ERROR
  useEffect(() => {
    error(`body.cueSidebar.orderItems.${index}.batchQuantity`, (reason) => {
      return parseInt(reason) === 0 || reason === "";
    });
    return () => {
      error(`body.cueSidebar.orderItems.${index}.batchQuantity`);
    };
  }, [error, index]);

  //FUNCTIONS
  const on_close = (target) => {
    const updateOrders = [...page.state.stores.orders].map((order) => {
      if (order._id === target.orderId) {
        return {
          ...order,
          orderItems: order.orderItems.map((item) => {
            if (item._id === target._id) {
              return {
                ...item,
                pending: 0,
                batch: item.batch.filter((each) => each._id !== "temp"),
              };
            }
            return item;
          }),
        };
      }
      return order;
    });

    let payload = [];
    page.state.body.cueSidebar.orderItems.forEach((item) => {
      if (item._id !== target._id) {
        payload = [...payload, item];
      }
    });

    page.set("stores.orders", updateOrders);
    page.set("body.cueSidebar.orderItems", payload);
  };

  const on_change_qty = (e, target, path) => {
    const updateOrders = [...page.state.stores.orders].map((order) => {
      if (order._id === target.orderId) {
        return {
          ...order,
          orderItems: order.orderItems.map((item) => {
            if (item._id === target._id) {
              return {
                ...item,
                batch: item.batch.map((b) => {
                  if (b._id === "temp") {
                    let num =
                      e.target.value === "" ? 0 : parseInt(e.target.value);
                    return { ...b, quantity: num };
                  }
                  return b;
                }),
              };
            }
            return item;
          }),
        };
      }
      return order;
    });

    page.set(path, e.target.value);
    page.set("stores.orders", updateOrders);
  };

  const on_enter = (e, index) => {
    if (e.key === "Enter") {
      page.toggle(`body.cueSidebar.orderItems.${index}.edit`);
    }
  };

  //COMPONENT
  return (
    <div
      className="bar split soft bordered drag-leave"
      data-id={item._id}
      data-group={"items"}
      draggable="true"
      onMouseUp={(e) => {
        drag.up(e);
      }}
      onMouseMove={(e) => {
        drag.move(e);
      }}
      onMouseDown={(e) => {
        drag.down(e);
      }}
      onDragStart={(e) => {
        drag.start(e);
      }}
      onDragEnd={(e) => {
        drag.end(e);
      }}
      onDragEnter={(e) => {
        drag.enter(e);
      }}
      onDragOver={(e) => {
        drag.over(e);
      }}
      onDragLeave={(e) => {
        drag.leave(e);
      }}
      onDrop={(e) => {
        drag.drop(e, "body.cueSidebar.orderItems");
      }}
    >
      <div className="bar split">
        <div className="pair">
          <button
            className="btn-icon"
            onClick={(e) => {
              on_close(item);
            }}
          >
            <CloseIcon />
          </button>
          <div>
            <div className="pair item">
              <b>Name: </b>
              <h3 className="item ellipsis">{item.name}</h3>
            </div>
            <div className="item">
              <b>Order: </b>
              {item.orderNumber}
            </div>
            <div className="item ellipsis">
              <b>Sku: </b>
              {item.sku}
            </div>
            <div className="item ellipsis">
              <b>Product: </b>
              {item.product}
            </div>
          </div>
        </div>
        <div className="item">
          {item.edit === true ? (
            <>
              <b>x</b>
              <input
                className="input-digits"
                type="number"
                value={item.batchQuantity}
                onChange={(e) => {
                  on_change_qty(
                    e,
                    item,
                    `body.cueSidebar.orderItems.${index}.batchQuantity`
                  );
                }}
                onKeyDown={(e) => {
                  on_enter(e, index);
                }}
                onBlur={(e) => {
                  page.toggle(`body.cueSidebar.orderItems.${index}.edit`);
                }}
              />
            </>
          ) : (
            <b
              data-id={item._id}
              className="click"
              onDoubleClick={(e) => {
                page.toggle(`body.cueSidebar.orderItems.${index}.edit`);
              }}
            >{`x${item.batchQuantity}`}</b>
          )}
        </div>
      </div>

      <div className="item right">
        <DragDropIcon />
      </div>
    </div>
  );
}

export default CueSidebarItem;
