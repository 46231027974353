class Barcode {
  constructor(data) {
    this.data = data;
  }

  THEARRAY = [];

  ascii_padding = 129;
  upper_shift = 235;

  interleaved_blocks = [
    1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 2, 2, 4, 4, 4, 4, 6, 6, 8, 10, 1,
    1, 1, 1, 1, 1,
  ];
  total_error_codewords = [
    5, 7, 10, 12, 14, 18, 20, 24, 28, 36, 42, 48, 56, 68, 84, 112, 144, 192,
    224, 272, 336, 408, 496, 620, 7, 11, 14, 18, 24, 28,
  ];
  total_data_codewords = [
    3, 5, 8, 12, 18, 22, 30, 36, 44, 62, 86, 114, 144, 174, 204, 280, 368, 456,
    576, 696, 816, 1050, 1304, 1558, 5, 10, 16, 22, 32, 49,
  ];

  matrix_size = {
    3: { 0: 8, 1: 8, 2: 2 },
    5: { 0: 10, 1: 10, 2: 2 },
    8: { 0: 12, 1: 12, 2: 2 },
    12: { 0: 14, 1: 14, 2: 2 },
    18: { 0: 16, 1: 16, 2: 2 },
    22: { 0: 18, 1: 18, 2: 2 },
    30: { 0: 20, 1: 20, 2: 2 },
    36: { 0: 22, 1: 22, 2: 2 },
    44: { 0: 24, 1: 24, 2: 2 },
    62: { 0: 28, 1: 14, 2: 4 },
    86: { 0: 32, 1: 16, 2: 4 },
    114: { 0: 36, 1: 18, 2: 4 },
    144: { 0: 40, 1: 20, 2: 4 },
    174: { 0: 40, 1: 22, 2: 4 },
    204: { 0: 48, 1: 24, 2: 4 },
    280: { 0: 56, 1: 14, 2: 16 },
    368: { 0: 64, 1: 16, 2: 16 },
    456: { 0: 72, 1: 18, 2: 16 },
    576: { 0: 80, 1: 20, 2: 16 },
    696: { 0: 88, 1: 22, 2: 16 },
    816: { 0: 96, 1: 24, 2: 16 },
    1050: { 0: 108, 1: 18, 2: 36 },
    1304: { 0: 120, 1: 20, 2: 36 },
    1558: { 0: 123, 1: 22, 2: 36 },
  };

  dot = 1;
  black(x, y) {
    return `<rect x="${x}" y="${y}" width="${this.dot}" height="${this.dot}" />`;
  }
  white(x, y) {
    return `<rect x="${x}" y="${y}" width="${this.dot}" height="${this.dot}" style="fill:rgb(255,255,255);"/>`;
  }

  reedSolomon_encoded() {
    let encoded_data = this.add_random();
    let code_and_pad = this.code_and_pad();
    let RSEC = this.ReedSolomonC(
      encoded_data,
      code_and_pad[0],
      code_and_pad[1],
      256,
      301
    );
    return RSEC;
  }

  structural_data() {
    let shape = [];
    let code = this.code_and_pad();
    let ncol = this.matrix_size[code[0]][0];
    let nrow = this.matrix_size[code[0]][0];
    let structure = this.matrix_size[code[0]][1];
    let region = this.matrix_size[code[0]][2];
    shape.push(ncol, nrow, structure, region);
    return shape;
  }

  encoded_data() {
    let char = this.character_array(this.data);
    let encoded_data = this.encode_value(char);
    return encoded_data;
  }
  code_and_pad() {
    let encoded_data = this.encoded_data();
    let padding = this.padding_check(
      encoded_data,
      this.total_data_codewords,
      this.total_error_codewords
    );
    return padding;
  }

  add_random() {
    let encoded_data = this.encoded_data();
    let code_and_pad = this.code_and_pad();
    if (code_and_pad.indexOf(2)) {
      if (code_and_pad[2] == 1) {
        encoded_data.push(this.ascii_padding);
      } else {
        encoded_data.push(this.ascii_padding);
        for (let i = 1; i < code_and_pad[2]; i++) {
          let random = this.randomize_253(129, encoded_data.length);
          encoded_data.push(random);
        }
      }
    }
    return encoded_data;
  }

  padding_check(data, index, error) {
    let code_and_pad = [];
    let padding;
    let count = data.length;
    for (let i = 0; i <= index.length; i++) {
      if (count == index[i]) {
        code_and_pad.push(index[i], error[i]);
        return code_and_pad;
      } else if (count < index[i]) {
        padding = index[i] - count;
        code_and_pad.push(index[i], error[i], padding);
        return code_and_pad;
      } else if (count > index[i] && count < index[i + 1]) {
        padding = index[i + 1] - count;
        code_and_pad.push(index[i + 1], error[i + 1], padding);
        return code_and_pad;
      }
    }
  }

  randomize_253(Pad_codeword_value, Pad_codeword_position) {
    let pseudo_random_number = ((149 * Pad_codeword_position) % 253) + 1;
    let temp_variable = Pad_codeword_value + pseudo_random_number;
    if (temp_variable <= 254) {
      return temp_variable;
    } else {
      return temp_variable - 254;
    }
  }

  encode_value(data) {
    let length = data.length;
    let encode = [];
    let i = 0;

    let codeword;
    while (i < length) {
      if (i + 1 < length) {
        var nxt = data[i + 1];
      } else {
        var nxt = "";
      }
      if (
        data[i].charCodeAt() >= 48 &&
        nxt.charCodeAt() >= 48 &&
        data[i].charCodeAt() <= 57 &&
        nxt.charCodeAt() <= 57
      ) {
        let temp = [data[i], nxt];
        let codeword = parseInt(temp.join("")) + 130;
        encode.push(codeword);
        i = i + 2;
      } else if (data[i].charCodeAt() >= 0 && data[i].charCodeAt() <= 127) {
        codeword = data[i].charCodeAt() + 1;
        encode.push(codeword);
        i = i + 1;
      } else if (data[i].charCodeAt() >= 128 && data[i].charCodeAt() <= 255) {
        encode.push(235);
        codeword = data[i].charCodeAt() - 128 + 1;
        encode.push(codeword);
        i = i + 1;
      }
    }
    return encode;
  }

  // Wants data as a "string"
  character_array(data) {
    //Need to find a functions that checks the data's encoding type
    let encode = [];
    for (let i = 0; i < data.length; i++) {
      let character = data.substring(i, i + 1);
      encode.push(character);
    }
    return encode;
  }

  bit_array(char) {
    let binary = (char >>> 0).toString(2);
    if (binary.length < 8) {
      binary = binary.padStart(8, 0); // if it breaks it's happening at this padding.
    }
    let bit = [];
    for (let i = 0; i <= 7; i++) {
      bit[i] = binary.substring(i, i + 1);
    }
    return bit;
  }

  unit(row, col, chr, bit) {
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    let reedSolomon_encoded = this.reedSolomon_encoded();

    if (row < 0) {
      row += nrow;
      col += 4 - ((nrow + 4) % 8);
    }
    if (col < 0) {
      col += ncol;
      row += 4 - ((ncol + 4) % 8);
    }
    let b = this.bit_array(reedSolomon_encoded[chr]);
    this.THEARRAY[row * ncol + col] = b[bit - 1];
    return this.THEARRAY;
  }

  utah(row, col, chr) {
    this.unit(row - 2, col - 2, chr, 1);
    this.unit(row - 2, col - 1, chr, 2);
    this.unit(row - 1, col - 2, chr, 3);
    this.unit(row - 1, col - 1, chr, 4);
    this.unit(row - 1, col, chr, 5);
    this.unit(row, col - 2, chr, 6);
    this.unit(row, col - 1, chr, 7);
    this.unit(row, col, chr, 8);
  }
  corner1(chr) {
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    this.unit(nrow - 1, 0, chr, 1);
    this.unit(nrow - 1, 1, chr, 2);
    this.unit(nrow - 1, 2, chr, 3);
    this.unit(0, ncol - 2, chr, 4);
    this.unit(0, ncol - 1, chr, 5);
    this.unit(1, ncol - 1, chr, 6);
    this.unit(2, ncol - 1, chr, 7);
    this.unit(3, ncol - 1, chr, 8);
  }
  corner2(chr) {
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    this.unit(nrow - 3, 0, chr, 1);
    this.unit(nrow - 2, 0, chr, 2);
    this.unit(nrow - 1, 0, chr, 3);
    this.unit(0, ncol - 4, chr, 4);
    this.unit(0, ncol - 3, chr, 5);
    this.unit(0, ncol - 2, chr, 6);
    this.unit(0, ncol - 1, chr, 7);
    this.unit(1, ncol - 1, chr, 8);
  }
  corner3(chr) {
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    this.unit(nrow - 3, 0, chr, 1);
    this.unit(nrow - 2, 0, chr, 2);
    this.unit(nrow - 1, 0, chr, 3);
    this.unit(0, ncol - 2, chr, 4);
    this.unit(0, ncol - 1, chr, 5);
    this.unit(1, ncol - 1, chr, 6);
    this.unit(2, ncol - 1, chr, 7);
    this.unit(3, ncol - 1, chr, 8);
  }
  corner4(chr) {
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    this.unit(nrow - 1, 0, chr, 1);
    this.unit(nrow - 1, ncol - 1, chr, 2);
    this.unit(0, ncol - 3, chr, 3);
    this.unit(0, ncol - 2, chr, 4);
    this.unit(0, ncol - 1, chr, 5);
    this.unit(1, ncol - 3, chr, 6);
    this.unit(1, ncol - 2, chr, 7);
    this.unit(1, ncol - 1, chr, 8);
  }

  ECC200() {
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    for (let row = 0; row < shape[1]; row++) {
      for (let col = 0; col < shape[0]; col++) {
        this.THEARRAY[row * shape[0] + col] = 2;
      }
    }
    /* Starting in the correct location for character #1, bit 8,... */
    let chr = 0;
    let row = 4;
    let col = 0;
    do {
      /* repeatedly first check for one of the special corner cases, then... */
      if (row == nrow && col == 0) this.corner1(chr++);
      if (row == nrow - 2 && col == 0 && ncol % 4) this.corner2(chr++);
      if (row == nrow - 2 && col == 0 && ncol % 8 == 4) this.corner3(chr++);
      if (row == nrow + 4 && col == 2 && !(ncol % 8)) this.corner4(chr++);
      /* sweep upward diagonally, inserting successive characters,... */
      do {
        if (row < nrow && col >= 0 && this.THEARRAY[row * ncol + col] == 2)
          this.utah(row, col, chr++);
        row -= 2;
        col += 2;
      } while (row >= 0 && col < ncol);
      row += 1;
      col += 3;
      /* & then sweep downward diagonally, inserting successive characters,... */

      do {
        if (row >= 0 && col < ncol && this.THEARRAY[row * ncol + col] == 2)
          this.utah(row, col, chr++);
        row += 2;
        col -= 2;
      } while (row < nrow && col >= 0);
      row += 3;
      col += 1;
      /* ... until the entire array is scanned */
    } while (row < nrow || col < ncol);
    /* Lastly, if the lower righthand corner is untouched, fill in fixed pattern */
    if (this.THEARRAY[nrow * ncol - 1] == 2) {
      this.THEARRAY[nrow * ncol - 2] = this.THEARRAY[
        nrow * ncol - ncol - 1
      ] = 0;
      this.THEARRAY[nrow * ncol - 1] = this.THEARRAY[
        nrow * ncol - ncol - 2
      ] = 1;
    }
    return this.THEARRAY;
  }

  prod(x, y, log, alog, gf) {
    if (x == 0 || y == 0) {
      return 0;
    } else {
      return alog[(log[x] + log[y]) % (gf - 1)];
    }
  }
  // data= wd, nd = number of data words,  nc = number of error words, gf = 256 which is 2^8, pp = 301 the Prime Modulus
  // but I have no idea why. The encoded value is just the data polynomial times the prime Modulus divided by the
  // generator polynomial which is represented in the log table
  ReedSolomonC(wd, nd, nc, gf, pp) {
    let i;
    let j;
    let k;
    let log = [];
    let alog = [];
    let c = [];

    log[0] = 1 - gf;
    alog[0] = 1;
    for (i = 1; i < gf; i++) {
      alog[i] = alog[i - 1] * 2;
      if (alog[i] >= gf) {
        alog[i] ^= pp;
      }
      log[alog[i]] = i;
    }

    for (i = 1; i <= nc; i++) {
      c[i] = 0;
      c[0] = 1;
    }
    for (i = 1; i <= nc; i++) {
      c[i] = c[i - 1];
      for (j = i - 1; j >= 1; j--) {
        c[j] = c[j - 1] ^ this.prod(c[j], alog[i], log, alog, gf);
      }
      c[0] = this.prod(c[0], alog[i], log, alog, gf);
    }
    for (i = nd; i <= nd + nc; i++) {
      wd[i] = 0;
    }
    for (i = 0; i < nd; i++) {
      k = wd[nd] ^ wd[i];
      for (j = 0; j < nc; j++) {
        wd[nd + j] =
          wd[nd + j + 1] ^ this.prod(k, c[nc - j - 1], log, alog, gf);
      }
    }
    let pop = wd.pop();
    return wd;
  }
  // React Fix

  drawRect(x, y, color) {
    return {
      x: x,
      y: y,
      height: 1,
      width: 1,
      color: this.colorCheck(color),
    };
  }

  colorCheck(string) {
    if (string === "white") {
      return "rgb(255, 255, 255)";
    } else if (string === "black") return "rgb(0, 0, 0)";
  }

  // draws an SVG barcode based on ECC200 output
  draw() {
    let finalArray = this.ECC200();
    let shape = this.structural_data();
    let ncol = shape[0];
    let nrow = shape[1];
    let structure = shape[2];
    let region = shape[3];
    let scale = (ncol + region) * 10;
    let box = ncol + region;

    const svg = {
      rect: [],
      height: scale,
      width: scale,
      viewBox: [0, 0, box, box],
      number: scale,
      numberFactor: scale * 10,
      numberFactorDivisor: (scale * 10) / 2,
    };

    let r = 0;
    let c = 0;
    let i = 0;
    let x = 0;
    let y = 0;

    do {
      //Checks Region and draws solid boarder
      if (r == structure) {
        for (let s = 0; s < ncol + region; s++) {
          svg.rect.push(this.drawRect(s, y, "black"));
        }
        y++;
      }
      //Checks region and draws dashed boarder
      if (0 == r % structure) {
        for (let z = 0; z < ncol + region; z++) {
          if (1 == z % 2) {
            svg.rect.push(this.drawRect(z, y, "white"));
          } else {
            svg.rect.push(this.drawRect(z, y, "black"));
          }
        }
        y++;
      }
      do {
        //Checks and inserts 'white' bits and checks boarder conditions
        if (finalArray[i] == 0) {
          if (c == structure) {
            if (1 == r % 2 && r !== 0) {
              svg.rect.push(this.drawRect(x, y, "white"));
            } else {
              svg.rect.push(this.drawRect(x, y, "black"));
            }
            x++;
          }
          if (0 == i % structure) {
            svg.rect.push(this.drawRect(x, y, "black"));

            x++;
          }
          svg.rect.push(this.drawRect(x, y, "white"));
        }
        //Checks and inserts 'black' bits and checks boarder conditions
        if (finalArray[i] == 1) {
          if (c == structure) {
            if (1 == r % 2 && r !== 0) {
              svg.rect.push(this.drawRect(x, y, "white"));
            } else {
              svg.rect.push(this.drawRect(x, y, "black"));
            }
            x++;
          }
          if (0 == i % structure) {
            svg.rect.push(this.drawRect(x, y, "black"));

            x++;
          }
          svg.rect.push(this.drawRect(x, y, "black"));
        }
        //Checks for final column and draws dashed boarder
        if (c == ncol - 1 && 1 == r % 2) {
          x++;
          svg.rect.push(this.drawRect(x, y, "white"));
        } else if (c == ncol - 1) {
          x++;
          svg.rect.push(this.drawRect(x, y, "black"));
        }
        c++;
        i++;
        x++;
      } while (c < ncol);
      c = 0;
      r++;
      x = 0;
      y++;
    } while (r < nrow);
    //Sets final solid boarder on bottom
    for (let i = 0; i < ncol + region; i++) {
      svg.rect.push(this.drawRect(i, y, "black"));
    }

    return svg;
  }
}

export default Barcode;
