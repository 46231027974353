import { useEffect, useMemo } from "react";
import usePage from "../../hooks/usePage";
import useServer from "../../hooks/useServer";

function useInventory() {
  const loadState = {
    inventory: "idle",
  };

  const unitTypes = useMemo(() => {
    return {
      linear: ["in", "ft", "yd", "mi", "mm", "cm", "m", "km"],
      weight: ["oz", "lb", "mg", "g", "kg", "t"],
      quantity: [
        "ea",
        "doz",
        "dz",
        "ct",
        "bdl",
        "pk",
        "pt",
        "qt",
        "gal",
        "ml",
        "l",
        "hl",
      ],
      area: ["in2", "ft2", "yd2", "mi2", "mm2", "cm2", "m2", "km2"],
    };
  }, []);

  const initialState = {
    modal: {
      open: false,
      mode: "new",
      _id: "",
      name: "",
      nickName: "",
      description: "",
      amount: 0,
      type: {
        options: ["Linear", "Weight", "Quantity", "Area"],
        selected: "Linear",
      },
      units: { options: unitTypes.linear, selected: "in" },
      cost: 0,
      supplier: "",
      website: "",
    },
    menu: {
      search: "",
    },
    body: {
      type: "items",
      changes: [],
    },
    stores: {
      customers: [],
      materials: [],
    },
  };

  const signalState = {};

  const page = usePage(initialState, loadState, signalState);
  //destructured for linter
  const { set, load, init } = page;
  const { get } = useServer();

  //update units when type changes
  useEffect(() => {
    async function effect() {
      if (page.state.modal.type.selected === "Linear") {
        set("modal.units.options", unitTypes.linear);
        set("modal.units.selected", "in");
      }
      if (page.state.modal.type.selected === "Weight") {
        set("modal.units.options", unitTypes.weight);
        set("modal.units.selected", "oz");
      }
      if (page.state.modal.type.selected === "Quantity") {
        set("modal.units.options", unitTypes.quantity);
        set("modal.units.selected", "ea");
      }
      if (page.state.modal.type.selected === "Area") {
        set("modal.units.options", unitTypes.area);
        set("modal.units.selected", "in2");
      }
    }
    effect();
  }, [page.state.modal.type.selected, set, unitTypes]);

  //LOAD CUSTOMER PRODUCTS
  useEffect(() => {
    async function effect() {
      load("stores.customers", {
        reload: async () => {
          return get("/products/from/customers");
        },
        callback: (data) => {
          data.forEach((customer) => {
            customer.products.forEach((product) => {
              product.show = false;
              product.skus.forEach((sku) => {
                sku.show = false;
                sku.amount = sku.quantity;
              });
            });
          });
          init("stores.customers", data);
        },
      });
    }
    effect();
  }, [load, init, get]);

  //LOAD MATERIALS
  useEffect(() => {
    async function effect() {
      load("stores.materials", {
        reload: async () => {
          return get("/materials");
        },
        callback: (data) => {
          init("stores.materials", data);
        },
      });
    }
    effect();
  }, [load, get, init]);

  return page;
}

export default useInventory;
