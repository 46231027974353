function format_new_customer_payload(state) {
  let clone = { ...state };
  const company = clone.general.company;
  const firstName = clone.general.firstName;
  const lastName = clone.general.lastName;
  const website = clone.general.website;
  const email = clone.general.email;
  const phone = clone.general.phone;
  const shippingAddress = clone.shippingAddress;
  const billingAddress = clone.billingAddress;
  const provider = clone.provider;
  const profileName = clone.profileName;

  return {
    process: [],
    archived: [],
    products: [],
    orders: [],
    invoices: [],
    provider: provider,
    profileName: profileName,
    addressBook: [
      {
        company: company,
        firstName: firstName,
        lastName: lastName,
        website: website,
        email: email,
        phone: phone,
        shippingAddress: {
          address1: shippingAddress.address1,
          address2: shippingAddress.address2,
          address3: shippingAddress.address3,
          city: shippingAddress.city,
          clone: shippingAddress.state,
          country: shippingAddress.country,
          postalCode: shippingAddress.postalCode,
        },
        billingAddress: {
          address1: billingAddress.address1,
          address2: billingAddress.address2,
          address3: billingAddress.address3,
          city: billingAddress.city,
          clone: billingAddress.state,
          country: billingAddress.country,
          postalCode: billingAddress.postalCode,
        },
      },
    ],
  };
}
export default format_new_customer_payload;
