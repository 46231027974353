import useServer from "../../hooks/useServer";
import trunc_str from "../../utils/trunc_str";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";

function EmployeeTable(props) {
  const { page } = props;
  const employees = page.state.stores.employees;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  //FUNCTIONS
  const on_edit = (employee) => {
    page.set("modal.open", true);
    page.set("modal._id", employee._id);
    page.set("modal.firstName", employee.firstName);
    page.set("modal.lastName", employee.lastName);
    page.set("modal.email", employee.email);
    page.set("modal.phone", employee.phone);
    page.set("modal.pay", employee.pay);
    page.set("modal.role", employee.role);
    page.set("modal.jobs", employee.jobs);
  };

  const add_job = (employee) => {
    setAlert({ msg: "", type: "clear" });
    page.set("modal2.open", true);
    page.set("modal2._id", employee._id);
    page.set("modal2.firstName", employee.firstName);
    page.set("modal2.lastName", employee.lastName);
  };

  const remove_job = async (jobId, empId) => {
    const data = await post("/employees/remove/job", {
      jobId: jobId,
      empId: empId,
    });
    if (data) {
      setAlert({ msg: data.msg, type: data.type });
      page.reload("stores.employees");
    }
  };

  //COMPONENT
  return (
    <div className="card shadow soft">
      {employees.map((employee, index) => {
        return (
          <div className="card list titleline" key={employee._id}>
            <div className="grid-bar">
              <div className="span-3">
                {`${employee.firstName} ${employee.lastName}`}
              </div>
              <div className="span-3 item">
                <button
                  className="btn-link"
                  onClick={() => {
                    add_job(employee);
                  }}
                >
                  Add Job
                </button>
              </div>
              <div className="span-2">
                <button
                  className="btn-link"
                  onClick={() => {
                    page.toggle(`stores.employees.${index}.info`);
                  }}
                >
                  {employee.info === true ? "Hide" : "Show Details"}
                </button>
              </div>
            </div>
            {employee.info === true ? (
              <>
                <div className="grid-bar">
                  <div className="span-1">
                    <button
                      className="btn-link"
                      onClick={() => {
                        on_edit(employee);
                      }}
                    >
                      Edit
                    </button>
                  </div>
                  <div className="span-1 pair">
                    <div className="input-label third">Role:</div>
                    <div className="">
                      {employee.role.charAt(0).toUpperCase() +
                        employee.role.slice(1)}
                    </div>
                  </div>
                  <div className="span-3 pair item">
                    <div className="input-label ">Email</div>
                    <div className="">{employee.email}</div>
                  </div>
                  <div className="span-1 pair item">
                    <div className="input-label ">Phone</div>
                    <div className="">{employee.phone}</div>
                  </div>
                  <div className="span-1 pair item">
                    <div className="input-label ">Pay</div>
                    <div className="">{`$${employee.pay}`}</div>
                  </div>
                </div>
                <div className="note column">
                  <div className="grid-bar titleline">
                    <h4 className="span-12">Assigned Jobs</h4>
                  </div>
                  <div className="grid-bar">
                    <div className="span-2 input-label ">Batch</div>
                    <div className="span-2 input-label ">Products</div>
                    <div className="span-2 input-label ">Sku</div>
                    <div className="span-2 input-label ">Step</div>
                    <div className="span-1 input-label ">Done</div>
                    <div className="span-1 input-label ">Need</div>
                    <div className="span-2 input-label ">Unassign</div>
                  </div>
                  {employee.jobs?.map((job) => {
                    return (
                      <div className="grid-bar" key={job._id}>
                        <div className="span-2">{job.batch}</div>
                        <div className="span-2">{job.product}</div>
                        <div className="span-2">{job.sku}</div>
                        <div className="span-2">{trunc_str(job.step)}</div>
                        <div className="span-1">{job.count}</div>
                        <div className="span-1">{job.quantity}</div>
                        <div className="span-2">
                          <button
                            className="btn-link"
                            onClick={async () => {
                              remove_job(job._id, employee._id);
                            }}
                          >
                            Remove
                          </button>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        );
      })}
    </div>
  );
}

export default EmployeeTable;
