import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import SearchBar from "../../components/SearchBar";

function Menu(props) {
  const { page } = props;
  const { setAlert } = useGlobalAlert();

  //COMPONENT
  return (
    <div className="card soft shadow">
      <h1>Inventory</h1>
      <div className="grid-bar">
        <div className="item span-2">
          <button
            className="btn-default "
            onClick={() => {
              setAlert({ type: "none", msg: "" });
              if (page.state.body.type === "items") {
                page.set("body.type", "materials");
              } else {
                page.set("body.type", "items");
              }
            }}
          >
            {page.state.body.type === "items" ? "Material View" : "Item View"}
          </button>
        </div>
        <SearchBar
          value={page.state.body.search}
          change={(e) => {
            e.preventDefault();
            page.set("body.search", e.target.value);
          }}
        />
      </div>
    </div>
  );
}
export default Menu;
