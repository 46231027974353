function generate_lookup_table(object, defaultValue, path) {
  const table = {};
  breadthFirst(object, path, table, defaultValue);
  return table;
}

function push_table(path, table, defaultValue) {
  table[path] = defaultValue;
}

function breadthFirst(object, path = "", table, defaultValue) {
  const queue = [{ object: object, path: path, index: 0 }];

  while (queue.length > 0) {
    const { object, path } = queue.shift();

    if (typeof object === "object" && object !== null) {
      if (path !== "") {
        push_table(path, table, defaultValue);
      }

      const keys = Object.keys(object);

      keys.forEach((key) => {
        const currentPath = path !== "" ? `${path}.${key}` : key;
        if (typeof object[key] === "object" && !Array.isArray(object[key])) {
          queue.push({ object: object[key], path: currentPath });
        } else if (Array.isArray(object[key])) {
          push_table(currentPath, table, defaultValue);

          object[key].forEach((item, index) => {
            queue.push({
              object: item,
              path: `${currentPath}.${index}`,
            });
          });
        } else {
          push_table(currentPath, table, defaultValue);
        }
      });
    }
  }

  return object;
}

export default generate_lookup_table;
