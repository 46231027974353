import { useEffect, useState, useRef, useLayoutEffect } from "react";
import Error from "../icons/Error";

function ErrorBox(props) {
  const { children, isError, errorMsg, errorFunc = () => {} } = props;
  const [error, setError] = useState(false);
  const change = useRef(isError);
  const touch = useRef(false);
  const computedWidth = useRef(0);
  const bubble = useRef(null);

  useEffect(() => {
    if (change.current !== isError) touch.current = true;
  }, [isError]);

  useEffect(() => {
    if (isError === true && touch.current === true) {
      setError(true);
    } else {
      setError(false);
    }
  }, [isError]);

  useEffect(() => {
    errorFunc(true);
    return () => {
      errorFunc(false);
    };
  }, []);

  useLayoutEffect(() => {
    computedWidth.current = bubble.current.offsetWidth;
  }, [error, errorMsg]);

  const box = {
    border: error === true ? "1px solid var(--error)" : "none",
    borderRadius: "var(--border-radius)",
  };

  return (
    <div className="error-box">
      <div
        className="error-icon"
        style={error === true ? { display: "block" } : { display: "none" }}
        onClick={() => {
          bubble.current.style.visibility === "hidden"
            ? (bubble.current.style.visibility = "visible")
            : (bubble.current.style.visibility = "hidden");
        }}
      >
        <Error fill="var(--error)" />
      </div>
      <div
        ref={bubble}
        className="error-bubble"
        style={{ right: `-${computedWidth.current}px` }}
      >
        {errorMsg}
      </div>
      <div style={box}>{children}</div>
    </div>
  );
}
export default ErrorBox;
