import date_trim from "../../utils/date_trim";
import useServer from "../../hooks/useServer";

function BatchList(props) {
  const { page } = props;
  const { post } = useServer();

  const handleOnSelect = async (batch) => {
    const batchId = batch._id;
    const name = batch.batchName;

    let data = await post("/find/batch/items", { batchId: batchId });
    if (data) {
      page.set("detailedView", {
        orders: [
          ...data.map((order) => {
            return {
              ...order,
              orderItems: order.orderItems.map((orderItem) => {
                return {
                  ...orderItem,
                  show: false,
                };
              }),
            };
          }),
        ],
        batchId: batchId,
        batchName: name,
        searched: false,
        update: false,
        group: "",
        station: "",
        redirect: false,
      });
    }
  };

  const get_item_totals = (orderItems) => {
    return orderItems.reduce((acc, item) => acc + item.quantity, 0);
  };

  return (
    <div className="table-boss shadow soft one">
      <div className="bar split">
        <h1 className="list">Batches</h1>
      </div>
      <div className="table-container">
        <h4 style={{ textAlign: "center", color: "var(--warning)" }}>
          {page.state.batchList.alert}
        </h4>
        {page.state.stores.batches?.map((batch) => {
          return (
            <div
              key={batch._id}
              className="bar split soft bordered drag-leave click"
              onClick={(e) => {
                handleOnSelect(batch);
              }}
            >
              <div>
                <h2 className="item">{batch.batchName}</h2>
                <p className="item">{date_trim(batch.batchDate)}</p>
              </div>
              <b>x{get_item_totals(batch.orderItems)}</b>
            </div>
          );
        })}
      </div>
    </div>
  );
}

export default BatchList;
