function BatchDetailsMenu(props) {
  const { page } = props;

  //FUNCTIONS
  const on_print_all = (orders) => {
    let barcodes = [];
    orders.forEach((order) => {
      order.orderItems.forEach((orderItem) => {
        orderItem.items.forEach((item) => {
          item.parts.forEach((part, index) => {
            let clone = { ...item };
            clone.orderNumber = order.orderNumber;
            clone.partName = part.name;
            clone.partNumber = index + 1;
            clone.totalParts = item.parts.length;
            barcodes = [...barcodes, clone];
          });
        });
      });
    });

    page.set("modal", {
      open: true,
      barcodes: barcodes,
    });
  };

  //COMPONENT
  return (
    <div className="bar split">
      <h1 className="list">Batch Details</h1>
      <div className="pair">
        <button
          className="btn-default item"
          onClick={(e) => {
            on_print_all(page.state.detailedView.orders);
          }}
          disabled={page.state.detailedView.orders.length === 0}
        >
          Print All Orders In Batch
        </button>
      </div>
    </div>
  );
}
export default BatchDetailsMenu;
