import CloseIcon from "../../../icons/Close";

function OrderCueTable(props) {
  const { page } = props;

  const cueTable = page.state.stores?.orders.find(
    (order) => order._id === page.state.body.cueTable._id
  );

  function on_dbl_click(targetItem) {
    const addItem = cueTable.orderItems.find(
      (item) => item._id === targetItem._id
    );
    if (
      !page.state.body.cueSidebar.orderItems.find(
        (item) => item._id === targetItem._id
      )
    ) {
      addItem.orderId = cueTable._id;
      addItem.orderNumber = cueTable.orderNumber;
      addItem.customerId = cueTable.customerId;
      addItem.edit = false;
      addItem.createDate = new Date().toISOString();
      addItem.batchQuantity = addItem.quantity;
      const orderUpdate = [...cueTable.orderItems].map((item) => {
        if (item._id === targetItem._id) {
          return {
            ...item,
            batch: [
              ...item.batch,
              {
                batchName: "Batching...",
                _id: "temp",
                quantity: item.quantity,
              },
            ],
          };
        }
        return item;
      });
      const update = [...page.state.stores.orders].map((order) => {
        if (order._id === cueTable._id) {
          return { ...order, orderItems: orderUpdate };
        }
        return order;
      });
      page.set("stores.orders", update);
      page.add("body.cueSidebar.orderItems", addItem);
    }
  }

  function on_add_all() {
    const orderItems = cueTable.orderItems;
    const addItems = orderItems.filter((item) => {
      return !page.state.body.cueSidebar.orderItems.find(
        (add) => add._id === item._id
      );
    });
    addItems.forEach((item) => {
      item.orderId = cueTable._id;
      item.orderNumber = cueTable.orderNumber;
      item.customerId = cueTable.customerId;
      item.edit = false;
      item.createDate = new Date().toISOString();
      item.batchQuantity = item.quantity;
      const orderUpdate = [...cueTable.orderItems].map((orderItem) => {
        return {
          ...orderItem,
          batch: [
            ...orderItem.batch,
            {
              batchName: "Batching...",
              _id: "temp",
              quantity: orderItem.quantity,
            },
          ],
        };
      });
      const update = [...page.state.stores.orders].map((order) => {
        if (order._id === cueTable._id) {
          return { ...order, orderItems: orderUpdate };
        }
        return order;
      });
      page.set("stores.orders", update);
      page.add("body.cueSidebar.orderItems", item);
    });
  }

  const setQtyColor = (item) => {
    if (set_item_totals(item) === item.quantity)
      return { color: "var(--primary)" };
    if (set_item_totals(item) > item.quantity)
      return { color: "var(--warning)" };
    return {};
  };

  const get_batch_totals = (batch) => {
    let totals = 0;
    batch.forEach((b) => {
      totals += b.quantity;
    });
    return totals;
  };

  const set_item_totals = (item) => {
    const allocate = item.allocate;
    const batched = get_batch_totals(item.batch);
    return batched + allocate;
  };

  //COMPONENT
  return (
    <div id="cue-1" className="table-cue">
      <div className="table-container">
        <div className="column shadow header soft">
          <div className="bar split">
            <button
              className="btn-icon"
              onClick={() => {
                page.reset("body.cueTable");
              }}
            >
              <CloseIcon />
            </button>
            <button
              className="btn-default right"
              onClick={() => {
                on_add_all();
              }}
            >
              Add All
            </button>
          </div>
          <div className="pair">
            <h5>Order -</h5>
            <h3 className="item">{cueTable.orderNumber}</h3>
          </div>
          <div className="pair">
            <h5>Customer -</h5>
            <h3 className="item">{cueTable.customer.company}</h3>
          </div>
        </div>
        {cueTable.orderItems?.map((item) => {
          return (
            <div
              className="bar split soft bordered drag-leave batch-item"
              data-id={item._id}
              onDoubleClick={() => {
                on_dbl_click(item);
              }}
              key={`${item._id}`}
              style={
                !item.product ? { border: "solid var(--warning) 1px" } : null
              }
            >
              <div>
                {/* <div className="item">
                  <b>Order: </b>
                  {item.orderNumber}
                </div> */}
                <div className="item ellipsis">
                  <b>Name: </b>
                  {item.name}
                </div>
                <div className="item ellipsis">
                  <b>Sku: </b>
                  {item.sku}
                </div>
                <div
                  className="item ellipsis"
                  style={!item.product ? { color: "var(--warning)" } : null}
                >
                  <b>Product: </b>
                  {!item.product ? "N/A" : item.product}
                </div>
              </div>
              <div className="pair item ellipsis">
                <h6
                  className="list"
                  style={setQtyColor(item)}
                >{`${set_item_totals(item)}/`}</h6>
                <h3 className="list" style={setQtyColor(item)}>
                  {item.quantity}
                </h3>
              </div>
            </div>
          );
        })}
      </div>
      <div className="table-container middled">
        <div className="column center">Product Preview</div>
      </div>
    </div>
  );
}
export default OrderCueTable;
