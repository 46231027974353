import useAuth from "../../context/User/useAuth";
import HeaderAdmin from "./HeaderAdmin";
import HeaderShipping from "./HeaderShipping";

function Header({ allowedRoles }) {
  const { auth } = useAuth();

  return allowedRoles.includes(auth?.role) ? (
    <>
      <HeaderAdmin />
    </>
  ) : (
    <>
      <HeaderShipping />
    </>
  );
}

export default Header;
