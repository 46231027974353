import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import CustomerLine from "./CustomerLine";
function Items(props) {
  const { page } = props;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  //FUNCTIONS
  const saveChanges = async () => {
    const payload = page.state.body.changes;
    const response = await post("/inventory/update", payload);
    if (response.type === "success") {
      setAlert({ type: "success", msg: "Changes Saved" });
      page.set("body.changes", []);
      page.reload("stores.customers");
    }
  };

  //COMPONENT
  return (
    <div className="card ">
      <button
        className="btn-primary"
        onClick={async (e) => {
          e.preventDefault();
          saveChanges();
        }}
      >
        Save Changes
      </button>
      {page.state.stores.customers.map((customer, index) => {
        const color = index % 2 ? "note" : "";
        return (
          <CustomerLine
            key={customer._id}
            page={page}
            customer={customer}
            color={color}
            index={index}
          />
        );
      })}
    </div>
  );
}
export default Items;
