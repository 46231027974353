import DropDown from "../../components/DropDown";
import TextInput from "../../components/TextInput";
import useServer from "../../hooks/useServer";
function NewUser(props) {
  const { page } = props;
  const { post } = useServer();

  const modal = page.state.modal;

  const map_roles = (role) => {
    return role === 1776 ? "Admin" : "User";
  };

  const on_create = async () => {
    const payload = {
      name: modal.name,
      email: modal.email,
      password: modal.password,
      confirmPassword: modal.confirmPassword,
      role: modal.role,
    };
    const data = await post("/admin/user/register", payload);
    if (data.type === "error") {
      page.set("modal.alert", { type: "error", msg: data.msg });
    } else {
      page.set("modal.alert", { type: "success", msg: data.msg });
      page.reset("modal.name");
      page.reset("modal.email");
      page.set("modal.password", "");
      page.set("modal.confirmPassword", "");
      page.reload("stores.users");
    }
  };

  return (
    <>
      <div className="grid-bar">
        <div className="input-label single s-3 right"> Role</div>
        <div className="span-3">
          <DropDown title={map_roles(modal.role)} value={modal.role}>
            <div
              className="dropdown-option"
              onClick={() => {
                page.set("modal.role", 2001);
              }}
            >
              User
            </div>
            <div
              className="dropdown-option"
              onClick={() => {
                page.set("modal.role", 1776);
              }}
            >
              Admin
            </div>
          </DropDown>
        </div>
        <div className="input-label single s-3 right">Name:</div>
        <TextInput
          value={modal.name}
          onChange={(e) => {
            page.set("modal.name", e.target.value);
          }}
          css={"span-4"}
        ></TextInput>

        <div className="input-label single s-3 right">Email:</div>
        <TextInput
          value={modal.email}
          onChange={(e) => {
            page.set("modal.email", e.target.value);
          }}
          css="span-4"
        ></TextInput>
        <div className="input-label single s-3 right">Password:</div>
        <input
          className="form-input span-4 pB"
          type="password"
          onChange={(e) => {
            page.set("modal.password", e.target.value);
          }}
        />
        <div className="input-label single s-3 right">Confirm Password:</div>
        <input
          className="form-input span-4 pB"
          type="password"
          onChange={(e) => {
            page.set("modal.confirmPassword", e.target.value);
          }}
        />
      </div>

      <button
        className="btn-primary"
        onClick={() => {
          on_create();
        }}
      >
        Create
      </button>
    </>
  );
}
export default NewUser;
