import MaterialLine from "./MaterialLine";

const search_filter = (search, data) => {
  const filter = new RegExp(search, "i");
  return filter.test(data);
};

function Materials(props) {
  const { page } = props;
  return (
    <div className="card ">
      <button
        className="btn-primary"
        onClick={() => {
          page.set("modal.open", true);
        }}
      >
        New Material
      </button>

      <div className="grid-bar titleline">
        <b className="span-3">Material</b>
        <b className="span-2">Quantity</b>
        <b className="span-2">Price</b>
        <b className="span-2">Supplier</b>
        <b className="span-2">Link</b>
      </div>
      {page.state.stores.materials.map((material, index) => {
        const color = index % 2 ? "note" : "";
        return search_filter(page.state.body.search, material.name) ? (
          <MaterialLine
            key={material._id}
            page={page}
            material={material}
            color={color}
            index={index}
          />
        ) : (
          <></>
        );
      })}
    </div>
  );
}
export default Materials;
