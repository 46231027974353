import DropDown from "../../../components/DropDown";
import ShipstationStores from "./ShipstationStores";
function Provider(props) {
  const { page } = props;
  let string = page.state.modal.provider.source;
  const source = string.charAt(0).toUpperCase() + string.slice(1);

  const on_change = (source) => {
    page.set("modal.provider.source", source);
    if (source === "generic") {
      page.set("modal.provider.data", {
        name: source,
        data: {},
      });
      return;
    } else if (source === "shipstation") {
      page.set("modal.provider.data", {
        ...page.state.modal.provider.data,
        name: source,
      });
      if (page.isLoading["stores.shipstation"] === false) {
        page.set("modal.provider.data", {
          ...page.state.modal.provider.data,
          shipstationStoreIds: [
            {
              storeId: page.state.stores.shipstation[0].storeId,
              storeName: page.state.stores.shipstation[0].storeName,
            },
          ],
        });
      }
    }
  };
  return (
    <>
      <h3 className="titleline list-item">Provider</h3>
      <div className="card-two-thirds">
        <h6 className="column list">
          Choose if the customer orders will link from an outside source
        </h6>
        <div className="grid-bar">
          <label className="input-label third right">Source</label>
          <DropDown title={source}>
            <div
              className="dropdown-option"
              onClick={() => {
                on_change("generic");
              }}
            >
              Generic
            </div>
            <div
              className="dropdown-option"
              onClick={() => {
                on_change("shipstation");
              }}
            >
              Shipstation
            </div>
          </DropDown>
        </div>
        {page.state.modal.provider.source === "shipstation" ? (
          page.isLoading["stores.shipstation"] ? (
            <>Loading...</>
          ) : (
            <ShipstationStores page={page} />
          )
        ) : (
          <> </>
        )}
      </div>
    </>
  );
}
export default Provider;
