const get_sku_totals = (selected) => {
  const employees = [];
  selected.steps.forEach((step) => {
    if (step.highlight === true) {
      step.skus.forEach((sku) => {
        if (sku._id === selected.skuId) {
          sku.employees.forEach((emp) => {
            emp = {
              ...emp,
              _id: emp.empId,
              name: emp.empFirstName + " " + emp.empLastName,
              totals: { avg: 0, mode: 0, cost: 0, count: 0 },
            };
            emp.totals.avg = emp.emp_avg;
            emp.totals.mode = emp.emp_mode;
            emp.totals.cost = emp.avg_cost;
            emp.totals.count = emp.total;
            employees.push(emp);
          });
        }
      });
      return step;
    }
  });

  return employees;
};

export default get_sku_totals;
