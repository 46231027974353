function OrderTableRow(props) {
  const { tableState, rows, page, setAlert } = props;

  const width = (key) => {
    if (key === null || key === undefined) {
      return 100;
    }
    const header = tableState.columns.find((col) => col.key === key);
    return header.width;
  };

  return (
    <>
      {rows?.map((row, index) => {
        return (
          <div className={`table-row ${row.rowColor}`} key={index}>
            {row.cells?.map((cell, i) => {
              return (
                <div
                  className={`table-item ${cell.css}`}
                  style={{ width: width(tableState?.columns[i]?.key) }}
                  key={i}
                >
                  <div
                    onClick={(e) => {
                      e.preventDefault();
                      cell.action(page);
                      setAlert({ type: "", message: "" });
                    }}
                  >
                    {cell.data}
                  </div>
                </div>
              );
            })}
          </div>
        );
      })}
    </>
  );
}

export default OrderTableRow;
