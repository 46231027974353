import DropDown from "../../components/DropDown";
import useServer from "../../hooks/useServer";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
function CustomerMenu(props) {
  const { page } = props;
  const { get, post } = useServer();
  const { setAlert } = useGlobalAlert();

  const customers = page.state.stores.customers;
  const selected =
    customers?.find((customer) => customer._id === page.state.menu.selected)
      ?.profileName ?? "";

  const handleOnNew = async () => {
    page.set("modal.open", true);
    page.load("stores.shipstation", {
      reload: async () => {
        return get("shipstation/stores");
      },
      callback: (data) => {
        page.init("stores.shipstation", data);
      },
    });
  };

  const handleOnLoad = async (id) => {
    let find = page.state.stores.customers.find(
      (customer) => customer._id === id
    );
    const customer = {
      ...find,
      addressBook: find.addressBook.map((address) => {
        return {
          ...address,
          customerId: id,
          lock: true,
          shippingAddress: { ...address.shippingAddress, lock: true },
          billingAddress: { ...address.billingAddress, lock: true },
        };
      }),
    };

    page.set("body.mode", "idle");
    page.reset("body.selectedProcess");
    page.reset("body.selectedProduct");
    page.set("body.selectedAddress", customer.addressBook[0]);
    page.set("body.addressBook", customer.addressBook);
    page.load("stores.process", {
      reload: async () => {
        return post("/customer/processes", id);
      },
      callback: (data) => {
        page.init("stores.process", data.data);
        page.load("stores.products", {
          reload: async () => {
            return post("/customer/products", id);
          },
          callback: (data) => {
            page.init("stores.products", data.data);
          },
        });
      },
    });
  };

  return (
    <div className="card shadow soft list">
      <div className="grid-bar">
        <div className="span-10">
          <div className="grid-bar">
            <h1 className="span-4 item">Customers</h1>
            <div className="span-4">
              <DropDown
                value={page.state.menu.selected}
                title={
                  page.state.menu.selected === "" ? "Load Customer" : selected
                }
              >
                {page.state.stores.customers.length > 0 ? (
                  page.state.stores.customers.map((customer) => {
                    return (
                      <div
                        onClick={() => {
                          page.set("menu.selected", customer._id);
                          handleOnLoad(customer._id);
                          setAlert({
                            type: "clear",
                            msg: "",
                          });
                        }}
                        className="dropdown-option"
                        key={customer._id}
                        value={customer._id}
                      >
                        {customer.profileName}
                      </div>
                    );
                  })
                ) : (
                  <></>
                )}
              </DropDown>
            </div>
          </div>
        </div>
        <div className="span-2 right">
          <button className="btn-primary" onClick={handleOnNew}>
            New Customer
          </button>
        </div>
      </div>
    </div>
  );
}
export default CustomerMenu;
