import axios from "../../utils/axios";
import useAuth from "../User/useAuth";

const useRefresh = () => {
  const { setAuth } = useAuth();
  const refresh = async () => {
    const response = await axios.get("user/refresh", { withCredentials: true });
    setAuth((prev) => {
      return {
        ...prev,
        role: response.data.role,
        name: response.data.name,
        accessToken: response.data.accessToken,
      };
    });
    return response.data.accessToken;
  };
  return refresh;
};

export default useRefresh;
