const format_time = (time) => {
  if (time === undefined) return 0;
  if (time < 60) {
    return `${time.toPrecision(2)}s`;
  } else if (time >= 60 && time <= 3600) {
    let integer = Math.floor(time / 60);
    if (integer >= 0 && integer < 9) {
      return `${(time / 60).toFixed(2)}m`;
    } else {
      return `${(time / 60).toFixed(2)}m`;
    }
  } else if (time > 3600) {
    let integer = Math.floor(time / 3600);
    if (integer >= 0 && integer < 9) {
      return `${(time / 3600).toFixed(2)}h`;
    } else {
      return `${(time / 3600).toFixed(2)}h`;
    }
  }
};
export default format_time;
