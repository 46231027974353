import trunc_str from "../../../../utils/trunc_str";

import NumberInputBorderless from "../../../../components/NumberInputBorderless";
import DropDownBorderless from "../../../../components/DropDownBorderless";
function ProductRequirements(props) {
  const { page, componentIndex, optionIndex, index } = props;
  const self =
    page.state.body.selectedProduct.components[componentIndex].options[
      optionIndex
    ].requirements[index];
  const path = `body.selectedProduct.components.${componentIndex}.options.${optionIndex}.requirements.${index}`;
  return (
    <div className="grid-bar">
      <div className="span-1 cell bar">
        <button
          className="btn-link item"
          onClick={(e) => {
            e.preventDefault();
            page.remove(
              `body.selectedProduct.components.${componentIndex}.options.${optionIndex}.requirements`,
              index
            );
          }}
        >
          Remove
        </button>
      </div>
      <div className="span-9 cell">
        <DropDownBorderless
          css="span-8"
          title={
            self.name !== "" ? trunc_str(self.name, 50) : "Select Material"
          }
          error={page.state.errors[`${path}._id`]}
          errorFunc={(mount) => {
            if (mount) {
              page.error(`${path}._id`, (reason) => reason === "");
            } else {
              page.error(`${path}._id`);
            }
          }}
        >
          {page.state.stores.materials.map((material, j) => {
            return (
              <div
                key={`${material._id}${componentIndex}${optionIndex}${index}${j}`}
                className="dropdown-option"
                onClick={(e) => {
                  e.preventDefault();
                  page.set(`${path}._id`, material._id);
                  page.set(`${path}.name`, material.name);
                  page.set(`${path}.units`, material.units);
                  page.set(`${path}.cost`, material.cost);
                  page.set(`${path}.amount`, 0);
                }}
              >
                <b>{material.nickName}</b>
                <p>{trunc_str(material.name)}</p>
              </div>
            );
          })}
        </DropDownBorderless>
      </div>
      <div className="span-2 cell last pair">
        <NumberInputBorderless
          value={self.amount}
          onChange={(e) => {
            e.preventDefault();
            page.set(`${path}.amount`, e.target.value);
          }}
          error={page.state.errors[`${path}.amount`]}
          errorFunc={(mount) => {
            if (mount) {
              page.error(`${path}.amount`, (reason) => {
                return (
                  reason.length === 0 ||
                  reason === 0 ||
                  reason === "0" ||
                  reason === ""
                );
              });
            } else {
              page.error(`${path}.amount`);
            }
          }}
        />
        <span className="item">{`${self.units}`}</span>
      </div>
    </div>
  );
}
export default ProductRequirements;
