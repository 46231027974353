import Close from "../icons/Close";
import Ellipse from "../icons/Ellipse";
import { useEffect, useRef } from "react";
import is_overflown from "../utils/is_overflown";
function ModalBase({ children, ...props }) {
  const ref = useRef(null);
  const {
    page,
    open,
    title,
    msg,
    close,
    loading,
    alert = { type: "none" },
    checkOverflow = false,
    overflowDirection = "bottom",
    overflowReason = null,
  } = props;

  useEffect(() => {
    if (checkOverflow) {
      is_overflown(ref.current, overflowDirection, ref.current);
    }
  }, [checkOverflow, overflowDirection, overflowReason]);

  const alertType = () => {
    if (alert.type === "error") {
      return "var(--error)";
    } else if (alert.type === "success") {
      return "var(--primary)";
    } else if (alert.type === "warning") {
      return "var(--warning)";
    } else {
      return "var(--text)";
    }
  };

  return open === true ? (
    <div ref={ref} className="modal-page" style={{ display: "block" }}>
      <div className="modal-container shadow">
        <div className="modal-header">
          <div className="bar split">
            <div className="pair ">
              <h1>{title}</h1>
              <h3 className="pL pT" style={{ color: alertType() }}>
                {loading === true ? <Ellipse /> : alert.msg}
              </h3>
            </div>
            <button
              className="btn-icon"
              onClick={(e) => {
                e.preventDefault();
                close(e);
              }}
            >
              <Close />
            </button>
          </div>
        </div>
        <div className="modal-body soft">{children}</div>
      </div>
      <div style={{ marginBottom: "200px" }}></div>
    </div>
  ) : (
    <></>
  );
}
export default ModalBase;
