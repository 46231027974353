import DragDropIcon from "../../../../../icons/DragDrop";
import CloseIcon from "../../../../../icons/Close";
import TextInput from "../../../../../components/TextInput";
import copy_requirements from "./copy_requirements";

import ProductRequirements from "../ProductRequirements";
function OptionEdit(props) {
  const { option, componentIndex, optionIndex, page, drag, color } = props;

  const path = `body.selectedProduct.components.${componentIndex}.options.${optionIndex}`;
  return (
    <>
      <div
        className="bar"
        style={{ backgroundColor: color }}
        data-id={optionIndex}
        data-group="option"
        draggable="true"
        onMouseUp={(e) => {
          drag.up(e);
        }}
        onMouseMove={(e) => {
          drag.move(e);
        }}
        onMouseDown={(e) => {
          drag.down(e);
        }}
        onDragStart={(e) => {
          drag.start(e);
        }}
        onDragEnd={(e) => {
          drag.end(e);
        }}
        onDragEnter={(e) => {
          drag.enter(e);
        }}
        onDragOver={(e) => {
          drag.over(e);
        }}
        onDragLeave={(e) => {
          drag.leave(e);
        }}
        onDrop={(e) => {
          drag.drop(
            e,
            `body.selectedProduct.components.${componentIndex}.options`
          );
        }}
      >
        <div className="grid-bar">
          <div className="span-1" data-group="option">
            <DragDropIcon />
          </div>
          <button
            data-id={option.id}
            data-edit={option.edit}
            tabIndex={-1}
            className="table-cell-link btn-icon span-1"
            onClick={(e) => {
              e.preventDefault();
              page.set(`${path}.edit`, !option.edit);
            }}
          >
            <div className="item">Lock</div>
          </button>
          <div className="span-4">
            <TextInput
              value={option.name}
              data-id={option.id}
              onChange={(e) => {
                e.preventDefault();
                page.set(`${path}.name`, e.target.value);
              }}
              error={page.state.errors[`${path}.name`]}
              errorFunc={(mount) => {
                if (mount) {
                  page.error(`${path}.name`, (reason) => reason.length === 0);
                } else {
                  page.error(`${path}.name`);
                }
              }}
            />
          </div>
        </div>

        <button
          data-id={option.id}
          tabIndex={-1}
          className="btn-icon right"
          onClick={(e) => {
            e.preventDefault();
            page.remove(
              `body.selectedProduct.components.${componentIndex}.options`,
              optionIndex
            );
          }}
        >
          <CloseIcon />
        </button>
      </div>
      {option.requirements.map((requirement, index) => (
        <ProductRequirements
          key={`req-${requirement._id}${index}`}
          page={page}
          componentIndex={componentIndex}
          optionIndex={optionIndex}
          index={index}
        />
      ))}
      <div className="bar split titleline">
        <button
          className="btn-default mT mB"
          onClick={(e) => {
            e.preventDefault();
            page.add(
              `body.selectedProduct.components.${componentIndex}.options.${optionIndex}.requirements`,
              {
                _id: "",
                name: "",
                units: "",
                amount: 0,
                cost: 0,
              }
            );
          }}
        >
          + Requirement
        </button>
        <div className="pair">
          <label className="item">
            Copy these requirements to all options:
          </label>
          <button
            className="btn-link"
            onClick={(e) => {
              copy_requirements(page, componentIndex, optionIndex);
              e.preventDefault();
            }}
          >
            Copy
          </button>
        </div>
      </div>
    </>
  );
}
export default OptionEdit;
