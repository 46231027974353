function Unlock(props) {
  const fill = props.fill || "var(--primary)";
  return (
    <svg
      version="1.1"
      id="Layer_1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      height="15"
      width="15.525"
      viewBox="0 0 41.2 40"
      xmlSpace="preserve"
      fill={fill}
    >
      <path
        d="M25.5,1.4c-2.7,1.4-4.9,5.1-4.9,8.2v6.2h-17H1.8c-1,0-1.8,0.8-1.8,1.8v20.6c0,1,0.8,1.8,1.8,1.8H26c1,0,1.8-0.8,1.8-1.8
	V17.6c0-1-0.8-1.8-1.8-1.8h-1.8V9.6c0-1.9,1-3.7,2.6-4.8c5.2-3.2,10.7,0.5,10.7,5.5v4.3c0,0.6,0.5,1.2,1.2,1.2H40
	c0.6,0,1.2-0.5,1.2-1.2v-4.3C41.2,2.9,33.3-2.7,25.5,1.4z M15.8,28.5v2.4c0,1-0.8,1.8-1.8,1.8s-1.8-0.8-1.8-1.8v-2.4
	c-0.7-0.6-1.2-1.4-1.2-2.4c0-1.7,1.4-3,3-3s3,1.4,3,3C17,27,16.5,27.9,15.8,28.5z"
      />
    </svg>
  );
}
export default Unlock;
