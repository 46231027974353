function MaterialLine(props) {
  const { page, material, color, index } = props;

  function truncateUrl(url) {
    const match = url.match(/^(https?:\/\/[^\/]+)/);
    return match ? match[0] : url;
  }

  return (
    <div className={`grid-bar titleline ${color}`}>
      <div className="grid-bar span-3">
        <p className="full">{material.name}</p>
        <div className="full pair">
          <button
            className="btn-link"
            onClick={(e) => {
              e.preventDefault();
              page.set("modal.open", true);
              page.set("modal.mode", "edit");
              page.set("modal._id", material._id);
              page.set("modal.name", material.name);
              page.set("modal.nickName", material.nickName);
              page.set("modal.description", material.description);
              page.set("modal.amount", material.amount);
              page.set("modal.type.selected", material.type);
              page.set("modal.units.selected", material.units);
              page.set("modal.cost", material.cost);
              page.set("modal.supplier", material.supplier);
              page.set("modal.website", material.website);
            }}
          >
            Edit
          </button>
          <p className="item">{material.nickName}</p>
        </div>
      </div>

      <div className="span-2">
        <p className="item">{`${material.amount} ${material.units}`}</p>
      </div>
      <div className="span-2">
        <p className="item">{`$${material.cost.toFixed(2)}/${
          material.units
        }`}</p>
      </div>
      <div className="span-2">
        <p className="item">{`${material.supplier}`}</p>
      </div>
      <div className="span-2">
        <div style={{ padding: "8px 0px" }}>
          <a
            className="btn-link"
            href={material.website}
            target="_blank"
            rel="noopener noreferrer"
          >
            {truncateUrl(material.website)}
          </a>
        </div>
      </div>
    </div>
  );
}

export default MaterialLine;
