import NumberInput from "../../../../components/NumberInput";
import TextInput from "../../../../components/TextInput";
import CloseIcon from "../../../../icons/Close";
import DragDropIcon from "../../../../icons/DragDrop";
import { add_step } from "./add_step";

function ProcessStep(props) {
  const { index, drag, page, desc, name, constant, cost, lock } = props;

  return (
    <div
      data-product="step"
      className="card soft shadow list"
      data-id={index}
      data-group="steps"
      draggable="true"
      onMouseUp={(e) => {
        drag.up(e);
      }}
      onMouseMove={(e) => {
        drag.move(e);
      }}
      onMouseDown={(e) => {
        drag.down(e);
      }}
      onDragStart={(e) => {
        drag.start(e);
      }}
      onDragEnd={(e) => {
        drag.end(e);
      }}
      onDragEnter={(e) => {
        drag.enter(e);
      }}
      onDragOver={(e) => {
        drag.over(e);
      }}
      onDragLeave={(e) => {
        drag.leave(e);
      }}
      onDrop={(e) => {
        drag.drop(e, "body.selectedProcess.steps");
        page.validate();
      }}
    >
      <div className="bar split">
        <div className="bar">
          <button
            style={lock ? { display: "none" } : { display: "block" }}
            id={"btn-" + index}
            className="btn-icon"
            tabIndex={-1}
            onClick={(e) => {
              e.preventDefault();
              page.remove("body.selectedProcess.steps", index);
            }}
          >
            <CloseIcon />
          </button>
        </div>
        <DragDropIcon />
      </div>
      <label className="grid-bar">
        <div className="input-label span-2 right">{`Step. ${
          index + 1
        } Name`}</div>
        <TextInput
          value={name}
          onChange={(e) => {
            page.set(
              `body.selectedProcess.steps.${index}.name`,
              e.target.value
            );
          }}
          error={page.state.errors[`body.selectedProcess.steps.${index}.name`]}
          errorMsg="Step name required"
          errorFunc={(mount) => {
            if (mount) {
              page.error(
                `body.selectedProcess.steps.${index}.name`,
                (reason) => reason.length === 0
              );
            } else {
              page.error(`body.selectedProcess.steps.${index}.name`);
            }
          }}
          css="half"
          placeholder="Step Name"
          required={true}
          lock={false}
        />
        <div className="span-4 bar">
          <div className="input-label item">Estimate</div>
          <NumberInput
            value={constant}
            onChange={(e) => {
              page.set(
                `body.selectedProcess.steps.${index}.constant`,
                e.target.value
              );
            }}
            lock={false}
          />
          <div className="input-label item">sec</div>
          <div className="input-label item">Cost</div>
          <NumberInput
            value={cost}
            onChange={(e) => {
              page.set(
                `body.selectedProcess.steps.${index}.cost`,
                e.target.value
              );
            }}
            lock={false}
          />
          <div className="input-label item">$/hr</div>
        </div>
      </label>
      <textarea
        className="form-control form-textarea list"
        type="textarea"
        placeholder="Detailed description"
        value={desc}
        onChange={(e) => {
          page.set(`body.selectedProcess.steps.${index}.desc`, e.target.value);
        }}
      />
      {index === page.state.body.selectedProcess.steps.length - 1 ? (
        <button
          className="btn-default"
          onClick={(e) => {
            e.preventDefault();
            add_step(page);
          }}
          style={lock ? { display: "none" } : { display: "block" }}
        >
          + Step
        </button>
      ) : (
        <></>
      )}
    </div>
  );
}

export default ProcessStep;
