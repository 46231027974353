import NewEmployee from "../features/employee/NewEmployee";
import Menu from "../features/employee/Menu";
import useEmployee from "../features/employee/useEmployee";
import ModalBase from "../components/ModalBase";
import EmployeeTable from "../features/employee/EmployeeTable";
import AddJob from "../features/employee/AddJob";
import Spinner from "../icons/Spinner";
function Employee() {
  const page = useEmployee();
  return (
    <div className="page">
      <ModalBase
        page={page}
        open={page.state.modal.open}
        alert={page.state.modal.alert}
        title={"New Employee"}
        close={() => {
          page.set("modal.open", false);
          page.reset("modal");
        }}
      >
        <NewEmployee page={page} />
      </ModalBase>
      <ModalBase
        title={"Add Jobs"}
        alert={page.state.modal2.alert}
        page={page}
        open={page.state.modal2.open}
        close={(e) => {
          e.preventDefault();
          page.reset("modal2");
        }}
      >
        <AddJob page={page} />
      </ModalBase>
      <Menu page={page} />
      {page.isLoading["stores.employee"] ? (
        <Spinner />
      ) : (
        <EmployeeTable page={page} />
      )}
    </div>
  );
}
export default Employee;
