import Lock from "../icons/Lock";
import Unlock from "../icons/Unlock";
import TextInput from "./TextInput";
function General(props) {
  const {
    title = "General",
    company,
    firstName,
    lastName,
    website,
    email,
    phone,
    lock,
    onLock,
  } = props;

  return (
    <>
      <div className="pair titleline">
        <h3 className="item">{title}</h3>
        <button
          className="btn-icon"
          onClick={(e) => {
            onLock(e);
          }}
        >
          {!lock ? <Unlock /> : <Lock fill="var(--text)" />}
        </button>
      </div>
      <div className="card-two-thirds">
        <div className="grid-bar">
          <label className="third input-label right">Company</label>
          <TextInput
            value={company.value}
            required={company?.required ?? false}
            onChange={company.onChange}
            css={company.css}
            placeholder={company?.placeholder ?? "Company"}
            lock={lock}
          />
        </div>
        <div className="grid-bar">
          <label className="third input-label right">Name</label>
          <div className="two-thirds grid-bar gap">
            <TextInput
              value={firstName.value}
              required={firstName?.required ?? false}
              render={firstName.render}
              onChange={firstName.onChange}
              css={firstName.css}
              placeholder={firstName?.placeholder ?? "First Name"}
              error={firstName.error}
              errorFunc={firstName.errorFunc}
              lock={lock}
            />
            <TextInput
              value={lastName.value}
              required={lastName?.required ?? false}
              render={lastName.render}
              onChange={lastName.onChange}
              css={lastName.css}
              placeholder={lastName?.placeholder ?? "Last Name"}
              error={lastName.error}
              errorFunc={lastName.errorFunc}
              lock={lock}
            />
          </div>
        </div>
        <div className="grid-bar">
          <label className="third input-label right">Website</label>
          <TextInput
            value={website.value}
            required={website?.required ?? false}
            render={website.render}
            onChange={website.onChange}
            css={website.css}
            placeholder={website?.placeholder ?? "Website Name"}
            onError={website?.onError}
            errors={website.errors}
            lock={lock}
          />
        </div>
        <div className="grid-bar">
          <label className="third input-label right">Email</label>
          <TextInput
            value={email.value}
            required={email?.required ?? false}
            render={email.render}
            onChange={email.onChange}
            css={email.css}
            placeholder={email?.placeholder ?? "Email Address"}
            onError={email?.onError}
            errors={email.errors}
            lock={lock}
          />
        </div>
        <div className="grid-bar">
          <label className="third input-label right">Phone</label>
          <TextInput
            value={phone.value}
            required={phone?.required ?? false}
            render={phone.render}
            onChange={phone.onChange}
            css={phone.css}
            placeholder={phone?.placeholder ?? "Phone Number"}
            onError={phone?.onError}
            errors={phone.errors}
            lock={lock}
          />
        </div>
      </div>
    </>
  );
}
export default General;
