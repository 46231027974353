import AddressBlock from "../../../components/AddressBlock";
function ShippingAddress(props) {
  const { page } = props;

  const address = page.state.modal.shippingAddress;
  const hidden = page.state.modal.billingAddress.hidden;
  const render = page.state.modal.render;

  return hidden ? (
    <></>
  ) : (
    <AddressBlock
      title="Shipping Information"
      cardCSS="card-two-thirds"
      lock={address.lock}
      onLock={() => {
        page.toggle("modal.shippingAddress.lock");
      }}
      address1={{
        value: address.address1,
        render: render,
        required: true,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.shippingAddress.address1", e.target.value);
        },
        error: page.state.errors["modal.shippingAddress.address1"],
        errorMsg: "Address Required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.shippingAddress.address1", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.shippingAddress.address1");
          }
        },
      }}
      address2={{
        value: address.address2,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.shippingAddress.address2", e.target.value);
        },
      }}
      address3={{
        value: address.address3,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.shippingAddress.address3", e.target.value);
        },
      }}
      city={{
        value: address.city,
        render: render,
        required: true,
        css: "half",
        onChange: (e) => {
          page.set("modal.shippingAddress.city", e.target.value);
        },
        error: page.state.errors["modal.shippingAddress.city"],
        errorMsg: "City Required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.shippingAddress.city", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.shippingAddress.city");
          }
        },
      }}
      state={{
        value: address.state,
        render: render,
        required: true,
        css: "half",
        onChange: (e) => {
          page.set("modal.shippingAddress.state", e.target.value);
        },
        error: page.state.errors["modal.shippingAddress.state"],
        errorMsg: "State Required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.shippingAddress.state", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.shippingAddress.state");
          }
        },
      }}
      country={{
        value: address.country,
        required: true,
        css: "two-thirds",
        onChange: (e) => {
          page.set("modal.shippingAddress.country", e.target.value);
        },
      }}
      postalCode={{
        value: address.postalCode,
        render: render,
        required: true,
        css: "two-thirds",
        error: page.state.errors["modal.shippingAddress.postalCode"],
        errorMsg: "Postal Code Required",
        errorFunc: (mount) => {
          if (mount) {
            page.error("modal.shippingAddress.postalCode", (reason) => {
              return reason.length === 0;
            });
          } else {
            page.error("modal.shippingAddress.postalCode");
          }
        },
        onChange: (e) => {
          page.set("modal.shippingAddress.postalCode", e.target.value);
        },
      }}
      copyButton={{
        hidden: true,
        text: "Same as Priamry?",
        onClick: () => {},
      }}
    />
  );
}
export default ShippingAddress;
