exports.format_order_table_header = (set) => {
  const headers = [
    { name: "Status", key: "status" },
    { name: "Batches", key: "batches" },
    { name: "Items", key: "items" },
    { name: "Order #", key: "orderNumber" },
    { name: "Age", key: "age" },
    { name: "Order Date", key: "orderDate" },
    { name: "Customer", key: "customer" },
  ];

  const mappedHeaders = headers.map((header, i) => {
    return {
      name: header.name,
      key: header.key,
      action: header.action,
      index: i,
      width: 100,
    };
  });

  if (localStorage.getItem("orders") !== null) {
    const columns = JSON.parse(localStorage.getItem("orders"));
    set("tableInfo.columns", columns);
  } else {
    set("tableInfo.columns", mappedHeaders);
  }
};
