import trunc_str from "../../../utils/trunc_str";

function Requirements(props) {
  const { selected } = props;

  //COMPONENT
  return (
    <div className="column soft">
      <h3 className="grid-bar titleline">Requirements</h3>
      <div className="grid-bar">
        <b className="span-2">{`Avg Cost: $${selected?.total_avg_mat_cost?.toFixed(
          2
        )}`}</b>
      </div>
      <div className="grid-bar">
        <b className="span-4">Material</b>
        <b className="span-2">Avg Usage</b>
        <b className="span-2">Avg Cost</b>
      </div>
      {selected.materials.map((mat) => {
        return (
          <div key={mat._id} className="grid-bar">
            <div className="span-4">{trunc_str(mat.name)}</div>
            <div className="span-2">{`${mat?.amount?.toFixed(2)} ${
              mat.units
            }`}</div>
            <div className="span-2">{`$${mat?.cost?.toFixed(2)} `}</div>
          </div>
        );
      })}
    </div>
  );
}
export default Requirements;
