import React, { useRef, useEffect, useState } from "react";
import format_time from "../../../utils/format_time";
import get_shop_totals from "./get_shop_totals";
import get_emp_totals from "./get_emp_totals";
import get_sku_totals from "./get_sku_totals";

function Graph(props) {
  const { selected } = props;
  const graph = useRef(null);
  const [employees, setEmployees] = useState([]);

  const scale_bar = (employees, shop) => {
    const values = [];
    employees.forEach((emp) => {
      values.push(emp.totals.avg);
    });
    return range(values, shop);
  };

  const range = (values, shop) => {
    const mid = shop.avg;
    const min = 0;
    const max = mid * 2;
    const range = max - min === 0 ? 1 : max - min;
    return values.map((value) => 250 + ((value - mid) / range) * 250);
  };

  const name_initials = (name) => {
    const names = name.split(" ");
    return `${names[0].charAt(0)}.${names[1].charAt(0)}.`;
  };

  const bar_width = () => {
    if (graph.current === null) return 25;
    return graph.current.offsetWidth / employees.length > 25
      ? 25
      : graph.current.offsetWidth / employees.length;
  };

  const shop = get_shop_totals(employees);
  const bars = scale_bar(employees, shop);
  const barWidth = bar_width();

  useEffect(() => {
    let emps = [];
    if (selected.sku === "All Skus") emps = get_emp_totals(selected);
    if (selected.sku !== "All Skus") emps = get_sku_totals(selected);
    setEmployees(emps);
  }, [selected, selected.skuId]);

  return (
    <div className="graph" ref={graph}>
      <svg
        height="1px"
        width={`${1000}px`}
        style={{
          position: "absolute",
          bottom: `${250}px`,
          zIndex: 1,
        }}
      >
        <line
          x1="0"
          y1="0"
          x2={`${1000}px`}
          y2="0"
          style={{
            stroke: "black",
            strokeWidth: 2,
            strokeDasharray: "5,5",
          }}
        />
      </svg>
      <div
        style={{
          position: "absolute",
          bottom: `${250}px`,
          right: "5px",
          zIndex: 1,
        }}
      >{`Selected Avg: ${format_time(shop.avg)}`}</div>
      {graph.current &&
        employees.map((employee, index) => {
          return (
            <React.Fragment key={employee._id}>
              <div
                className="graph-bar expand"
                style={{ left: `${25 + index * 100}px`, bottom: "0px" }}
              >
                <svg height={`${bars[index]}px`} width={`${barWidth}px`}>
                  <rect
                    height={`${bars[index]}px`}
                    width={`${barWidth}px`}
                    style={{ fill: "var(--primary)" }}
                  />
                </svg>
              </div>
              <div
                style={{
                  position: "absolute",
                  left: `${25 + index * 100}px`,
                  top: "510px",
                  zIndex: "2",
                }}
              >
                <div className="hover-unhide">
                  {name_initials(employee.name)}
                </div>
                <div
                  className="hover-hidden"
                  style={{
                    position: "absolute",
                    top: "0px",
                    minWidth: "fit-content",
                    backgroundColor: "var(--background)",
                    whiteSpace: "nowrap",
                    zIndex: "3",
                  }}
                >
                  {employee.name}
                </div>
                <div>{format_time(employee.totals.avg)} </div>
                <div>{`Count: ${employee.totals.count}`} </div>
              </div>
            </React.Fragment>
          );
        })}
    </div>
  );
}
export default Graph;
