function format_product_payload(page) {
  const product = page.state.body.selectedProduct;
  const customerId = page.state.body.selectedAddress.customerId;
  const customerName =
    page.state.stores.customers.find((customer) => customer._id === customerId)
      ?.profileName ?? "";

  return {
    _id: product?._id ?? "",
    name: product.name,
    desc: product.desc,
    customerId: customerId,
    customerName: customerName,
    processId: product.processId,
    processName: product.processName,
    delimiter: product.delimiter,
    parts: product.parts,
    archived: false,
    components: product.components.map((component, index) => {
      return {
        ...component,
        name: component.name === "" ? `Component ${index + 1}` : component.name,
        options: component.options.map((option, index) => {
          return {
            name: option.name,
            requirements: option.requirements.map((requirement, index) => {
              return {
                ...requirement,
                cost: requirement.cost === undefined ? 0 : requirement.cost,
              };
            }),
          };
        }),
      };
    }),
  };
}
export default format_product_payload;
