function Option(props) {
  const { page, componentIndex, optionIndex, option, drag } = props;

  //COMPONENT
  return (
    <div
      key={`bar-${option.id}`}
      className="table-row bordered"
      data-id={optionIndex}
      data-group="option"
      draggable="true"
      onMouseUp={(e) => {
        drag.up(e);
      }}
      onMouseMove={(e) => {
        drag.move(e);
      }}
      onMouseDown={(e) => {
        drag.down(e);
      }}
      onDragStart={(e) => {
        drag.start(e);
      }}
      onDragEnd={(e) => {
        drag.end(e);
      }}
      onDragEnter={(e) => {
        drag.enter(e);
      }}
      onDragOver={(e) => {
        drag.over(e);
      }}
      onDragLeave={(e) => {
        drag.leave(e);
      }}
      onDrop={(e) => {
        drag.drop(
          e,
          `body.selectedProduct.components.${componentIndex}.options`
        );
      }}
    >
      <div className="grid-bar">
        <div className="span-1 column center">
          <button
            data-edit={option.edit}
            tabIndex={-1}
            className="table-cell-link btn-icon item"
            onClick={(e) => {
              e.preventDefault();
              page.set(
                `body.selectedProduct.components.${componentIndex}.options.${optionIndex}.edit`,
                true
              );
            }}
          >
            <div>Edit</div>
          </button>
        </div>

        <div className="span-8 column center">
          <span>{option.name}</span>
        </div>
        <div className="span-3 bar right">
          <span>Req: {option.requirements.length}</span>
        </div>
      </div>
    </div>
  );
}
export default Option;
