import DropDown from "../../components/DropDown";
import NumberInput from "../../components/NumberInput";
import Close from "../../icons/Close";
import trunc_str from "../../utils/trunc_str";
function AddTask(props) {
  const { page } = props;

  return page.state.modal2.products.map((product, index) => {
    return (
      <div className="grid-bar" key={`${product._id} ${index}`}>
        <div className="span-2 bar">
          <div className="pB pR">Qty:</div>
          <NumberInput
            value={product.quantity}
            error={page.state.errors[`modal2.products.${index}.quantity`]}
            errorFunc={(mount) => {
              if (mount) {
                page.error(`modal2.products.${index}.quantity`, (reason) => {
                  let value = parseInt(reason);
                  return reason.length === 0 || value === 0;
                });
              } else {
                page.error(`modal2.products.${index}.quantity`);
              }
            }}
            onChange={(e) => {
              page.set(`modal2.products.${index}.quantity`, e.target.value);
            }}
          />
          <div className="pB pR">{`/${product.outOf}`}</div>
        </div>
        <div className="span-3 pair">
          <div className="pB pR">Product:</div>
          <DropDown
            title={product.sku === "" ? "Item" : product.sku}
            value={product.sku}
            error={page.state.errors[`modal2.products.${index}.sku`]}
            errorMsg={page.state.errorMessages[`modal2.products.${index}.sku`]}
            errorFunc={(mount) => {
              if (mount) {
                page.error(
                  `modal2.products.${index}.sku`,
                  (reason) => {
                    return reason === "";
                  },
                  "Please select a product"
                );
              } else {
                page.error(`modal2.products.${index}.sku`);
              }
            }}
          >
            {page.state.modal2.items.map((item) => {
              return (
                <div
                  className="dropdown-option"
                  key={item._id + index}
                  onClick={() => {
                    page.set(`modal2.products.${index}.product`, item.name);
                    page.set(
                      `modal2.products.${index}.productId`,
                      item.productId
                    );
                    page.set(`modal2.products.${index}.sku`, item.sku);
                    page.set(`modal2.products.${index}.skuId`, item.skuId);
                    page.set(
                      `modal2.products.${index}.quantity`,
                      item.quantity
                    );
                    page.set(`modal2.products.${index}.outOf`, item.quantity);
                    page.set(`modal2.products.${index}.steps`, item.steps);
                  }}
                >
                  <div className="bar">
                    <div>Product:</div>
                    <b className="item">{item.name}</b>
                    <div>Sku:</div>
                    <b className="item">{item.sku}</b>
                  </div>
                </div>
              );
            })}
          </DropDown>
        </div>
        <div className="span-3 bar">
          <div className="pB pR">Step:</div>
          <DropDown
            title={product.step === "" ? "Step" : trunc_str(product.step)}
            value={product.step}
            error={page.state.errors[`modal2.products.${index}.step`]}
            errorMsg={page.state.errorMessages[`modal2.products.${index}.step`]}
            errorFunc={(mount) => {
              if (mount) {
                page.error(
                  `modal2.products.${index}.step`,
                  (reason) => {
                    return reason === "";
                  },
                  "Please select a step"
                );
              } else {
                page.error(`modal2.products.${index}.step`);
              }
            }}
          >
            {product.steps.map((step, i) => {
              return step.constant === 0 ? (
                <div
                  className="dropdown-option"
                  key={`${step._id} ${index} ${i}`}
                  onClick={() => {
                    page.set(`modal2.products.${index}.stepId`, step._id);
                    page.set(
                      `modal2.products.${index}.step`,
                      `${i + 1}. ${step.name}`
                    );
                    page.set(`modal2.products.${index}.desc`, step.desc);
                  }}
                >
                  <div className="bar">
                    <b className="item">{`${i + 1}.`}</b>
                    <div>Name:</div>
                    <b className="item">{trunc_str(step.name)}</b>
                    <div>Desc:</div>
                    <b className="item">{trunc_str(step.desc)}</b>
                  </div>
                </div>
              ) : (
                <></>
              );
            })}
          </DropDown>
        </div>
        <div className="span-3 pB pair">
          <div>Desc: </div>
          <div className="item">{trunc_str(product.desc)}</div>
        </div>
        <div
          className="span-1 s-12"
          onClick={() => {
            page.remove(`modal2.products`, index);
          }}
        >
          <button className="btn-icon">
            <Close />
          </button>
        </div>
      </div>
    );
  });
}
export default AddTask;
