import BarcodeSVG from "./BarcodeSVG";
import Barcode from "../../utils/barcode";
import CloseIcon from "../../icons/Close";

function BatchBarcodeModal(props) {
  const { page } = props;

  return (
    <div
      className="barcode-modal-container"
      onClick={(e) => {
        page.reset("barcodeModal");
      }}
    >
      <div className="barcode-modal">
        <div className="bar split barcode-nav">
          <h4>{page.state.modal.barcodes?.[0]?.batchName}</h4>
          <button
            className="btn-icon"
            onClick={(e) => {
              page.reset("modal");
            }}
          >
            <CloseIcon />
          </button>
        </div>

        <div className="barcodes-container">
          {page.state.modal.barcodes?.map((barcode, index) => {
            let img;
            if (barcode.skuId === "") {
              img = {
                missing: true,
              };
            } else {
              img = new Barcode(barcode._id).draw();
            }

            return (
              <BarcodeSVG
                img={img}
                item={barcode}
                key={`${index}`}
                prevIndex={index}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}
export default BatchBarcodeModal;
