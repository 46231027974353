import CloseIcon from "../icons/Close";
import CheckCircle from "../icons/CheckCircle";
function ModalAlert(props) {
  const { alerts, setAlert } = props;
  const check = alerts.length;
  const classNameLookup = {
    error: "alert-modal-error",
    off: "alert-modal-off",
    on: "alert-modal-on",
    warning: "alert-modal-warning",
    query: "alert-modal-query",
    success: "alert-modal-success",
  };
  const removeAlert = (id) => {
    setAlert(
      alerts.filter((alert) => {
        if (alert.id !== id) {
          return alert;
        } else {
          //eslint-disable-next-line
          return;
        }
      })
    );
  };

  return (
    <>
      <div className={check < 1 ? classNameLookup.off : classNameLookup.on}>
        {check < 1
          ? ""
          : alerts.map((alert, index) => {
              return (
                <div key={`alert${index}`} className="alert-modal shadow">
                  <button
                    data-id={alert.id}
                    className="btn-icon icon"
                    onClick={(e) => {
                      e.preventDefault();
                      alert.action();
                      removeAlert(e.currentTarget.dataset.id);
                    }}
                    style={
                      alert.type === "query"
                        ? { visibility: "visible" }
                        : { visibility: "hidden" }
                    }
                  >
                    <CheckCircle />
                  </button>
                  <div>
                    <p className={classNameLookup[alert.type]}>{alert.msg}</p>
                  </div>
                  <button
                    data-id={alert.id}
                    className="btn-icon right"
                    onClick={(e) => {
                      e.preventDefault();
                      removeAlert(e.currentTarget.dataset.id);
                    }}
                  >
                    <CloseIcon />
                  </button>
                </div>
              );
            })}
      </div>
    </>
  );
}
export default ModalAlert;
