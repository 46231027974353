import DropDown from "../../../../components/DropDown";
function ProductHeader(props) {
  const { page } = props;

  return (
    <>
      <div className="grid-bar titleline note heading">
        <h3 className="half ">Product Information</h3>
      </div>
      <div className="grid-bar gap">
        <div className="span-2 right">
          <button
            className="btn-primary"
            onClick={(e) => {
              e.preventDefault();
              page.set("body.mode", "new");
              page.reset(`body.selectedProduct`);
            }}
          >
            New
          </button>
        </div>
        <div className="span-10">
          <DropDown
            title={
              page.state.body.selectedProduct.name === ""
                ? "Load Product"
                : page.state.body.selectedProduct.name
            }
            value={page.state.body.selectedProduct?._id ?? ""}
            css="half"
          >
            {page.state.stores.products.length === 0 ? (
              <></>
            ) : (
              page.state.stores?.products?.map((product) => {
                return (
                  <div
                    className="dropdown-option"
                    onClick={() => {
                      const select = page.state.stores.products.find(
                        (each) => each._id === product._id
                      );
                      const payload = {
                        ...select,
                        components: select.components.map((component) => {
                          return {
                            ...component,
                            options: component.options.map((option) => {
                              return {
                                ...option,
                                requirements: option?.requirements ?? [],
                              };
                            }),
                          };
                        }),
                      };
                      page.set("body.mode", "edit");
                      page.set(`body.selectedProduct`, payload);
                    }}
                    key={product._id}
                    value={product._id}
                  >
                    {product.name}
                  </div>
                );
              })
            )}
          </DropDown>
        </div>
      </div>

      <div className="grid-bar titleline list"></div>
    </>
  );
}
export default ProductHeader;
