import ErrorBox from "../../../components/ErrorBox";
import useServer from "../../../hooks/useServer";
import TextInput from "../../../components/TextInput";
import { useEffect, useState, useRef } from "react";
import WarningBox from "../../../components/WarningBox";
import CueSidebarItem from "./CueSidebarItem";
import useGlobalAlert from "../../../context/GlobalAlert/useGlobalAlert";
import is_overflown from "../../../utils/is_overflown";

function OrderCueSidebar(props) {
  const { page, drag } = props;
  const { post } = useServer();
  const [errorMsg, setErrorMsg] = useState("Batch Name is required");
  const [placeHolder, setPlaceHolder] = useState("Batch Name");
  const { setAlert } = useGlobalAlert();
  const ref = useRef(null);

  useEffect(() => {
    let direction = "bottom";
    if (page.state.body.cueSidebar.orderItems.length <= 1) {
      direction = "top";
    }
    is_overflown(ref.current, direction, ref.current);
  }, [page.state.body.cueSidebar.orderItems.length]);

  useEffect(() => {
    page.error("body.cueSidebar.orderItems", (reason) => {
      return reason.length === 0;
    });
    return () => {
      page.error("body.cueSidebar.orderItems");
    };
  }, []);

  const on_clear = (e) => {
    e.preventDefault(e);

    page.reset("stores.orders");
    page.reset("body.cueTable");
    page.reset("body.cueSidebar");
    page.set("body.cueSidebar.open", true);
    page.reset("errors");
  };

  const on_create = async (e) => {
    e.preventDefault();
    if (page.validate()) {
      return;
    }
    const date = new Date();
    page.set("cueSidebar.batchDate", date);
    const serverValidated = await post(
      "/order/batchValidate",
      page.state.body.cueSidebar
    );
    if (serverValidated?.isError) {
      page.set_by_key(
        "errors",
        "body.cueSidebar.batchName",
        serverValidated.isError
      );

      setPlaceHolder(page.state.body.cueSidebar.batchName);
      page.reset("body.cueSidebar.batchName");
      setErrorMsg(serverValidated.errorMsg);
      return;
    }

    page.set("mode", "queue");
    page.set("body.cueSidebar.clear", true);
    const payload = {
      ...page.state.body.cueSidebar,
      date: date,
    };

    const data = await post("/order/batchCreate", payload);
    if (data) {
      page.reload("stores.orders");
      setAlert({ type: "success", msg: "Batch Created" });
      page.set("mode", "create");
      page.reset("body.cueSidebar");
      page.set("body.cueSidebar.open", false);
      page.set("body.cueTable.open", false);
    }
  };

  //COMPONENT
  return (
    <>
      <ErrorBox
        isError={page.state.errors["body.cueSidebar.orderItems"]}
        errorMsg="Batch must have at least one item"
      >
        <div className="shadow soft note">
          <div className="grid-bar pB">
            <div className="half">
              <button
                className="btn-default"
                onClick={(e) => {
                  on_clear(e);
                }}
              >
                Clear
              </button>
            </div>
            <div className="half right">
              <button
                className="btn-primary item"
                onClick={(e) => {
                  on_create(e);
                }}
              >
                Create
              </button>
            </div>
          </div>
          <label className="half grid-bar">
            <div className="quarter">Batch Name:</div>
            <TextInput
              css="three-quarters"
              placeholder={placeHolder}
              value={page.state.body.cueSidebar?.batchName}
              onChange={(e) => {
                page.set("body.cueSidebar.batchName", e.target.value);

                setErrorMsg("Batch Name is required");
              }}
              error={page.state.errors["body.cueSidebar.batchName"]}
              errorMsg={errorMsg}
              errorFunc={(mount) => {
                if (mount) {
                  page.error("body.cueSidebar.batchName", (reason) => {
                    let error = false;
                    if (reason === "Batching...") error = true;
                    if (reason.length === 0) error = true;
                    return error;
                  });
                } else {
                  page.error("body.cueSidebar.batchName");
                }
              }}
            />
          </label>
        </div>
      </ErrorBox>

      <div ref={ref} id="cue-sidebar" className="table-sidebar-container">
        {page.state.body.cueSidebar?.orderItems?.map((item, index) => {
          return (
            <ErrorBox
              key={`${item._id}`}
              isError={
                page.state.errors[
                  `body.cueSidebar.orderItems.${index}.batchQuantity`
                ]
              }
              errorMsg="Batched item quantity cannot be zero"
            >
              <WarningBox
                isError={item.itemProductId === ""}
                errorMsg="Warning! sku not linked to product. No tracking will occur"
              >
                <CueSidebarItem
                  key={`${item._id}`}
                  page={page}
                  drag={drag}
                  item={item}
                  index={index}
                  setPlaceHolder={setPlaceHolder}
                  setErrorMsg={setErrorMsg}
                />
              </WarningBox>
            </ErrorBox>
          );
        })}
      </div>
    </>
  );
}
export default OrderCueSidebar;
