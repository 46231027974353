function format_save_address(selectedAddress) {
  return {
    company: selectedAddress.company,
    firstName: selectedAddress.firstName,
    lastName: selectedAddress.lastName,
    website: selectedAddress.website,
    email: selectedAddress.email,
    phone: selectedAddress.phone,
    shippingAddress: {
      address1: selectedAddress.shippingAddress.address1,
      address2: selectedAddress.shippingAddress.address2,
      address3: selectedAddress.shippingAddress.address3,
      city: selectedAddress.shippingAddress.city,
      state: selectedAddress.shippingAddress.state,
      country: selectedAddress.shippingAddress.country,
      postalCode: selectedAddress.shippingAddress.postalCode,
    },
    billingAddress: {
      address1: selectedAddress.billingAddress.address1,
      address2: selectedAddress.billingAddress.address2,
      address3: selectedAddress.billingAddress.address3,
      city: selectedAddress.billingAddress.city,
      state: selectedAddress.billingAddress.state,
      country: selectedAddress.billingAddress.country,
      postalCode: selectedAddress.billingAddress.postalCode,
    },
  };
}
export default format_save_address;
