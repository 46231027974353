import useServer from "../../hooks/useServer";
import DropDown from "../../components/DropDown";
import TextInput from "../../components/TextInput";

function UserPanelEdit(props) {
  const { page, alerts, setAlert } = props;

  const { post } = useServer();

  const map_roles = (role) => {
    return role === 1776 ? "Admin" : "User";
  };

  const handleOnEdit = async (e) => {
    e.preventDefault();
    const payload = {
      _id: page.state.userPanel.current,
      name: page.state.userPanel.name,
      email: page.state.userPanel.email,
      password: page.state.userPanel.password,
      confirmPassword: page.state.userPanel.confirmPassword,
      role: page.state.userPanel.role,
    };
    const data = await post("/admin/user/edit", payload);
    if (data.err === true) {
      setAlert([
        ...alerts,
        {
          id: (alerts.length + 1).toString(),
          type: "error",
          msg: data.msg,
        },
      ]);
    } else {
      setAlert([
        ...alerts,
        {
          id: (alerts.length + 1).toString(),
          type: "success",
          msg: data.msg,
        },
      ]);
      handleOnCancelEdit(e);
    }
  };

  const handleOnCancelEdit = (e) => {
    e.preventDefault();
    const user = page.state.stores.users.find(
      (user) => user._id === page.state.userPanel.current
    );
    const payload = {
      edit: false,
      create: false,
      current: page.state.userPanel.current,
      name: user.name,
      email: user.email,
      password: "",
      confirmPassword: "",
      roles: user.roles,
    };
    page.set("userPanel", payload);
    page.reload("stores.users");
  };

  return (
    <>
      <form>
        <div className="bar">
          <DropDown
            title={map_roles(page.state.userPanel.role)}
            value={page.state.userPanel.role}
          >
            <div
              className="dropdown-option"
              onClick={() => {
                page.set("userPanel.role", 2001);
              }}
            >
              User
            </div>
            <div
              className="dropdown-option"
              onClick={() => {
                page.set("userPanel.role", 1776);
              }}
            >
              Admin
            </div>
          </DropDown>

          <div
            className="table-cell-link list-item"
            onClick={(e) => {
              handleOnCancelEdit(e);
            }}
          >
            Cancel
          </div>
        </div>
        <input
          className="form-input list"
          type="text"
          placeholder="Full Name"
          value={page.state.userPanel.name}
          onChange={(e) => {
            page.set("userPanel.name", e.target.value);
          }}
        />
        <input
          className="form-input list"
          type="text"
          value={page.state.userPanel.email}
          placeholder="Email"
          onChange={(e) => {
            page.set("userPanel.email", e.target.value);
          }}
        />
        <input
          className="form-input list"
          type="password"
          placeholder="Password"
          onChange={(e) => {
            page.set("userPanel.password", e.target.value);
          }}
        />
        <input
          className="form-input list"
          type="password"
          placeholder="Confirm Password"
          onChange={(e) => {
            page.set("userPanel.confirmPassword", e.target.value);
          }}
        />

        <button
          className="btn-default"
          onClick={(e) => {
            handleOnEdit(e);
          }}
        >
          Update
        </button>
      </form>
    </>
  );
}
export default UserPanelEdit;
