import React, { useEffect } from "react";
import DropDown from "../../components/DropDown";
import useServer from "../../hooks/useServer";
import ProductBlock from "./ProductBlock";

function ProductList(props) {
  const { page } = props;
  const { post } = useServer();
  const customer = page.state.body.products.customer;

  useEffect(() => {
    if (page.state.body.endDate === "") {
      const date = new Date();
      const lastMonth = new Date(date.setMonth(date.getMonth() - 3));
      page.set("body.startDate", lastMonth.toISOString().split("T")[0]);
      page.set("body.endDate", new Date().toISOString().split("T")[0]);
    }
  }, []);

  //FUNCTIONS
  const select_product = async (product) => {
    const data = await post("/product/metrics", {
      processId: product.processId,
      productId: product._id,
      startDate: page.state.body.startDate,
      endDate: page.state.body.endDate,
    });

    data.steps.forEach((step) => {
      step.highlight = false;
    });
    data.sku = "All Skus";
    data.skuId = "";
    page.set("body.selected", data);
  };

  //COMPONENT
  return (
    <>
      <div className="column soft shadow mB" style={{ minHeight: "100%" }}>
        <div className="bar">
          <h2>Products</h2>
        </div>
        <div className="grid-bar titleline">
          <DropDown
            title={
              customer?._id === undefined
                ? "Select Customer"
                : customer.profileName
            }
          >
            {page.state.stores.customers?.map((customer, index) => {
              return (
                <div
                  className="dropdown-option"
                  key={customer._id}
                  onClick={async () => {
                    page.set("body.products.customer", customer);
                    const data = await post("/customer/products", customer._id);
                    if (data) {
                      page.set("stores.products", data.data);
                    }
                  }}
                >
                  {customer.profileName}
                </div>
              );
            })}
          </DropDown>

          <div className="span-2 item pair">
            <h3 className="item">From: </h3>
            <input
              className="input-default"
              type="date"
              value={page.state.body.startDate}
              onChange={(e) => {
                page.set("body.startDate", e.target.value);
              }}
            />
          </div>
          <div className="span-2 item pair">
            <h3 className="item">To: </h3>
            <input
              className="input-default"
              type="date"
              value={page.state.body.endDate}
              onChange={(e) => {
                page.set("body.endDate", e.target.value);
              }}
            />
          </div>
        </div>
        <div className="column">
          {page.state.stores?.products?.map((product, index) => {
            const color = index % 2 ? "note" : "";
            return (
              <React.Fragment key={product._id}>
                {page.state.stores.products[index]._id ===
                page.state.body.selected.productId ? (
                  <ProductBlock page={page} />
                ) : (
                  <div
                    className={`bar ${color}`}
                    onClick={async (e) => {
                      await select_product(page.state.stores.products[index]);
                    }}
                  >
                    <b>{product.name}</b>
                  </div>
                )}
              </React.Fragment>
            );
          })}
        </div>
      </div>
      <div style={{ marginBottom: "200px" }}></div>
    </>
  );
}
export default ProductList;
