import { useEffect } from "react";
import useServer from "../../hooks/useServer";
import { useNavigate } from "react-router-dom";
import usePage from "../../hooks/usePage";
import format_order_table from "./format_order_table";
import { format_order_table_header } from "./format_order_table_header";
function useOrders() {
  const { get, post } = useServer();
  const navigate = useNavigate();

  const initialState = {
    filters: [],
    search: "",
    mode: "create",
    select: {},
    clear: false,

    modal: {
      open: false,
      msg: "",
      currentCustomer: {
        _id: "",
        addressBook: [],
        provider: {},
      },
      addressId: "",
      orderInformation: {
        lock: false,
        _id: "",
        orderNumber: "",
        batches: [],
        status: "",
      },
      general: {
        hidden: false,
        lock: false,
        firstName: "",
        lastName: "",
        company: "",
        website: "",
        phone: "",
        email: "",
      },
      shippingAddress: {
        hidden: false,
        lock: false,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
        residential: true,
      },
      billingAddress: {
        hidden: false,
        lock: false,
        address1: "",
        address2: "",
        address3: "",
        city: "",
        state: "",
        postalCode: "",
        country: "US",
      },
      orderItems: {
        lock: false,
        lines: [
          {
            providerItemId: "",
            itemProductId: "",
            product: "",
            name: "",
            sku: "",
            skus: [],
            quantity: "",
            unitPrice: "",
          },
        ],
      },
    },
    menu: {},
    body: {
      cueSidebar: {
        open: false,
        count: 0,
        batchName: "",
        batchDate: "",
        orderItems: [],
      },
      cueTable: {
        open: false,
        orderItems: [
          { orderNumber: "", clientName: "", sku: "", name: "", quantity: 0 },
        ],
      },
    },
    tableInfo: {
      localName: "",
      header: [],
      rows: [],
      columns: [],
    },

    stores: {
      orders: [],
      inventory: [],
      customers: [],
      products: [],
    },
  };

  const page = usePage(initialState);
  //Destructure the page object so the linter doesn't yell in the useEffects
  const { set, init, load, loading, isLoading } = page;

  // LOAD ORDERS
  useEffect(() => {
    async function effect() {
      load("stores.orders", {
        reload: async () => {
          return get("/orders/with/batches/and/skus");
        },
        callback: (data) => {
          init("stores.orders", data.orders);
          init("stores.inventory", data.skus);
        },
      });
    }
    effect();
  }, [get, init, load]);

  //ORDER TABLE
  useEffect(() => {
    function effect() {
      if (!isLoading["stores.orders"] || !isLoading["stores.customers"]) {
        format_order_table_header(set);
        format_order_table(page, set, navigate);
      }
    }
    effect();
  }, [
    page.state.filters,
    isLoading,
    navigate,
    page.state.stores.customers,
    page.state.stores.orders,
    set,
  ]);

  //LOAD CUSTOMER
  useEffect(() => {
    async function effect() {
      load("stores.customers", {
        reload: async () => {
          return get("/customer/list");
        },
        callback: (data) => {
          set("stores.customers", data);
        },
      });
    }
    effect();
  }, [get, set, load]);

  //LOAD PRODUCTS
  useEffect(() => {
    if (page.state.modal.currentCustomer._id.length !== 0) {
      async function effect() {
        load("stores.products", {
          reload: async () => {
            return post(
              "/customer/products",
              page.state.modal.currentCustomer._id
            );
          },
          callback: (data) => {
            set("stores.products", data.data);
          },
        });
      }
      effect();
    }
  }, [page.state.modal.currentCustomer, load, post, set]);

  //LOAD SKUS
  useEffect(() => {
    async function effect() {
      if (page.state.stores?.products?.length === 0) return;
      //we won't need to reload skus which is why this is different
      for (const [index, each] of page.state.modal.orderItems.lines.entries()) {
        if (each.productId === "") continue;
        loading(`modal.orderItems.lines.${index}.skus`);
        const skus = await post("/customer/skus", each.productId);
        if (skus) {
          loading(`modal.orderItems.lines.${index}.skus`);
          set(`modal.orderItems.lines.${index}.skus`, skus);
        }
      }
    }
    effect();
  }, [page.state.stores.products, loading, post, set]);

  return page;
}

export default useOrders;
