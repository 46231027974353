import useServer from "../../hooks/useServer";
import usePage from "../../hooks/usePage";
import { useEffect } from "react";
function useEmployee() {
  const { get } = useServer();

  //Page State
  const initialState = {
    modal: {
      _id: "",
      alert: { msg: "", type: "" },
      open: false,
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      phone: "",
      pay: "",
      role: "",
    },
    modal2: {
      alert: { msg: "", type: "" },
      open: false,
      firstName: "",
      lastName: "",
      batchId: "",
      batchName: "",
      customerId: "",
      batch: {},
      items: [],
      products: [],
    },
    menu: { alert: { msg: "", type: "" } },
    body: {},
    stores: {
      employees: [],
      batches: [],
    },
  };

  const page = usePage(initialState);
  const { load, init } = page;

  //LOAD EMPLOYEES
  useEffect(() => {
    async function effect() {
      load("stores.employees", {
        reload: async () => {
          return get("/employees/jobs");
        },
        callback: (response) => {
          response.map((employee) => {
            return { ...employee, info: false };
          });
          init("stores.employees", response);
        },
      });
    }
    effect();
  }, [init, load, get]);

  //LOAD BATCHES
  useEffect(() => {
    async function effect() {
      load("stores.batches", {
        reload: async () => {
          return get("/batches");
        },
        callback: (response) => {
          init("stores.batches", response);
        },
      });
    }
    effect();
  }, [init, load, get]);

  return page;
}
export default useEmployee;
