import Spinner from "../../../../icons/Spinner";
function AddressTable(props) {
  const { page } = props;

  const format = [
    "address1",
    "address2",
    "address3",
    "city",
    "state",
    "postalCode",
    "country",
  ];

  const render_shipping_address = (shippingAddress) => {
    let address = "";
    for (let [key, value] of Object.entries(shippingAddress)) {
      if (format.includes(key)) {
        if (key === "state") {
          address += `${value}, `;
        } else {
          address += `${value} `;
        }
      }
    }
    return address;
  };

  const on_load = (address) => {
    page.set("body.lock", true);
    page.set("body.selectedAddress", address);
  };

  return (
    <div className="soft">
      <div className="grid-bar list">
        <h3 className="half">Addresses</h3>
        <div className="half right">
          <button
            className="btn-primary item"
            onClick={(e) => {
              e.preventDefault();
              page.set("body.selectedAddress._id", "");
              page.set("body.selectedAddress.lock", false);
              page.reset("body.selectedAddress.shippingAddress");
              page.reset("body.selectedAddress.billingAddress");
            }}
          >
            New Address
          </button>
        </div>
      </div>
      {page.isLoading["stores.customer"] ? (
        <div className="column">
          <Spinner />
        </div>
      ) : (
        <div className="table-boss">
          <div className="address-table">
            <div className="grid-bar address-table-header">
              <h5 className="quarter cell">Company</h5>
              <h5 className="quarter cell">Contact</h5>
              <h5 className="half cell">Shipping</h5>
            </div>
            {page.state.body.addressBook.map((address) => {
              return (
                <div
                  key={address._id}
                  className="grid-bar row"
                  onClick={(e) => {
                    e.preventDefault();
                    on_load(address);
                  }}
                >
                  <div className="quarter cell">{address.company}</div>
                  <div className="quarter cell">{`${address.firstName} ${address.lastName}`}</div>

                  <div className="half cell">
                    {render_shipping_address(address.shippingAddress)}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
}
export default AddressTable;
