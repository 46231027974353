import TextInput from "./TextInput";
import Lock from "../icons/Lock";
import Unlock from "../icons/Unlock";
import CountryCodeDropDownCopy from "./CountryCodeDropDown";

function AddressBlock(props) {
  const {
    title,
    cardCSS,
    copyButton,
    address1,
    address2,
    address3,
    city,
    state,
    country,
    postalCode,
    lock,
    onLock,
  } = props;
  return (
    <>
      <div className="grid-bar titleline">
        <div className="span-10 pair">
          <h3 className="item">{title}</h3>
          <button
            className="btn-icon"
            onClick={(e) => {
              onLock(e);
            }}
          >
            {!lock ? <Unlock /> : <Lock fill="var(--text)" />}
          </button>
        </div>
        {copyButton.hidden ? (
          <></>
        ) : (
          <button
            className="btn-link span-2 right"
            onClick={copyButton.onClick}
          >
            {copyButton.text}
          </button>
        )}
      </div>
      <div className={cardCSS}>
        <label className=" grid-bar ">
          <div className="input-label third right">Address Line 1</div>
          <TextInput
            render={address1.render}
            value={address1.value}
            onChange={address1.onChange}
            css={address1.css}
            placeholder={address1?.placeholder ?? "Address Line 1"}
            required={address1?.required ?? false}
            error={address1.error}
            errorMsg={address1.errorMsg}
            errorFunc={address1.errorFunc}
            lock={lock}
          />
        </label>

        <label className=" grid-bar">
          <div className="input-label third right">Address Line 2</div>
          <TextInput
            render={address2.render}
            value={address2.value}
            onChange={address2.onChange}
            css={address2.css}
            placeholder={address2?.placeholder ?? "Address Line 2"}
            required={address2?.required ?? false}
            onError={address2?.onError}
            errors={address2.errors}
            lock={lock}
          />
        </label>

        <label className=" grid-bar">
          <div className="input-label third right">Address Line 3</div>
          <TextInput
            render={address3.render}
            value={address3.value}
            onChange={address3.onChange}
            css={address3.css}
            placeholder={address3?.placeholder ?? "Address Line 3"}
            required={address3?.required ?? false}
            onError={address3?.onError}
            errors={address3.errors}
            lock={lock}
          />
        </label>

        <label className="grid-bar">
          <div className="third input-label right">Location</div>
          <div className="two-thirds grid-bar gap">
            <TextInput
              render={city.render}
              value={city.value}
              onChange={city.onChange}
              css={city.css}
              placeholder="City"
              required={city?.required ?? false}
              error={city.error}
              errorMsg={city.errorMsg}
              errorFunc={city.errorFunc}
              lock={lock}
            />
            <TextInput
              render={state.render}
              value={state.value}
              onChange={state.onChange}
              css={state.css}
              placeholder="State"
              required={state?.required ?? false}
              error={state.error}
              errorMsg={state.errorMsg}
              errorFunc={state.errorFunc}
              lock={lock}
            />
          </div>
        </label>

        <label className="grid-bar">
          <div className="third input-label right">Country</div>
          <CountryCodeDropDownCopy
            current={country.value === "" ? "US" : country.value}
            css={country.css}
            required={country?.required ?? false}
            onChange={country.onChange}
            lock={lock}
          />
        </label>

        <label className="grid-bar">
          <div className="third input-label right">Postal Code</div>
          <TextInput
            render={postalCode.render}
            value={postalCode.value}
            onChange={postalCode.onChange}
            css={postalCode.css}
            placeholder="Postal Code"
            required={postalCode?.required ?? false}
            error={postalCode.error}
            errorMsg={postalCode.errorMsg}
            errorFunc={postalCode.errorFunc}
            lock={lock}
          />
        </label>
      </div>
    </>
  );
}
export default AddressBlock;
