import { useCallback, useReducer, useState } from "react";
import generate_lookup_table from "./generate_lookup_table";

function usePage(initialState) {
  //ANCILLARY PROPERTIES TABLES
  let clone = JSON.parse(JSON.stringify(initialState));
  const initialLoad = generate_lookup_table(clone, false);
  const initialError = generate_lookup_table(clone, false);
  const initialErrorMessages = generate_lookup_table(clone, "error");

  const select = (state, root, obj) => {
    let clone = { ...state };
    let start = traverse(clone, root);
    const search = (data) => {
      if (data === null || data === undefined) return;
      if (typeof data === "object") {
        if (Array.isArray(data)) {
          data.forEach((el, index) => {
            if (typeof el === "object" && !Array.isArray(el)) {
              search(el);
            }
          });
        } else {
          for (const [key, value] of Object.entries(data)) {
            for (const [k, v] of Object.entries(obj)) {
              if (key === k) {
                data[key] = v;
                // delete obj[k];
              }
            }

            if (typeof value === "object") {
              search(value);
            }
          }
        }
      }
    };
    search(start);
    return start;
  };

  //follows the string path to the end,
  //and constructs the object along the way,
  //returns the object with modified value at the end of the path
  const recurse = (prop, path, payload) => {
    let clone = Array.isArray(prop) ? [...prop] : { ...prop };
    let pathArr = path.split(".");
    let key = pathArr.shift();

    if (pathArr.length === 0) {
      clone[key] = payload;
    } else {
      if (/\d+/.test(key)) {
        key = parseInt(key);
      }
      clone[key] = clone[key] || {};
      clone[key] = recurse(clone[key], pathArr.join("."), payload);
    }
    return clone;
  };

  //returns the value at the end of the path
  const traverse = (obj, path) => {
    let clone = { ...obj };
    const route = path.split(".");
    for (let i = 0; i < route.length; i++) {
      if (clone[route[i]] === undefined) return undefined;
      clone = clone[route[i]];
    }
    return clone;
  };

  //returns the state with the updated value at the end of the path
  const target = (state, path, value) => {
    if (path === undefined) {
      return value;
    }
    const route = path.split(".");
    const root = route[0];
    const loc = route.slice(1).join(".");
    if (route.length === 1) {
      return { ...state, [root]: value };
    }
    let update = recurse(state[root], loc, value);
    return { ...state, [root]: update };
  };

  //seperate here to allow for useCallback to work
  const type = (state, type, path, value, key) => {
    if (type === "set") {
      return value;
    }

    if (type === "func") {
      return value;
    }

    if (type === "set_by_key") {
      let clone = { ...state[path] };
      clone[key] = value;
      return clone;
    }

    if (type === "checkError") {
      let clone = { ...state };
      let func = { ...state.errorFunctions };
      let errors = { ...state.errors };
      let target = traverse(clone, key);
      let result = func[key](target);
      errors[key] = result;
      return errors;
    }

    if (type === "selectiveSet") {
      let clone = JSON.parse(JSON.stringify(state));
      let result = select(clone, path, value);
      return result;
    }

    if (type === "reset") {
      if (path === undefined) {
        return initial;
      } else {
        let clone = { ...initial };
        let target = traverse(clone, path);
        return target;
      }
    }

    if (type === "toggle") {
      let target = !traverse(state, path);
      return target;
    }

    if (type === "add") {
      let clone = { ...state };
      let target = traverse(clone, path);
      return [...target, value];
    }

    if (type === "remove") {
      let clone = { ...state };
      let target = traverse(clone, path);
      let final = target.filter((obj, i) => {
        return i !== parseInt(key);
      });
      return final;
    }
  };

  //REDUCER
  const reducer = (state, action) => {
    let clone = { ...state };
    //functional set
    if (typeof action.value === "function" && action.type === "set") {
      let clone = { ...state };
      let current = traverse(clone, action.path);
      action.value = action.value(current);
    }

    let update = type(
      state,
      action.type,
      action.path,
      action.value,
      action.key
    );

    if (
      state.errorFunctions[action.path] !== undefined &&
      action.value !== undefined
    ) {
      let value;
      action.type === "remove" ? (value = update) : (value = action.value);
      clone.errors[action.path] = state.errorFunctions[action.path](value);
    }

    return target({ ...clone }, action.path, update);
  };

  //this is just to get init and reset to work
  const initialReducer = (state, action) => {
    return target({ ...state }, action.path, action.value);
  };

  //FUNCTION STATE
  const [crudFunctions, setCRUDFunctions] = useState({});
  const [crudCallback, setCRUDcallback] = useState({});

  initialState.errors = initialError;
  initialState.errorMessages = initialErrorMessages;
  initialState.errorFunctions = {};

  //DISPATCH
  const [initial, initDispatch] = useReducer(initialReducer, initialState);
  const [state, dispatch] = useReducer(reducer, initialState);

  const [isLoading, setIsLoading] = useState(initialLoad);

  //ACTIONS
  const set = useCallback((path, value) => {
    dispatch({ type: "set", path: path, value: value });
  }, []);

  const func = useCallback((path, value) => {
    dispatch({ type: "func", path: path, value: value });
  }, []);

  const set_by_key = useCallback((path, key, value) => {
    dispatch({ type: "set_by_key", path: path, key: key, value: value });
  }, []);

  const init = useCallback((path, value) => {
    initDispatch({ type: "set", path: path, value: value });
    dispatch({ type: "set", path: path, value: value });
  }, []);

  const toggle = useCallback((path) => {
    dispatch({ type: "toggle", path: path });
  }, []);

  const add = useCallback((path, value) => {
    dispatch({ type: "add", path: path, value: value });
  }, []);

  const remove = useCallback((path, index) => {
    dispatch({ type: "remove", path: path, key: index, value: [] });
  }, []);

  const load = useCallback(async (path, object) => {
    if (object === undefined) {
      setCRUDFunctions((prev) => ({ ...prev, [path]: () => {} }));
      setCRUDcallback((prev) => ({ ...prev, [path]: () => {} }));
      return;
    }
    if (crudFunctions[path] === undefined) {
      loading(path);
      const data = await object.reload();
      if (data) {
        loading(path);
        object.callback(data);
      }
    }
    setCRUDFunctions((prev) => ({ ...prev, [path]: object.reload }));
    setCRUDcallback((prev) => ({ ...prev, [path]: object.callback }));
  }, []);

  const loading = useCallback((path) => {
    setIsLoading((prev) => ({ ...prev, [path]: !prev[path] }));
  }, []);

  const error = useCallback((key, func, value) => {
    if (func === undefined) {
      dispatch({
        type: "set_by_key",
        path: "errorFunctions",
        key: key,
        value: () => false,
      });
      return;
    }
    dispatch({
      type: "set_by_key",
      path: "errorFunctions",
      key: key,
      value: func,
    });
    if (value !== undefined) {
      dispatch({
        type: "set_by_key",
        path: "errorMessages",
        key: key,
        value: value,
      });
    }
  }, []);

  const checkError = useCallback((key) => {
    dispatch({ type: "checkError", path: "errors", key: key });
  }, []);

  const validate = useCallback(() => {
    let fail = false;
    Object.keys(state.errorFunctions).forEach((key) => {
      let value = traverse(state, key);
      if (value === undefined) {
        return fail;
      }
      if (state.errorFunctions[key](value)) {
        fail = true;
        dispatch({ type: "checkError", path: "errors", key: key });
      }
    });
    return fail;
  });

  const reload = useCallback(
    async (path) => {
      if (path === undefined) {
        console.log(crudFunctions);
        return;
      }
      let response = await crudFunctions[path]();
      loading(path);
      if (response) {
        loading(path);
        crudCallback[path](response);
      }
    },
    [crudFunctions, crudCallback]
  );

  const reset = useCallback((path) => {
    dispatch({ type: "reset", path: path });
  }, []);

  return {
    set,
    set_by_key,
    func,
    isLoading,
    loading,
    setIsLoading,
    init,
    toggle,
    add,
    remove,
    error,
    checkError,
    validate,
    reset,
    load,
    reload,
    state,
    initial,
  };
}

export default usePage;
