import PST_age from "../../utils/PST_age";
import date_trim from "../../utils/date_trim";

import order_format_modal from "./order_format_modal";

const display_item_totals = (batched, allocated, total) => {
  let batchedTotal = 0;
  let allocatedTotal = 0;
  batched.forEach((each) => {
    batchedTotal += each.b;
  });
  allocated.forEach((each) => {
    allocatedTotal += each.a;
  });

  let required = 0;
  let fulfilled = 0;

  if (allocatedTotal === total) {
    required = total;
    fulfilled = total;
  } else {
    required = total - allocatedTotal;
  }

  if (allocatedTotal < total) fulfilled = batchedTotal;

  return `${fulfilled}/${required}`;
};

const item_total_sets = (each) => {
  let batched = [];
  let allocated = [];
  let total = 0;
  each.orderItems.forEach((item) => {
    total += item.quantity;
    allocated.push({ a: item.allocate, t: item.quantity });
    if (item.batch.length === 0) {
      batched.push({ b: 0, t: item.quantity - item.allocate });
    }
    item.batch.forEach((batch) => {
      batched.push({ b: batch.quantity, t: item.quantity - item.allocate });
    });
  });
  return { batched, allocated, total };
};

const format_order_table = (page, set, navigate) => {
  const filterOrders = (orders, filters) => {
    if (filters.length === 0) return orders;
    const filtered = [];
    let chronoOrder = "newest";
    orders.forEach((each) => {
      let filterCounter = 0;
      filters.forEach((filter) => {
        const re = new RegExp(filter.id, "gi");
        if (filter.type === "store" && each.customerId === filter.id)
          filterCounter++;
        if (filter.type === "search" && each.orderNumber.match(re))
          filterCounter++;

        if (filter.type === "status" && each.status === filter.id)
          filterCounter++;
        if (
          filter.type === "batch" &&
          each.batches?.find((batch) => batch.batchName === filter.id)
        ) {
          filterCounter++;
        }
        if (filter.type === "date") {
          chronoOrder = filter.id;
          filterCounter++;
        }
      });
      if (filterCounter === filters.length) filtered.push(each);
    });

    if (chronoOrder === "newest") {
      sortByNewest(filtered);
    }
    if (chronoOrder === "oldest") {
      sortByOldest(filtered);
    }

    return filtered;
  };

  const sortByOldest = (orders) => {
    orders.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateA - dateB;
    });
  };

  const sortByNewest = (orders) => {
    orders.sort((a, b) => {
      const dateA = new Date(a.createdAt);
      const dateB = new Date(b.createdAt);
      return dateB - dateA;
    });
  };

  const match_batch = (each) => {
    const batches = [];
    each.orderItems.forEach((item) => {
      item.batch.forEach((batchItem) => {
        batches.push(batchItem.batchName);
      });
    });

    return batches;
  };

  const display_item_color = (batched, allocated) => {
    let fullyBatched = true;
    let fullyAllocated = true;

    batched.forEach((each) => {
      if (each.b < each.t) fullyBatched = false;
    });
    allocated.forEach((each) => {
      if (each.a < each.t) fullyAllocated = false;
    });

    if (fullyAllocated) return "table-cell-allocated";
    if (fullyBatched) return "table-cell-primary";
    return "table-item";
  };

  const customer_lookup = (each) => {
    const customer = page.state.stores.customers.find(
      (customer) => customer._id === each.customerId
    );
    return customer;
  };

  const rows = filterOrders(page.state.stores.orders, page.state.filters).map(
    (each) => {
      const batches = match_batch(each);
      const { batched, allocated, total } = item_total_sets(each);
      return {
        rowId: each._id,
        cells: [
          //status
          {
            data: `${each.status}`,
            action: () => {},
          },
          //batches
          {
            data: [...new Set(batches)].toString().replace(/,/g, ", "),
            action: () => {},
            css: batches?.some((batch) => batch === "Batching...")
              ? "table-cell-primary"
              : "table-item click",
          },
          //batched items
          {
            data: display_item_totals(batched, allocated, total),

            action: () => {},
            css: display_item_color(batched, allocated),
          },
          //order number
          {
            data: each.orderNumber,
            css: "table-cell-link click",
            action: (page) => {
              if (page.state.mode === "create") {
                order_format_modal(each, page);
              } else {
                page.set("body.cueTable._id", each._id);
                page.set("body.cueTable.open", true);
              }
            },
          },
          //age
          { data: PST_age(each.createdAt), action: () => {} },
          //order date
          { data: date_trim(each.createdAt), action: () => {} },
          // customer
          { data: customer_lookup(each)?.profileName, action: () => {} },
        ],
      };
    }
  );
  set("tableInfo.rows", rows);
};
export { display_item_totals, item_total_sets, format_order_table };
export default format_order_table;
