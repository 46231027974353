import useServer from "../../hooks/useServer";
import usePage from "../../hooks/usePage";
import { useEffect } from "react";

function useMetrics() {
  const { get } = useServer();

  const initialState = {
    menu: {
      employee: {},
    },
    body: {
      startDate: "",
      endDate: "",
      empOne: { _id: "", firstName: "", lastName: "", product: { _id: "" } },
      empTwo: { _id: "", firstName: "", lastName: "", product: { _id: "" } },
      products: {
        customer: {},
      },
      selected: { skuId: "", sku: "" },
    },
    stores: {
      employees: [],
      products: [],
      customers: [],
    },
  };

  const page = usePage(initialState);
  const { set, load, init } = page;

  //LOAD EMPLOYEES
  useEffect(() => {
    async function effect() {
      load("stores.employees", {
        reload: async () => {
          return get("/employees");
        },
        callback: (response) => {
          if (response.length > 0) {
            response.map((employee) => {
              return { ...employee, info: false };
            });
            init("stores.employees", response);
          }
        },
      });
    }
    effect();
  }, [init, load, get]);

  //LOAD CUSTOMERS
  useEffect(() => {
    async function effect() {
      load("stores.customers", {
        reload: async () => {
          return get("/customer/list");
        },
        callback: (response) => {
          if (response.length > 0) {
            init("stores.customers", response);
          }
        },
      });
    }
    effect();
  }, [load, get, set, init]);

  return page;
}
export default useMetrics;
