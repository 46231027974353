function TabSelect(props) {
  const { page } = props;
  return (
    <div className="grid-bar smooth list">
      <h4
        className={`quarter tab ${
          page.state.body.tab === "address" ? "selected" : ""
        }`}
        onClick={() => {
          page.reset("body.selectedProcess");
          page.reset("body.selectedProduct");
          page.set("body.tab", "address");
          page.set("body.mode", "idle");
        }}
      >
        Address Book
      </h4>
      <h4
        className={`quarter tab ${
          page.state.body.tab === "process" ? "selected" : ""
        }`}
        onClick={() => {
          page.reset("body.selectedProduct");
          page.set("body.tab", "process");
          page.set("body.mode", "idle");
        }}
      >
        Proccess
      </h4>
      <h4
        className={`quarter tab ${
          page.state.body.tab === "products" ? "selected" : ""
        }`}
        onClick={() => {
          page.reset("body.selectedProcess");
          page.set("body.tab", "products");
          page.set("body.mode", "idle");
        }}
      >
        Products
      </h4>
      <h4
        className={`quarter tab ${
          page.state.body.tab === "invoice" ? "selected" : ""
        }`}
        onClick={() => {
          page.reset("body.selectedProcess");
          page.reset("body.selectedProduct");
          page.set("body.tab", "invoice");
        }}
      >
        Invoices
      </h4>
    </div>
  );
}
export default TabSelect;
