import ProductHeader from "./ProductHeader";
import ProductSku from "./ProductSku";
import is_overflown from "../../../../utils/is_overflown";
import TextInput from "../../../../components/TextInput";
import ProductComponent from "./ProductComponent";
import useDrag from "../../../../hooks/useDrag";
import useServer from "../../../../hooks/useServer";
import format_product_payload from "../../format_product_payload";
import DropDown from "../../../../components/DropDown";
import ProductParts from "./ProductParts";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
import ErrorBox from "../../../../components/ErrorBox";
import { useRef, useEffect } from "react";

function Product(props) {
  const { page } = props;
  const drag = useDrag(page);
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const ref = useRef(null);
  const process = page.state.stores.process?.find(
    (proc) => proc._id === page.state.body.selectedProduct?.processId
  );

  const lock = page.state.body.mode === "edit" ? true : false;

  useEffect(() => {
    let direction = "bottom";
    if (page.state.body.selectedProduct.components.length <= 1) {
      direction = "top";
    }

    is_overflown(ref.current, direction);
  }, [page.state.body.selectedProduct.components.length]);

  const on_submit = async (e) => {
    e.preventDefault();
    if (page.validate()) return;
    const lock = page.state.body.selectedProduct.components.map((component) => {
      return {
        ...component,
        options: component.options.map((option) => {
          return {
            ...option,
            edit: false,
          };
        }),
      };
    });
    page.set("body.selectedProduct.components", lock);
    const payload = format_product_payload(page);
    const data = await post("/customer/upsert/product", payload);
    if (data) {
      setAlert({ type: data.type, msg: data.msg });
      page.set("body.mode", "idle");
      page.reset("body.selectedProduct");
      page.reload("stores.products");
    }
  };

  const accept_archive = async () => {
    const data = await post("/customer/archive/product", {
      _id: page.state.body.selectedProduct._id,
    });
    if (data) {
      setAlert({ type: data.type, msg: data.msg });
      page.set("alert.open", false);
      page.set("body.mode", "idle");
      page.reset("body.selectedProduct");
      page.reload("stores.products");
    }
  };

  return (
    <>
      {page.state.menu.selected === "" ? (
        <h4>Load Customer</h4>
      ) : (
        <form
          ref={ref}
          className="card-three-quarters soft"
          onSubmit={on_submit}
        >
          <ProductHeader page={page} />
          {page.state.body.mode !== "idle" ? (
            <div>
              <label className="grid-bar">
                <div className="input-label quarter right">Product Name:</div>
                <div className="half">
                  <TextInput
                    value={page.state.body.selectedProduct.name}
                    touch={false}
                    onChange={(e) => {
                      page.set("body.selectedProduct.name", e.target.value);
                    }}
                    error={page.state.errors["body.selectedProduct.name"]}
                    errorMsg="Product name required"
                    errorFunc={(mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedProduct.name",
                          (reason) => reason.length === 0
                        );
                      } else {
                        page.error("body.selectedProduct.name");
                      }
                    }}
                    css=""
                    placeholder="Product Name"
                    required={true}
                    lock={lock}
                    maxLength="50"
                  />
                </div>
              </label>
              <label className="grid-bar">
                <div className="input-label quarter right">Description:</div>
                <div className="half">
                  <TextInput
                    value={page.state.body.selectedProduct.desc}
                    onChange={(e) => {
                      page.set("body.selectedProduct.desc", e.target.value);
                    }}
                    css=""
                    placeholder="Brief Description"
                    required={false}
                    lock={false}
                    maxLength="150"
                  />
                </div>
              </label>
              <label className="grid-bar">
                <div className="input-label quarter right">Process:</div>
                <div className="half">
                  <DropDown
                    title={!process?.name ? "Choose Process" : process.name}
                    value={process?._id}
                    touch={false}
                    required={true}
                    style={{ width: "100%" }}
                    error={page.state.errors["body.selectedProduct.processId"]}
                    errorMsg="Process required"
                    errorFunc={(mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedProduct.processId",
                          (reason) => reason === null
                        );
                      } else {
                        page.error("body.selectedProduct.processId");
                      }
                    }}
                    lock={lock}
                  >
                    {page.state.stores.process.map((each) => {
                      return (
                        <div
                          className="dropdown-option"
                          onClick={() => {
                            page.set(
                              "body.selectedProduct.processId",
                              each._id
                            );
                          }}
                          key={each._id}
                          value={each._id}
                          data-name={each.name}
                        >
                          {each.name}
                        </div>
                      );
                    })}
                  </DropDown>
                </div>
              </label>

              <div className="grid-bar ">
                <div className="input-label single s-6 right">Cancel</div>
                <div className="single right">
                  <button
                    className="btn-default"
                    onClick={(e) => {
                      e.preventDefault();
                      page.set("body.mode", "idle");
                      page.reset("body.selectedProduct");
                    }}
                  >
                    Cancel
                  </button>
                </div>
                <div className="input-label single right">Save</div>
                <div className="single right">
                  <button className="btn-primary" type="submit">
                    Save
                  </button>
                </div>
                <div className="input-label single right">Archive</div>
                <div className="single right">
                  <button
                    className="btn-default"
                    onClick={(e) => {
                      e.preventDefault();

                      page.set("alert.open", true);
                      page.set("alert.title", "Archive Product");
                      page.set(
                        "alert.msg",
                        `Are you sure you want to archive "${page.state.body.selectedProduct.name}"? This cannot be undone`
                      );
                      page.func("alert.accept", accept_archive);
                    }}
                  >
                    Archive
                  </button>
                </div>
              </div>

              <ProductParts page={page} />
              <ProductSku page={page} lock={lock} />
              <div className="card list">
                <div className="grid-bar">
                  <ErrorBox
                    isError={
                      page.state.errors["body.selectedProduct.components"]
                    }
                    errorMsg="Product requires one component"
                    errorFunc={(mount) => {
                      if (mount) {
                        page.error(
                          "body.selectedProduct.components",
                          (reason) => reason.length === 0
                        );
                      } else {
                        page.error("body.selectedProduct.components");
                      }
                    }}
                  ></ErrorBox>
                </div>
                {page.state.body.selectedProduct.components.map(
                  (component, componentIndex) => {
                    return (
                      <ProductComponent
                        key={componentIndex}
                        component={component}
                        componentIndex={componentIndex}
                        page={page}
                        drag={drag}
                        lock={lock}
                      />
                    );
                  }
                )}
              </div>
              <button
                style={lock ? { display: "none" } : { display: "block" }}
                className="btn-default list"
                onClick={(e) => {
                  e.preventDefault();
                  page.add("body.selectedProduct.components", {
                    name: "",
                    note: "",
                    search: "",
                    options: [
                      {
                        name: "",
                        edit: true,
                        show: true,
                        requirements: [
                          {
                            _id: "",
                            name: "",
                            amount: 0,
                            units: "",
                          },
                        ],
                      },
                    ],
                  });
                }}
              >
                + Sku Component
              </button>
            </div>
          ) : (
            <></>
          )}

          <div style={{ marginBottom: "200px" }}></div>
        </form>
      )}
    </>
  );
}
export default Product;
