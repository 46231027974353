function Ellipse(css = "") {
  return (
    <div className={`lds-ellipse ${css}`}>
      <div></div>
      <div></div>
      <div></div>
      <div></div>
    </div>
  );
}

export default Ellipse;
