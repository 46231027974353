import TextInput from "../../../../components/TextInput";

function AddressBookHeader(props) {
  const { page } = props;

  const reset = page.state.body.reset;
  const selectedAddress = page.state.body.selectedAddress;

  return (
    <div className="soft">
      <div className="grid-bar titleline note heading">
        <h3 className="half ">Information</h3>
      </div>
      <div className="grid-bar">
        <label className="grid-bar list third">
          <div className="input-label half right">Company:</div>
          <TextInput
            value={selectedAddress.company}
            onChange={(e) => {
              page.set("body.selectedAddress.company", e.target.value);
            }}
            error={page.state.errors["body.selectedAddress.company"]}
            errorMsg="Company is required"
            errorFunc={(mount) => {
              if (mount) {
                page.error(
                  "body.selectedAddress.company",
                  (reason) => reason.length === 0
                );
              } else {
                page.error("body.selectedAddress.company");
              }
            }}
            css="half"
            placeholder="Company"
            required={true}
            lock={selectedAddress.lock}
            reset={reset}
          />
        </label>
        <label className="grid-bar list third">
          <div className="input-label half right">Contact:</div>
          <TextInput
            value={selectedAddress.firstName}
            onChange={(e) => {
              page.set("body.selectedAddress.firstName", e.target.value);
            }}
            error={page.state.errors["body.selectedAddress.firstName"]}
            errorMsg="First name is required"
            errorFunc={(mount) => {
              if (mount) {
                page.error(
                  "body.selectedAddress.firstName",
                  (reason) => reason.length === 0
                );
              } else {
                page.error("body.selectedAddress.firstName");
              }
            }}
            css="quarter right"
            placeholder="Company"
            required={true}
            lock={selectedAddress.lock}
          />
          <TextInput
            value={selectedAddress.lastName}
            onChange={(e) => {
              page.set("body.selectedAddress.lastName", e.target.value);
            }}
            css="quarter"
            placeholder="Company"
            required={true}
            error={page.state.errors["body.selectedAddress.lastName"]}
            errorMsg="Last name is required"
            errorFunc={(mount) => {
              if (mount) {
                page.error(
                  "body.selectedAddress.lastName",
                  (reason) => reason.length === 0
                );
              } else {
                page.error("body.selectedAddress.lastName");
              }
            }}
            lock={selectedAddress.lock}
          />
        </label>

        <div className="grid-bar third input-spacing">
          <div className="half right">
            <button
              className="btn-link half"
              onClick={(e) => {
                e.preventDefault();
                page.toggle("body.selectedAddress.lock");
              }}
            >
              {selectedAddress.lock === true ? "Edit" : "Lock"}
            </button>
          </div>
          <div className="half right">
            <button
              className={`btn-primary half ${
                selectedAddress.lock === true ? "btn-disabled" : ""
              }`}
              disabled={selectedAddress.lock}
              type="submit"
            >
              Save
            </button>
          </div>
        </div>
      </div>
      <div className="grid-bar">
        <label className="grid-bar list third">
          <div className="input-label half right">Email:</div>
          <TextInput
            value={selectedAddress.email}
            onChange={(e) => {
              page.set("body.selectedAddress.email", e.target.value);
            }}
            css="half"
            placeholder="Email"
            lock={selectedAddress.lock}
          />
        </label>
        <label className="grid-bar list third">
          <div className="input-label half right">Website:</div>
          <TextInput
            value={selectedAddress.website}
            onChange={(e) => {
              page.set("body.selectedAddress.website", e.target.value);
            }}
            css="half"
            placeholder="website"
            lock={selectedAddress.lock}
          />
        </label>
        <label className="grid-bar list third">
          <div className="input-label half right">Phone:</div>
          <TextInput
            value={selectedAddress.phone}
            onChange={(e) => {
              page.set("body.selectedAddress.phone", e.target.value);
            }}
            css="half"
            placeholder="phone"
            lock={selectedAddress.lock}
          />
        </label>
      </div>
    </div>
  );
}

export default AddressBookHeader;
