function Menu(props) {
  const { page } = props;

  return (
    <div className="bar shadow soft split">
      <div className="card">
        <h1>Employees</h1>
      </div>
      <div className="bar">
        <button
          className="btn-primary"
          onClick={() => {
            page.set("modal.open", true);
          }}
        >
          New Employee
        </button>
      </div>
    </div>
  );
}
export default Menu;
