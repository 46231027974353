import General from "../../components/General";
import AddressBlock from "../../components/AddressBlock";
import Provider from "./customerNew/Provider";
import useServer from "../../hooks/useServer";
import format_new_customer_payload from "./format_new_customer_payload";
import TextInput from "../../components/TextInput";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
function CustomerNew(props) {
  const { page } = props;
  const shippingAddress = page.state.modal.shippingAddress;
  const billingAddress = page.state.modal.billingAddress;
  const general = page.state.modal.general;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  return (
    <form
      method="post"
      onSubmit={async (e) => {
        e.preventDefault();
        if (page.validate()) return;

        const payload = format_new_customer_payload(page.state.modal);
        const response = await post("/customer/create", payload);
        if (response) {
          page.reset("modal");
          page.reload("stores.customers");
          setAlert({ type: "success", msg: "Customer Created" });
        }
      }}
    >
      <div className="card-two-thirds">
        <div className="grid-bar">
          <h2 className="third input-label right">Customer Profile Name</h2>
          <TextInput
            required={true}
            css="third"
            value={page.state.modal.profileName}
            onChange={(e) => {
              page.set("modal.profileName", e.target.value);
            }}
          />
        </div>
      </div>

      <General
        lock={general.lock}
        onLock={(e) => {
          e.preventDefault();
          page.toggle("modal.general.lock");
        }}
        title="General"
        company={{
          value: general.company,
          required: false,
          render: general.render,
          onChange: (e) => {
            page.set("modal.general.company", e.target.value);
          },
          css: "two-thirds",
          placeholder: "Company Name",
        }}
        firstName={{
          value: general.firstName,
          required: true,
          render: general.render,
          onChange: (e) => {
            page.set("modal.general.firstName", e.target.value);
          },
          css: "half",
          error: page.state.errors["modal.general.firstName"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.general.firstName", (reason) => {
                return reason.length === 0;
              });
            }
          },
        }}
        lastName={{
          value: general.lastName,
          required: true,
          render: general.render,
          onChange: (e) => {
            page.set("modal.general.lastName", e.target.value);
          },
          css: "half",
          error: page.state.errors["modal.general.lastName"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.general.lastName", (reason) => {
                return reason.length === 0;
              });
            }
          },
        }}
        website={{
          value: general.website,
          required: false,
          render: general.render,
          onChange: (e) => {
            page.set("modal.general.website", e.target.value);
          },
          css: "two-thirds",
        }}
        email={{
          value: general.email,
          required: false,
          render: general.render,
          onChange: (e) => {
            page.set("modal.general.email", e.target.value);
          },
          css: "two-thirds",
        }}
        phone={{
          value: general.phone,
          required: false,
          render: general.render,
          onChange: (e) => {
            page.set("modal.general.phone", e.target.value);
          },
          css: "two-thirds",
        }}
      />
      <AddressBlock
        title="Shipping Address"
        lock={shippingAddress.lock}
        onLock={(e) => {
          e.preventDefault();
          page.toggle("modal.shippingAddress.lock");
        }}
        cardCSS="card-two-thirds"
        copyButton={{
          text: "",
          onClick: () => {},
          hidden: false,
        }}
        address1={{
          value: shippingAddress.address1,
          onChange: (e) => {
            page.set("modal.shippingAddress.address1", e.target.value);
          },
          error: page.state.errors["modal.shippingAddress.address1"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.shippingAddress.address1", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "two-thirds",
          placeholder: "Address Line 1",
          required: true,
        }}
        address2={{
          value: shippingAddress.address2,
          onChange: (e) => {
            page.set("modal.shippingAddress.address2", e.target.value);
          },
          css: "two-thirds",
          placeholder: "Address Line 2",
          required: false,
          error: "Address Line 2 is required",
        }}
        address3={{
          value: shippingAddress.address3,
          onChange: (e) => {
            page.set("modal.shippingAddress.address3", e.target.value);
          },
          css: "two-thirds",
          placeholder: "Address Line 3",
          required: false,
          error: "Address Line 3 is required",
        }}
        city={{
          value: shippingAddress.city,

          onChange: (e) => {
            page.set("modal.shippingAddress.city", e.target.value);
          },
          error: page.state.errors["modal.shippingAddress.city"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.shippingAddress.city", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "half",
          placeholder: "City",
          required: true,
        }}
        state={{
          value: shippingAddress.state,

          onChange: (e) => {
            page.set("modal.shippingAddress.state", e.target.value);
          },
          error: page.state.errors["modal.shippingAddress.state"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.shippingAddress.state", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "half",
          placeholder: "State",
          required: true,
        }}
        country={{
          value: shippingAddress.country,
          onChange: (e) => {
            page.set("modal.shippingAddress.country", e.target.value);
          },
          css: "two-thirds",
          placeholder: "country",
          required: true,
          error: "country is required",
        }}
        postalCode={{
          value: shippingAddress.postalCode,
          onChange: (e) => {
            page.set("modal.shippingAddress.postalCode", e.target.value);
          },
          error: page.state.errors["modal.shippingAddress.postalCode"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.shippingAddress.postalCode", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "two-thirds",
          placeholder: "Postal Code",
          required: true,
        }}
      />
      <AddressBlock
        title="Billing Address"
        lock={billingAddress.lock}
        onLock={(e) => {
          e.preventDefault();
          page.toggle("modal.billingAddress.lock");
        }}
        cardCSS="card-two-thirds"
        copyButton={{
          text: "Same as Primary?",
          onClick: (e) => {
            e.preventDefault();
            page.set("modal.billingAddress", shippingAddress);
          },
          hidden: false,
        }}
        address1={{
          value: billingAddress.address1,

          onChange: (e) => {
            page.set("modal.billingAddress.address1", e.target.value);
          },
          error: page.state.errors["modal.billingAddress.address1"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.billingAddress.address1", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "two-thirds",
          placeholder: "Address Line 1",
          required: true,
        }}
        address2={{
          value: billingAddress.address2,
          onChange: (e) => {
            page.set("modal.billingAddress.address2", e.target.value);
          },
          css: "two-thirds",
          placeholder: "Address Line 2",
          required: false,
        }}
        address3={{
          value: billingAddress.address3,
          onChange: (e) => {
            page.set("modal.billingAddress.address3", e.target.value);
          },
          css: "two-thirds",
          placeholder: "Address Line 3",
          required: false,
        }}
        city={{
          value: billingAddress.city,
          onChange: (e) => {
            page.set("modal.billingAddress.city", e.target.value);
          },
          error: page.state.errors["modal.billingAddress.city"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.billingAddress.city", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "half",
          placeholder: "City",
          required: true,
        }}
        state={{
          value: billingAddress.state,

          onChange: (e) => {
            page.set("modal.billingAddress.state", e.target.value);
          },
          error: page.state.errors["modal.billingAddress.state"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.billingAddress.state", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "half",
          placeholder: "State",
          required: true,
        }}
        country={{
          value: billingAddress.country,
          onChange: (e) => {
            page.set("modal.billingAddress.country", e.target.value);
          },
          css: "two-thirds",
          placeholder: "country",
          required: true,
          error: "country is required",
        }}
        postalCode={{
          value: billingAddress.postalCode,

          onChange: (e) => {
            page.set("modal.billingAddress.postalCode", e.target.value);
          },
          error: page.state.errors["modal.billingAddress.postalCode"],
          errorFunc: (mount) => {
            if (mount) {
              page.error("modal.billingAddress.postalCode", (reason) => {
                return reason.length === 0;
              });
            }
          },
          css: "two-thirds",
          placeholder: "Postal Code",
          required: true,
        }}
      />
      <Provider page={page} />
      <h3 className="titleline list-item">Create</h3>
      <div className="column list">
        <button className="btn-primary" type="submit">
          Create Customer
        </button>
      </div>
    </form>
  );
}
export default CustomerNew;
