import { Link, useNavigate, Outlet } from "react-router-dom";
import { UserContext } from "../../context/User/UserProvider";
import { useContext } from "react";
import axios from "../../utils/axios";
import AlertArea from "./AlertArea";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";

function HeaderAdmin() {
  const { auth, setAuth } = useContext(UserContext);
  const navigate = useNavigate();
  const { setAlert } = useGlobalAlert();
  const logOutUser = async () => {
    try {
      const data = await axios.get("user/logout");
      return data;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <>
      <header>
        <div className="nav">
          <div className="card">
            <div className="logo">
              <Link
                to="/"
                onClick={() => {
                  setAlert({
                    type: "",
                    msg: "",
                  });
                }}
              >
                Home
              </Link>
            </div>
            <ul>
              {auth.accessToken !== "" ? (
                <>
                  <li>
                    <Link
                      to="/orders"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Orders
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/batches"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Batches
                    </Link>
                  </li>

                  <li>
                    <Link
                      to="/customers"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Customers
                    </Link>
                  </li>
                  {/* <li>
                    <Link
                      to="/flow"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Flow
                    </Link>
                  </li> */}
                  <li>
                    <Link
                      to="/employee"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Employee
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/inventory"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Inventory
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/metrics"
                      onClick={() => {
                        setAlert({
                          type: "",
                          msg: "",
                        });
                      }}
                    >
                      Metrics
                    </Link>
                  </li>
                </>
              ) : (
                <></>
              )}
            </ul>
          </div>
          {auth.accessToken !== "" ? (
            <>
              <Link
                to="/profileAdmin"
                onClick={() => {
                  setAlert({
                    type: "",
                    msg: "",
                  });
                }}
              >
                {auth.name.split(" ")[0]}
              </Link>
              <button
                className="btn-loginout"
                onClick={async (e) => {
                  setAuth({ accessToken: "", role: 0 });
                  await logOutUser();
                  navigate("/login");
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <button>
              <Link to="/login">Login</Link>
            </button>
          )}
        </div>
      </header>
      <AlertArea />
      <Outlet />
    </>
  );
}

export default HeaderAdmin;
