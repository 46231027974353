const PST_age = (date) => {
  let ago = "";
  const then = new Date(date);
  const PSTthen = then.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const now = new Date();
  const PSTnow = now.toLocaleString("en-US", {
    timeZone: "America/Los_Angeles",
  });
  const milliThen = new Date(PSTthen).getTime();
  const milliNow = new Date(PSTnow).getTime();
  const old = milliNow - milliThen;
  let days = old / 1000 / 60 / 60 / 24;
  if (days < 1) {
    ago = `${(days * 24).toFixed(0)}hrs`;
  } else {
    ago = `${days.toFixed(0)}d`;
  }
  return ago;
};

export default PST_age;
