function format_sku_totals(selected) {
  return [...selected.steps].map((step) => {
    let avg = {
      employees: [],
      step_avg: 0,
      step_mode: 0,
      step_cost: 0,
      step_divisor: 0,
    };
    step.skus.forEach((sku) => {
      if (sku._id === selected.skuId) {
        sku.employees.forEach((emp) => {
          avg.employees.push(emp);

          avg.step_avg += emp.emp_avg * emp.total;
          avg.step_mode += emp.emp_mode * emp.total;
          avg.step_cost += emp.avg_cost * emp.total;
          avg.step_divisor += emp.total;
        });

        if (sku.employees.length > 0) {
          avg.step_avg = avg.step_avg / avg.step_divisor;
          avg.step_mode = avg.step_mode / avg.step_divisor;
          avg.step_cost = avg.step_cost / avg.step_divisor;
        }
      }
    });
    step.sku_totals = avg;
    return step;
  });
}
export default format_sku_totals;
