import { useState, useEffect } from "react";
import format_time from "../../../utils/format_time";
import format_sku_totals from "./format_sku_totals";

function LaborSku(props) {
  const { page, selected } = props;
  const [isDragging, setIsDragging] = useState(false);
  const [employee, setEmployee] = useState([]);

  const handleMouseDown = (index) => {
    setIsDragging(true);
    page.toggle(`body.selected.steps.${index}.highlight`);
  };

  const handleMouseEnter = (index) => {
    if (isDragging) {
      page.toggle(`body.selected.steps.${index}.highlight`);
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const choose = (avg, constant) => {
    if (avg === 0) return constant;
    return avg;
  };

  useEffect(() => {
    const emp = format_sku_totals(selected);
    setEmployee(emp);
  }, [selected, selected.skuId]);

  return employee.map((step, index) => {
    return (
      <div
        key={step.stepId}
        className="grid-bar click"
        onMouseDown={() => handleMouseDown(index)}
        onMouseEnter={() => handleMouseEnter(index)}
        onMouseUp={handleMouseUp}
        style={{
          backgroundColor: step.highlight ? "var(--primary)" : "transparent",
          userSelect: "none",
          color: step.highlight ? "white" : "",
        }}
      >
        <div className="span-4">{`${index + 1}. ${step.stepName}`}</div>
        <div className="span-1">
          {format_time(choose(step.sku_totals.step_avg, step.constant))}
        </div>
        <div className="span-1">{format_time(step.sku_totals.step_mode)}</div>
        <div className="span-1">{`${step.sku_totals.step_divisor}`}</div>
        <div className="span-1">{`$${
          step?.sku_totals.step_cost?.toFixed(2) ?? 0
        }`}</div>
        <div className="span-1">{`${step.sku_totals.employees.length}
              `}</div>
      </div>
    );
  });
}
export default LaborSku;
