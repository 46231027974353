import { Outlet } from "react-router-dom";
import useAuth from "../../context/User/useAuth";
import Login from "../../pages/Login";

function RoleCheck({ allowedRoles }) {
  const { auth } = useAuth();

  return allowedRoles.includes(auth?.role) ? <Outlet /> : <Login />;
}

export default RoleCheck;
