const get_emp_totals = (selected) => {
  const employees = {};

  selected.steps.forEach((step) => {
    if (step.highlight === true) {
      step.skus.forEach((sku) => {
        sku.employees.forEach((employee) => {
          if (!employees[employee.empId]) {
            employees[employee.empId] = {
              _id: employee.empId,
              name: employee.empFirstName + " " + employee.empLastName,
              totals: {
                avg: 0,
                mode: 0,
                cost: 0,
                count: 0,
                loop: 0,
              },
            };
          }
          employees[employee.empId].totals.loop += 1;
          employees[employee.empId].totals.count += employee.total;
          employees[employee.empId].totals.avg +=
            employee.emp_avg * employee.total;
          employees[employee.empId].totals.mode +=
            employee.emp_mode * employee.total;
          employees[employee.empId].totals.cost +=
            employee.avg_cost * employee.total;
        });
      });
    }
  });

  return [
    ...Object.values(employees).map((employee, index) => {
      if (index === 3) console.log(employee);
      employee.totals.avg = employee.totals.avg / employee.totals.count;
      employee.totals.mode = employee.totals.mode / employee.totals.count;
      employee.totals.cost = employee.totals.cost / employee.totals.count;
      return employee;
    }),
  ];
};

export default get_emp_totals;
