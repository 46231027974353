import useAuth from "../context/User/useAuth";
import { useState } from "react";
import ModalAlert from "../components/ModalAlert";
import useProfileAdmin from "../features/profileAdmin/useProfileAdmin";
import UserPanel from "../features/profileAdmin/UserPanel";
import ModalBase from "../components/ModalBase";
import NewUser from "../features/profileAdmin/NewUser";

function ProfileAdmin() {
  const { auth } = useAuth();
  const [alerts, setAlert] = useState([]);
  const page = useProfileAdmin();

  return (
    <div className="page">
      <ModalAlert alerts={alerts} setAlert={setAlert} />
      <ModalBase
        page={page}
        alert={page.state.modal.alert}
        open={page.state.modal.open}
        title={"Add New User"}
        close={() => {
          page.reset("modal");
        }}
      >
        <NewUser page={page} />
      </ModalBase>
      <div className="container thirds shadow soft">
        <div className="bar split">
          <h1>Admin Profile</h1>
          <h3>{auth.name}</h3>
        </div>
      </div>

      <UserPanel page={page} setAlert={setAlert} alerts={alerts} />
    </div>
  );
}
export default ProfileAdmin;
