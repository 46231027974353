import CloseIcon from "../../../../icons/Close";
import DragDropIcon from "../../../../icons/DragDrop";
import SearchBar from "../../../../components/SearchBar";
import ProductOptions from "./ProductOptionFeild/ProductOptions";

import TextInput from "../../../../components/TextInput";

function ProductComponent(props) {
  const { page, component, componentIndex, drag, lock } = props;
  const path = `body.selectedProduct.components.${componentIndex}`;

  const on_lock_all = () => {
    let payload = [];
    if (component.options.some((option) => option.edit === true)) {
      payload = [...component.options].map((option) => {
        return {
          ...option,
          edit: false,
        };
      });
    } else {
      payload = [...component.options].map((option) => {
        return {
          ...option,
          edit: !option.edit,
        };
      });
    }

    page.set(`${path}.options`, payload);
  };

  const on_search = (value, component) => {
    const payload = [...component.options].map((option) => {
      if (option.name.toLowerCase().includes(value.toLowerCase())) {
        return {
          ...option,
          show: true,
        };
      }
      return {
        ...option,
        show: false,
      };
    });
    page.set(`${path}.search`, value);
    page.set(`${path}.options`, payload);
  };

  const on_clear_search = (component) => {
    const payload = [...component.options].map((option) => {
      return {
        ...option,
        show: true,
      };
    });

    page.set(`${path}.search`, "");
    page.set(`${path}.options`, payload);
  };

  //COMPONENT
  return (
    <div
      className="card soft shadow list"
      data-id={componentIndex}
      data-group="comp"
      draggable="true"
      onMouseUp={(e) => {
        drag.up(e);
      }}
      onMouseMove={(e) => {
        drag.move(e);
      }}
      onMouseDown={(e) => {
        drag.down(e);
      }}
      onDragStart={(e) => {
        drag.start(e);
      }}
      onDragEnd={(e) => {
        drag.end(e);
      }}
      onDragEnter={(e) => {
        drag.enter(e);
      }}
      onDragOver={(e) => {
        drag.over(e);
      }}
      onDragLeave={(e) => {
        drag.leave(e);
      }}
      onDrop={(e) => {
        drag.drop(e, "body.selectedProduct.components");
      }}
    >
      <div className="bar split list">
        <div className="bar">
          <button
            style={lock ? { display: "none" } : { display: "block" }}
            tabIndex="-1"
            className="btn-icon"
            onClick={(e) => {
              e.preventDefault();
              page.remove("body.selectedProduct.components", componentIndex);
            }}
          >
            <CloseIcon />
          </button>
          <div className="form-label item">{`${componentIndex + 1}.`}</div>
          <TextInput
            value={component.name}
            onChange={(e) => {
              page.set(`${path}.name`, e.target.value);
            }}
            placeholder="Component Name..."
            error={page.state.errors[`${path}.name`]}
            errorMsg="Component name required"
            errorFunc={(mount) => {
              if (mount) {
                page.error(`${path}.name`, (reason) => reason.length === 0);
              } else {
                page.error(`${path}.name`);
              }
            }}
          />

          <SearchBar
            value={component.search}
            change={(e) => {
              on_search(e.target.value, component);
            }}
            placeholder={"Filter..."}
            btnName={"Clear"}
            lock={false}
            click={(e) => {
              e.preventDefault();
              on_clear_search(component);
            }}
            action={() => {}}
          />
          <button
            className="btn-default"
            onClick={(e) => {
              e.preventDefault();
              page.add(`${path}.options`, {
                name: "",
                edit: true,
                show: true,
                requirements: [],
              });
            }}
          >
            + Option
          </button>
          <button
            className="btn-primary item"
            onClick={(e) => {
              e.preventDefault();
              on_lock_all(componentIndex);
            }}
          >
            Toggle All
          </button>
          <label className="form-label item">
            Options: #{component.options.length}
          </label>
        </div>
        <div style={lock ? { display: "none" } : { display: "block" }}>
          <DragDropIcon />
        </div>
      </div>

      <ProductOptions
        page={page}
        component={component}
        drag={drag}
        componentIndex={componentIndex}
        lock={lock}
      />
    </div>
  );
}
export default ProductComponent;
