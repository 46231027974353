const SearchBar = (props) => {
  const {
    value = "",
    name,
    placeholder,
    action = () => {},
    css,
    lock,
    click,
    change,
    btnName,
  } = props;
  return (
    <div className="search-bar-container item">
      <input
        disabled={lock}
        className={`search-bar-input ${css}`}
        value={value}
        name={name}
        placeholder={placeholder}
        onChange={(e) => {
          change(e);
        }}
        onKeyDown={(e) => {
          action(e);
        }}
      />
      <button
        disabled={lock}
        className="search-bar-btn"
        onClick={(e) => {
          e.preventDefault();
          click(e);
        }}
      >
        {btnName === undefined ? "Search" : btnName}
      </button>
    </div>
  );
};

export default SearchBar;
