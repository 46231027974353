import { useEffect } from "react";
import useServer from "../../hooks/useServer";
import usePage from "../../hooks/usePage";

function useBatches() {
  const { get } = useServer();

  const initialState = {
    modal: {
      open: false,
      master: {},
      barcodes: [],
    },
    menu: {},
    body: {},
    redoScanner: {
      open: false,
      scan: "",
    },
    barcodeModal: {
      open: false,
      master: {},
      barcodes: [],
    },
    batchList: {
      alert: "",
      search: "",
      list: [],
    },
    detailedView: {
      update: false,
      redirect: false,
      batchName: "",
      searched: false,
      group: "",
      station: "",
      orders: [],
    },
    // async state
    stores: {
      clients: [],
      batches: [],
      stations: [],
    },
  };

  const page = usePage(initialState);
  //destructed for linter
  const { set, load, init, isLoading } = page;

  //BATCHES
  useEffect(() => {
    async function effect() {
      load("stores.batches", {
        reload: async () => {
          return get("/batches");
        },
        callback: (data) => {
          init("stores.batches", data);
        },
      });
    }
    effect();
  }, [get, load, init]);

  //BATCHLIST
  useEffect(() => {
    if (isLoading["stores.batches"] === false) {
      set("batchList.list", page.state.stores.batches);
    }
  }, [isLoading, set]);

  // //BARCODE MODAL
  useEffect(() => {
    if (page.state.modal.open === true) {
      window.print();
      window.onafterprint = () => {
        const value = { open: false, master: {}, barcodes: [] };
        set("modal", value);
      };
    }
  }, [page.state.modal.open, set]);

  return page;
}
export default useBatches;
