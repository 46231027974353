import LaborSku from "./LaborSku";
import LaborShop from "./LaborShop";

function Labor(props) {
  const { page, selected } = props;

  const handleClear = () => {
    const steps = page.state.body.selected.steps.map((step) => {
      return {
        ...step,
        highlight: false,
      };
    });
    page.set("body.selected.steps", steps);
  };

  //TODO:: write a function to toggle between shop labor and sku labor LaborSku.jsx
  //COMPONENT
  return (
    <div className="column soft">
      <h3 className="grid-bar titleline">Labor</h3>
      <div className="grid-bar">
        <b className="span-11">{`Avg Cost: $${
          selected?.total_avg_labor_cost?.toFixed(2) ?? 0
        }`}</b>
        <button
          className="btn-link span-1"
          onClick={() => {
            handleClear();
          }}
        >
          Clear
        </button>
      </div>
      <div className="grid-bar">
        <b className="span-4">Steps</b>
        <b className="span-1">Avg</b>
        <b className="span-1">Mode</b>
        <b className="span-1">Logs</b>
        <b className="span-1">Cost</b>
        <b className="span-1">#ofEmps</b>
      </div>
      {selected.sku === "All Skus" ? (
        <LaborShop page={page} selected={selected} />
      ) : (
        <LaborSku page={page} selected={selected} />
      )}
    </div>
  );
}
export default Labor;
