import useOrders from "../features/orders/useOrders";
import OrderMenu from "../features/orders/OrderMenu";
import Spinner from "../icons/Spinner";
import useDrag from "../hooks/useDrag";
import ModalBase from "../components/ModalBase";
import OrderNew from "../features/orders/OrderNew";
import Body from "../features/orders/body/Body";
import useGlobalAlert from "../context/GlobalAlert/useGlobalAlert";
function Orders() {
  const page = useOrders();
  const drag = useDrag(page);
  const { setAlert } = useGlobalAlert();
  let mode = page.state.mode;

  //COMPONENT
  return (
    <div className="page">
      <ModalBase
        page={page}
        title={`${(mode = mode.charAt(0).toUpperCase() + mode.slice(1))} Order`}
        open={page.state.modal.open}
        msg={page.state.modal.msg}
        loading={page.state.modal.loading}
        close={() => {
          page.reset("errors");
          page.reset("modal");
          page.reset("stores.products");
          page.reset("mode");
          setAlert({ msg: "", type: "none" });
        }}
        checkOverflow={true}
        overflowReason={page.state.modal.orderItems.lines.length}
      >
        <OrderNew page={page} />
      </ModalBase>
      <OrderMenu page={page} />

      <div className="grid-bar gap">
        {page.isLoading["stores.orders"] || page.isLoading["stores.batches"] ? (
          <div className="table-boss shadow column span-12">
            <Spinner />
          </div>
        ) : (
          <Body page={page} drag={drag} />
        )}
      </div>
    </div>
  );
}

export default Orders;
