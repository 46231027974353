function psuedo_random_hex(len, salt) {
  let milli = new Date().getTime();
  salt = salt === undefined ? (salt = 0) : salt;
  let bits = 8;
  let min = Math.pow(16, Math.min(len, bits) - 1);
  let max = Math.pow(16, Math.min(len, bits)) - 1;
  let rounds = salt % 2 === 0 ? 1 : 2;
  let n = 0;
  for (let r = 0; r < rounds; r++) {
    n = Math.floor(
      parseFloat(`${Math.random()}${milli}`) * (max - min + 1) + min
    );
  }
  let string = n.toString(16);
  while (string.length < len) {
    string = string + psuedo_random_hex(len - bits);
  }
  return string;
}

export default psuedo_random_hex;
