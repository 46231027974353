const Customer = (each, page) => {
  const customer = page.state.stores.customers.find(
    (customer) => customer._id === each.customerId
  );

  return {
    _id: each.customerId,
    addressBook: customer.addressBook,
    provider: each.provider,
  };
};

const OrderInformation = (each) => {
  return {
    lock: true,
    _id: each._id,
    orderNumber: each.orderNumber,
    provider: {
      name: each.provider.name,
      data: each.provider?.data ?? {},
    },
    customerId: each.customerId,
    orderDate: each.createdAt,
    createdAt: each.createdAt,
    status: each.status,
  };
};

const GeneralInformation = (each) => {
  return {
    lock: true,
    firstName: each.customer.firstName,
    lastName: each.customer.lastName,
    company: each?.customer.company ?? "",
    phone: each.customer?.phone ?? "",
    email: each?.customer.customerEmail ?? "",
  };
};

const ShippingInformation = (each) => {
  return {
    lock: true,
    address1: each.customer.shippingAddress.address1,
    address2: each?.customer.shippingAddress.address2 ?? "",
    address3: each?.customer.shippingAddress.address3 ?? "",
    city: each.customer.shippingAddress.city,
    state: each.customer.shippingAddress.state,
    postalCode: each.customer.shippingAddress.postalCode,
    country: each.customer.shippingAddress.country,
    residential: each.customer.shippingAddress.residential,
  };
};

const BillingInformation = (each) => {
  return {
    lock: true,
    address1: each.customer.billingAddress.address1,
    address2: each?.customer.billingAddress.address2 ?? "",
    address3: each?.customer.billingAddress.address3 ?? "",
    city: each.customer.billingAddress.city,
    state: each.customer.billingAddress.state,
    postalCode: each.customer.billingAddress.postalCode,
    country: each.customer.billingAddress.country,
    residential: each.customer.billingAddress.residential,
  };
};

const OrderDetailsLines = (each) => {
  return {
    lock: true,
    lines: each.orderItems.map((item) => {
      return {
        _id: item._id,
        providerItemId: item.providerItemId,
        // batchName: batchFromItem(each, item),
        skuId: item.skuId,
        sku: item.sku,
        skus: [],
        name: item.name,
        product: item.product,
        quantity: item.quantity,
        batched: item.batched,
        allocate: item.allocate,
        unitPrice: item.unitPrice,
        itemProductId: item?.itemProductId ?? "",
      };
    }),
  };
};

const order_format_modal = (each, page) => {
  let orderInformation = {};
  let currentCustomer = {};
  let general = {};
  let shipping = {};
  let billing = {};
  let orderItems = {};

  currentCustomer = Customer(each, page);
  orderInformation = OrderInformation(each, page);
  general = GeneralInformation(each, page);
  shipping = ShippingInformation(each, page);
  billing = BillingInformation(each, page);
  orderItems = OrderDetailsLines(each, page);

  if (each.provider.source === "shipstation") {
    currentCustomer.provider.data = {
      ...currentCustomer.provider.data,
      selectedStore: `${each.provider.data.selectedStore}`,
      shipstationOrderId: `${each.provider.data.shipStationOrderId}`,
    };
  }

  page.set("modal.currentCustomer", currentCustomer);
  page.set("modal.orderInformation", orderInformation);
  page.set("modal.general", general);
  page.set("modal.shippingAddress", shipping);
  page.set("modal.billingAddress", billing);
  page.set("modal.orderItems", orderItems);
  page.set("mode", "edit");
  page.set("modal.open", true);
};

export default order_format_modal;
