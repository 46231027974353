import React from "react";
import DropDown from "../../../components/DropDown";
import Close from "../../../icons/Close";
function ShipstationStores(props) {
  const { page } = props;

  return (
    <>
      <div className="grid-bar list">
        <label className="input-label third">ShipStation Stores</label>
        <button
          className="btn-default two-thirds right"
          onClick={(e) => {
            e.preventDefault();
            page.add("modal.provider.data.shipstationStoreIds", [
              {
                storeId: page.state.stores.shipstation[0].storeId,
                storeName: page.state.stores.shipstation[0].storeName,
              },
            ]);
          }}
        >
          + Store
        </button>
      </div>
      {page.state.modal.provider.data?.shipstationStoreIds?.map(
        (store, index) => {
          return (
            <div key={store.storeId + index} className="grid-bar">
              <div
                className="pair third right"
                style={{ marginBottom: "1rem" }}
              >
                <button
                  className="btn-icon"
                  style={{ marginBottom: "0.5rem" }}
                  onClick={(e) => {
                    e.preventDefault();
                    page.remove(
                      "modal.provider.data.shipstationStoreIds",
                      index
                    );
                  }}
                >
                  <Close />
                </button>
                <label
                  className="input-label"
                  style={{ marginBottom: "1rem" }}
                >{`${index + 1}.`}</label>
              </div>
              <DropDown
                title={
                  page.state.modal.provider.data.shipstationStoreIds[index]
                    .storeName
                }
              >
                {page.state.stores.shipstation.map((s) => {
                  return page.state.modal.provider.data.shipstationStoreIds[
                    index
                  ].storeId === s.storeId ? (
                    <React.Fragment key={s.storeId}></React.Fragment>
                  ) : (
                    <div
                      className="dropdown-option"
                      key={s.storeId}
                      onClick={() => {
                        page.set(
                          `modal.provider.data.shipstationStoreIds.${index}`,
                          {
                            storeId: s.storeId,
                            storeName: s.storeName,
                          }
                        );
                      }}
                    >
                      {s.storeName}
                    </div>
                  );
                })}
              </DropDown>
            </div>
          );
        }
      )}
    </>
  );
}
export default ShipstationStores;
