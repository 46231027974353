import React from "react";
import Requirements from "./productBlock/Requirements";
import Labor from "./productBlock/Labor";
import Graph from "./productBlock/Graph";
import format_time from "../../utils/format_time";
import Trend from "./productBlock/Trend";
import DropDown from "../../components/DropDown";

function ProductBlock(props) {
  const { page } = props;
  const selected = page.state.body.selected;

  //COMPONENT
  return (
    <div className="card shadow list expand">
      <div className="bar note soft">
        <h3>{page.state.body.selected?.productName ?? "Select a Product"}</h3>
      </div>
      <div className="grid-bar soft ">
        <div className="span-3">{`Process: ${selected.processName}`}</div>
        <div className="span-1">{`Steps: ${selected.steps?.length ?? 0}`}</div>
        <div className="span-1">{`Skus: ${selected.skus?.length ?? 0}`}</div>
        <div className="span-2">{`Total Produced: ${selected.total_complete}`}</div>
        <div className="span-2">{`Avg Throughput: ${format_time(
          selected.total_throughput
        )}`}</div>
        <div className="span-3">{`Total Avg Cost: $${
          selected?.total_cost?.toFixed(2) ?? 0
        }`}</div>
      </div>
      <div className="grid-bar soft titleline">
        <DropDown title={selected.sku} value={selected.sku}>
          <div
            className="dropdown-option "
            onClick={() => {
              page.set("body.selected.sku", "All Skus");
            }}
          >
            All Skus
          </div>
          {selected.skus.map((sku) => {
            return (
              <div
                key={sku._id}
                className="dropdown-option"
                onClick={() => {
                  page.set("body.selected.sku", sku.sku);
                  page.set("body.selected.skuId", sku._id);
                  const steps = selected.steps.map((step) => {
                    return { ...step, highlight: false };
                  });
                  page.set("body.selected.steps", steps);
                }}
              >
                {sku.sku}
              </div>
            );
          })}
        </DropDown>
      </div>

      <div className="split">
        <Requirements page={page} selected={selected} />
        <Labor page={page} selected={selected} />
      </div>
      <div className="split">
        {/* <Trend page={page} selected={selected} /> */}
        <Graph page={page} selected={selected} />
      </div>
    </div>
  );
}
export default ProductBlock;
