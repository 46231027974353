import DropDown from "../../../components/DropDown";
import { useState, useEffect } from "react";
function ShipstationStore(props) {
  const { page, currentCustomer } = props;

  const findName = (id) => {
    const store = currentCustomer.provider.data.shipstationStoreIds.find(
      (store) => store.storeId === id
    );
    return store ? store.storeName : "Shipstation Store";
  };

  const [name, setName] = useState(
    findName(page.state.modal.currentCustomer.provider.data?.selectedStore)
  );

  useEffect(() => {
    setName(
      findName(page.state.modal.currentCustomer.provider.data?.selectedStore)
    );
  }, [page.state.modal.currentCustomer.provider.data?.selectedStore]);

  return (
    <div>
      <DropDown
        title={name}
        value={page.state.modal.currentCustomer.provider.data?.selectedStore}
        lock={page.state.modal.orderInformation.lock}
        error={
          page.state.errors["modal.currentCustomer.provider.data.selectedStore"]
        }
        errorMsg={"Choose Store"}
        errorFunc={(mount) => {
          if (mount) {
            page.error(
              "modal.currentCustomer.provider.data.selectedStore",
              (reason) => {
                return reason === "";
              }
            );
          } else {
            page.error("modal.currentCustomer.provider.data.selectedStore");
          }
        }}
      >
        {currentCustomer.provider.data.shipstationStoreIds.map((store) => {
          return (
            <div
              className="dropdown-option"
              key={store.storeId}
              value={store.storeId}
              onClick={() => {
                page.set(
                  "modal.currentCustomer.provider.data.selectedStore",
                  store.storeId
                );
              }}
            >
              {store.storeName}
            </div>
          );
        })}
      </DropDown>
    </div>
  );
}
export default ShipstationStore;
