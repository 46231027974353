import UserPanelDisplay from "./UserPanelDisplay";
import UserPanelEdit from "./UserPanelEdit";
function UserPanel(props) {
  const { page, setAlert, alerts } = props;

  return (
    <>
      <div className="card soft half smooth shadow relative">
        <div className="soft relative">
          <h1 className="list">Users</h1>

          {page.state.userPanel.edit === false ? (
            <UserPanelDisplay page={page} />
          ) : (
            <UserPanelEdit page={page} alerts={alerts} setAlert={setAlert} />
          )}
        </div>
        <div className="bar right" style={{ alignSelf: "flex-end" }}>
          {page.state.userPanel.edit === false ? (
            <button
              className="btn-default"
              onClick={(e) => {
                page.set("modal.open", true);
              }}
            >
              Add New User
            </button>
          ) : (
            <></>
          )}
        </div>
      </div>
      <div style={{ marginBottom: "200px" }}></div>
    </>
  );
}
export default UserPanel;
