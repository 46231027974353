import { useEffect } from "react";
import useServer from "../../hooks/useServer";
import usePage from "../../hooks/usePage";

function useProfileAdmin() {
  const { get } = useServer();

  const initialState = {
    modal: {
      alert: { type: "none", msg: "" },
      open: false,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: 2001,
    },
    barcode: "",
    title: "",
    userPanel: {
      current: "",
      edit: false,
      create: false,
      name: "",
      email: "",
      password: "",
      confirmPassword: "",
      role: "none",
    },
    stores: {
      users: [],
    },
  };

  const signalState = {};

  const page = usePage(initialState, signalState);
  const { set, load } = page;

  //LOAD USER
  useEffect(() => {
    async function effect() {
      load("stores.users", {
        reload: async () => {
          return get("/users");
        },
        callback: (data) => {
          const payload = {
            ...page.state.userPanel,
            edit: false,
            create: false,
            current: data[0]._id,
            name: data[0].name,
            email: data[0].email,
            role: data[0]?.role ?? "none",
          };
          set("stores.users", data);
          set("userPanel", payload);
        },
      });
    }
    effect();
  }, [get, set, load]);


  return page;
}

export default useProfileAdmin;
