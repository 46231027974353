import axios from "axios";
const BASE_URL = `${window.location.protocol}//${window.location.hostname}${
  window.location.port ? ":" + window.location.port : ""
}`;

export default axios.create({
  baseURL: BASE_URL,
});

export const axiosProtected = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});
