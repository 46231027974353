import TextInput from "../../../../components/TextInput";
import ProcessSteps from "./ProcessSteps";
import useDrag from "../../../../hooks/useDrag";
import useServer from "../../../../hooks/useServer";
import ProcessHeader from "./ProcessHeader";
import useGlobalAlert from "../../../../context/GlobalAlert/useGlobalAlert";
import { useEffect, useRef } from "react";
import ErrorBox from "../../../../components/ErrorBox";
import { add_step } from "./add_step";
import is_overflown from "../../../../utils/is_overflown";
function Process(props) {
  const { page } = props;
  const drag = useDrag(page);
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();
  const ref = useRef(null);

  const selectedProcess = page.state.body.selectedProcess;
  const lock = page.state.body.mode === "edit" ? true : false;
  const { error } = page;

  useEffect(() => {
    error("body.selectedProcess.steps", (reason) => {
      return reason.length === 0;
    });
    return () => {
      error("body.selectedProcess.steps");
    };
  }, [error]);

  useEffect(() => {
    let direction = "bottom";
    if (page.state.body.selectedProcess.steps.length <= 1) {
      direction = "top";
    }

    is_overflown(ref.current, direction);
  }, [page.state.body.selectedProcess.steps.length]);

  const on_save = async (e) => {
    e.preventDefault();
    if (page.validate()) {
      return;
    }
    const payload = {
      _id: page.state.body.selectedProcess?._id ?? "",
      customerId: page.state.body.selectedAddress.customerId,
      name: page.state.body.selectedProcess.name,
      desc: page.state.body.selectedProcess.desc,
      steps: page.state.body.selectedProcess.steps.map((step) => {
        let constant = step.constant === "" ? 0 : parseFloat(step.constant);
        let cost = step.cost === "" ? 0 : parseFloat(step.cost);
        return { ...step, constant: constant, cost: cost };
      }),
    };
    page.load("stores.process", {
      reload: async () => {
        return post("/customer/upsert/process", payload);
      },
      callback: (data) => {
        page.init("stores.process", data.data);
        setAlert({
          type: data.type,
          msg: data.msg,
        });
        page.reset("body.selectedProcess");
        page.set("body.mode", "idle");
      },
    });
  };

  return (
    <>
      {page.state.menu.selected === "" ? (
        <h4>Load Customer</h4>
      ) : (
        <form ref={ref} className="card-three-quarters soft" onSubmit={on_save}>
          <ProcessHeader page={page} />

          {page.state.body.mode !== "idle" ? (
            <>
              <label className="grid-bar">
                <div className="input-label quarter right ">Process Name</div>
                <TextInput
                  value={selectedProcess?.name}
                  onChange={(e) => {
                    page.set("body.selectedProcess.name", e.target.value);
                  }}
                  error={page.state.errors["body.selectedProcess.name"]}
                  errorMsg="Process name required"
                  errorFunc={(mount) => {
                    if (mount) {
                      page.error(
                        "body.selectedProcess.name",
                        (reason) => reason.length === 0
                      );
                    } else {
                      page.error("body.selectedProcess.name");
                    }
                  }}
                  css="half"
                  placeholder="Process Name"
                  required={true}
                />
              </label>
              <label className="grid-bar">
                <div className="input-label quarter right ">Description</div>
                <TextInput
                  value={selectedProcess?.desc}
                  onChange={(e) => {
                    page.set("body.selectedProcess.desc", e.target.value);
                  }}
                  css="half"
                  error="Process name required"
                  maxLength={128}
                  placeholder="128 characters max"
                  required={false}
                  lock={false}
                />
              </label>
              <ErrorBox
                isError={page.state.errors["body.selectedProcess.steps"]}
                errorMsg="At least one step is required"
              >
                <div className="grid-bar list">
                  <div className="input-label quarter right ">Steps</div>
                  <div className="span-2">
                    <button
                      className="btn-default"
                      onClick={(e) => {
                        e.preventDefault();
                        add_step(page);
                      }}
                      style={lock ? { display: "none" } : { display: "block" }}
                    >
                      + Step
                    </button>
                  </div>
                  <div className="grid-bar quarter">
                    <div className="input-label single right">Cancel</div>
                    <div className="single">
                      <button
                        className="btn-default"
                        onClick={(e) => {
                          e.preventDefault();
                          page.set("body.mode", "idle");
                          page.reset("body.selectedProcess");
                          page.reset("errors");
                        }}
                      >
                        Cancel
                      </button>
                    </div>
                  </div>
                  <div className="grid-bar quarter">
                    <div className="input-label single right">Save</div>
                    <div className="single">
                      <button className="btn-primary" type="submit">
                        Save
                      </button>
                    </div>
                  </div>
                </div>
              </ErrorBox>

              {page.state.body.selectedProcess.steps?.map((step, index) => {
                return (
                  <ProcessSteps
                    key={index}
                    index={index}
                    page={page}
                    desc={step.desc}
                    name={step.name}
                    stationName={step.stationName}
                    stationType={step.stationType}
                    stationId={step.stationId}
                    constant={step.constant}
                    cost={step.cost}
                    group={step.group}
                    drag={drag}
                    lock={lock}
                  />
                );
              })}
            </>
          ) : (
            <></>
          )}
          <div style={{ marginBottom: "200px" }}></div>
        </form>
      )}
    </>
  );
}
export default Process;
