import { createContext, useState } from "react";

export const GlobalAlertContext = createContext({ type: "", msg: "" });

export const GlobalAlertProvider = ({ children }) => {
  const [alert, setAlert] = useState({ type: "", msg: "" });

  return (
    <GlobalAlertContext.Provider value={{ alert, setAlert }}>
      {children}
    </GlobalAlertContext.Provider>
  );
};
