import CueSidebar from "./CueSidebar";
import CueTable from "./CueTable";
import OrderTable from "./OrderTable";

function Body(props) {
  const { page, drag } = props;

  return (
    <>
      {page.state.body.cueSidebar.open === true ? (
        <div className="table-boss shadow column soft span-3">
          <CueSidebar page={page} drag={drag} />
        </div>
      ) : (
        <></>
      )}

      {page.state.body.cueTable.open === true ? (
        <div className="table-boss shadow soft span-9">
          <CueTable page={page} drag={drag} />
        </div>
      ) : (
        <div
          className={`table-boss shadow soft span-${
            page.state.body.cueSidebar.open === true ? "9" : "12"
          }`}
        >
          <OrderTable page={page} />
        </div>
      )}
    </>
  );
}

export default Body;
