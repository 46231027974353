import TextInput from "../../components/TextInput";
import NumberInput from "../../components/NumberInput";
import DropDown from "../../components/DropDown";
import useGlobalAlert from "../../context/GlobalAlert/useGlobalAlert";
import useServer from "../../hooks/useServer";
import ErrorBox from "../../components/ErrorBox";
function NewEmployee(props) {
  const { page } = props;
  const modal = page.state.modal;
  const { post } = useServer();
  const { setAlert } = useGlobalAlert();

  //FUNCTIONS
  const handleOnSubmit = async (e) => {
    e.preventDefault();
    if (page.validate()) return;
    const payload = page.state.modal;

    const data = await post("/employee/new", payload);
    if (data.type === "error") {
      page.set_by_key("errors", data.key, true);
      page.set_by_key("errorMessages", data.key, data.msg);
    } else {
      setAlert({ msg: data.msg, type: data.type });
      page.reload("stores.employees");
      page.reset("modal");
    }
  };

  //COMPONENT
  return (
    <ErrorBox
      isError={page.state.errors["modal.alert"]}
      errorMsg={page.state.errorMessages["modal.alert"]}
    >
      <label className="grid-bar list">
        <div className="input-label third right">First Name</div>
        <TextInput
          value={modal.firstName}
          onChange={(e) => {
            page.set("modal.firstName", e.target.value);
          }}
          required={true}
          error={page.state.errors["modal.firstName"]}
          errorFunc={(mount) => {
            if (mount) {
              page.error("modal.firstName", (reason) => {
                return reason.length === 0;
              });
            } else {
              page.error("modal.firstName");
            }
          }}
          placeholder="First Name"
          css="span-6"
        />
      </label>
      <label className="grid-bar list">
        <div className="input-label third right">Last Name</div>
        <TextInput
          value={modal.lastName}
          onChange={(e) => {
            page.set("modal.lastName", e.target.value);
          }}
          required={true}
          error={page.state.errors["modal.lastName"]}
          errorFunc={(mount) => {
            if (mount) {
              page.error("modal.lastName", (reason) => {
                return reason.length === 0;
              });
            } else {
              page.error("modal.lastName");
            }
          }}
          placeholder="Last Name"
          css="span-6"
        />
      </label>
      <label className="grid-bar list">
        <div className="input-label third right">Email</div>
        <TextInput
          value={modal.email}
          onChange={(e) => {
            page.set("modal.email", e.target.value);
          }}
          render={modal.render}
          required={true}
          error={page.state.errors["modal.email"]}
          errorMsg={page.state.errorMessages["modal.email"]}
          errorFunc={(mount) => {
            if (mount) {
              page.error(
                "modal.email",
                (reason) => {
                  return reason.length === 0;
                },
                "Email is required"
              );
            } else {
              page.error("modal.email");
            }
          }}
          placeholder="example@email.com"
          css="span-6"
        />
      </label>
      <ErrorBox
        isError={page.state.errors["modal.password"]}
        errorMsg={page.state.errorMessages["modal.password"]}
      >
        <label className="grid-bar list">
          <div className="input-label third right">Password</div>
          <input
            className="input-default span-6"
            type="password"
            value={modal.password}
            onChange={(e) => {
              page.set("modal.password", e.target.value);
            }}
          />
        </label>
        <label className="grid-bar list">
          <div className="input-label third right">Retype Password</div>
          <input
            className="input-default span-6"
            type="password"
            value={modal.confirmPassword}
            onChange={(e) => {
              page.set("modal.confirmPassword", e.target.value);
            }}
          />
        </label>
      </ErrorBox>
      <label className="grid-bar list">
        <div className="input-label third right">Phone</div>
        <NumberInput
          value={modal.phone}
          onChange={(e) => {
            page.set("modal.phone", e.target.value);
          }}
          placeholder="1234567890"
          css="span-6"
        />
      </label>
      <label className="grid-bar list">
        <div className="input-label third right">Pay</div>
        <NumberInput
          value={modal.pay}
          required={true}
          onChange={(e) => {
            page.set("modal.pay", e.target.value);
          }}
          error={page.state.errors["modal.pay"]}
          errorFunc={(mount) => {
            if (mount) {
              page.error("modal.pay", (reason) => {
                return reason.length === 0;
              });
            } else {
              page.error("modal.pay");
            }
          }}
          css="span-6"
        />
      </label>
      <label className="grid-bar list">
        <div className="input-label third right">Role</div>
        <DropDown
          title={
            modal.role === ""
              ? "Roles"
              : modal?.role.charAt(0).toUpperCase() + modal.role.slice(1)
          }
          value={modal.role}
          required={true}
          error={page.state.errors["modal.role"]}
          errorFunc={(mount) => {
            if (mount) {
              page.error("modal.role", (reason) => {
                return reason.length === 0;
              });
            } else {
              page.error("modal.role");
            }
          }}
          css="span-6"
          render={modal.render}
        >
          <div
            className="dropdown-option"
            onClick={() => {
              page.set("modal.role", "production");
            }}
          >
            Production
          </div>
          <div
            className="dropdown-option"
            onClick={() => {
              page.set("modal.role", "manager");
            }}
          >
            Manager
          </div>
          <div
            className="dropdown-option"
            onClick={() => {
              page.set("modal.role", "shipping");
            }}
          >
            Shipping
          </div>
        </DropDown>
      </label>
      <div className="grid-bar list">
        <button
          className="btn-primary single s-6"
          onClick={async (e) => {
            handleOnSubmit(e);
          }}
        >
          Submit
        </button>
      </div>
    </ErrorBox>
  );
}
export default NewEmployee;
