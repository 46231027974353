import OrderTableHeader from "./OrderTableHeader";
import OrderTableRow from "./OrderTableRow";
import useGlobalAlert from "../../../context/GlobalAlert/useGlobalAlert";

function OrderTable(props) {
  const { page } = props;
  const { setAlert } = useGlobalAlert();

  const tableInfo = page.state.tableInfo;

  return (
    <div className="table-container">
      <div className="table">
        <OrderTableHeader page={page} />
        <OrderTableRow
          tableState={tableInfo}
          rows={page.state.tableInfo.rows}
          tableInfo={page.state.tableInfo}
          page={page}
          setAlert={setAlert}
        />
      </div>
    </div>
  );
}

export default OrderTable;
